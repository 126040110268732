import { IdentificationIcon, ShareIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import React from "react";
import Tabs from "src/components/Shared/Tabs/Tabs";
import WorkspaceSSOList from "./WorkspaceSSOList";
import SSOServiceMetadata from "src/components/SSO/SSOServiceMetadata";
import SSOCustomizations from "src/components/SSO/SSOCustomizations";

const WorkspaceSSO = ({ workspaceId = null }) => {
  const tabs = [
    { name: "SSO Providers", icon: IdentificationIcon, component: <WorkspaceSSOList workspaceId={workspaceId} /> },
    { name: "Customizations", icon: WrenchScrewdriverIcon, component: <SSOCustomizations workspaceId={workspaceId} /> },
    { name: "Service Provide Metadata", icon: ShareIcon, component: <SSOServiceMetadata /> },
  ];
  return (
    <Tabs
      tabs={tabs}
      queryParamName={`subtab`}
      altDesign={true}
    />
  );
};

export default WorkspaceSSO;
