import { Menu, Transition } from "@headlessui/react";
import { ArrowLeftCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { classNames } from "src/helpers/classNames";
import Button from "./Button";

const AddCredentialDropdown = ({ align, buttonClass, dropdownClass, dropdownData, onClick = () => { } }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [subMenuData, setSubMenuData] = useState(null);
  const [isMainMenu, setIsMainMenu] = useState(false);
  const [isSubMenu, setIsSubMenu] = useState(false);
  const [subMenu, setSubMenu] = useState([]);

  useEffect(() => {
    if (dropdownData?.length > 1) {
      setIsMainMenu(true);
      setIsSubMenu(false);
    } else if (dropdownData?.length === 1) {
      if (dropdownData[0]?.embed_types && dropdownData[0]?.embed_types?.length > 0) {
        if (dropdownData[0]?.embed_types?.length > 1) {
          setIsMainMenu(false);
          setIsSubMenu(true);
          setSubMenu(dropdownData[0])
        } else if (dropdownData[0]?.embed_types?.length === 1) {
          setIsMainMenu(false);
          setIsSubMenu(false);
        }
      } else {
        setIsMainMenu(false);
        setIsSubMenu(false);
      }
    } else {
      setIsMainMenu(false);
      setIsSubMenu(false);
    }
  }, [dropdownData])

  return (
    <>
      <Menu
        as="div"
        className="relative">
        <div>
          <Menu.Button className={classNames("h-[47px] md:h-[40px] 2xl:h-[47px] border border-highlightColor py-2 px-4 rounded-md bg-highlightColor text-white transition-all", buttonClass)} onClick={() => {
            if (dropdownData?.length === 1) {
              if (dropdownData[0]?.embed_types && dropdownData[0]?.embed_types?.length === 1) {
                onClick(dropdownData[0], dropdownData[0]?.embed_types[0]);
              }
            }
          }}>
            <div className="flex items-center gap-x-3">
              <PlusIcon className="h-5 w-5 stroke-2" /> Add Credential
            </div>
          </Menu.Button>
        </div>
        {isMainMenu &&
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className={classNames("absolute z-50 mt-2 w-96 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none overflow-hidden", align === "right" ? "right-0 origin-top-right" : "left-0 origin-top-left", dropdownClass)}>
              <div className="flex overflow-hidden">
                <div className="w-full flex-shrink-0">
                  <div className="py-2 max-h-96 overflow-y-auto scrollbar">
                    {dropdownData.length > 0 &&
                      dropdownData.map((item) => (
                        <Button
                          disabled={!item?.embed_types || item?.embed_types.length === 0}
                          type="button"
                          version="default"
                          className="w-full flex items-center py-2 px-3 text-left gap-2 hover:bg-gray-100"
                          key={item._id}
                          onClick={() => {
                            if (item?.embed_types && item?.embed_types?.length > 0) {
                              if (item?.embed_types?.length > 1) {
                                setSubMenuOpen(true);
                                setSubMenuData(item);
                              } else if (item?.embed_types?.length === 1) {
                                onClick(item, item?.embed_types[0]);
                              }
                            }
                          }}>
                          <div className="flex-shrink-0 w-8 h-8 object-contain rounded-md overflow-hidden">
                            <div
                              className=""
                              dangerouslySetInnerHTML={{ __html: item.image }}
                            />
                          </div>
                          <div className="flex-shrink w-full text-gray-600 text-[13px] font-semibold">{item.name}</div>
                        </Button>
                      ))}
                  </div>
                </div>
                <div className={classNames("w-full flex-shrink-0 bg-white transition-all duration-200", subMenuOpen ? "-translate-x-full" : "")}>
                  <div className="space-y-2">
                    <div className="flex py-2">
                      <Button
                        className="!bg-transparent !shadow-none !text-gray-400 !border-none focus:!ring-0 flex-shrink-0 !px-2"
                        onClick={() => {
                          setSubMenuOpen(false);
                          setSubMenuData(null);
                        }}>
                        <ArrowLeftCircleIcon className="w-8 h-8 stroke-1" />
                      </Button>
                      <div className="flex items-center w-full flex-shrink gap-2">
                        <div className="flex-shrink-0 w-8 h-8 object-contain rounded-md overflow-hidden">
                          <div
                            className=""
                            dangerouslySetInnerHTML={{ __html: subMenuData?.image }}
                          />
                        </div>
                        <div className="flex-shrink w-full text-gray-600 text-[13px] font-semibold">{subMenuData?.name}</div>
                      </div>
                    </div>
                    <div className="space-y-1 pb-1">
                      <div className="text-xs uppercase text-gray-400 font-semibold tracking-wider px-3">Actions</div>
                      <div className="max-h-[254px] overflow-y-auto scrollbar space-y-2">
                        {subMenuData?.embed_types &&
                          subMenuData?.embed_types?.length > 0 &&
                          subMenuData?.embed_types?.map((item) => (
                            <Menu.Item key={item._id}>
                              <Button
                                className="!bg-transparent hover:!bg-gray-100 !shadow-none !border-none focus:!ring-0 flex-shrink-0 !px-3 !rounded-none !w-full !text-left !block"
                                onClick={() => {
                                  onClick(subMenuData, item);
                                  setSubMenuOpen(false);
                                  setSubMenuData(null);
                                }}>
                                <div className="flex-shrink-0 w-10 h-10 object-contain rounded-md overflow-hidden mb-auto">
                                  <div
                                    className=""
                                    dangerouslySetInnerHTML={{ __html: item?.image }}
                                  />
                                </div>
                                <div className="w-full flex-shrink space-y-1">
                                  <div className="flex items-start justify-between">
                                    <div className="text-base leading-none font-semibold text-gray-900">{item?.title}</div>
                                    {/* <div className="text-xs leading-none bg-white text-gray-400 border border-gray-300 rounded-md px-2 py-1">Default</div> */}
                                  </div>
                                  <div className="text-xs text-gray-400">{item?.sub_title}</div>
                                </div>
                              </Button>
                            </Menu.Item>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        }
        {isSubMenu &&
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className={classNames("absolute z-50 mt-2 w-96 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none overflow-hidden", align === "right" ? "right-0 origin-top-right" : "left-0 origin-top-left", dropdownClass)}>
              <div className="flex overflow-hidden">
                <div className="w-full flex-shrink-0">
                  <div className="py-2 max-h-96 overflow-y-auto scrollbar">
                    {subMenu?.embed_types &&
                      subMenu?.embed_types?.length > 0 &&
                      subMenu?.embed_types?.map((item) => (
                        <Menu.Item key={item._id}>
                          <Button
                            className="!bg-transparent hover:!bg-gray-100 !shadow-none !border-none focus:!ring-0 flex-shrink-0 !px-3 !rounded-none !w-full !text-left !block"
                            onClick={() => {
                              onClick(subMenu, item);
                            }}>
                            <div className="flex-shrink-0 w-10 h-10 object-contain rounded-md overflow-hidden mb-auto">
                              <div
                                className=""
                                dangerouslySetInnerHTML={{ __html: item?.image }}
                              />
                            </div>
                            <div className="w-full flex-shrink space-y-1">
                              <div className="flex items-start justify-between">
                                <div className="text-base leading-none font-semibold text-gray-900">{item?.title}</div>
                                {/* <div className="text-xs leading-none bg-white text-gray-400 border border-gray-300 rounded-md px-2 py-1">Default</div> */}
                              </div>
                              <div className="text-xs text-gray-400">{item?.sub_title}</div>
                            </div>
                          </Button>
                        </Menu.Item>
                      ))}
                  </div>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        }
      </Menu>
    </>
  );
};

export default AddCredentialDropdown;
