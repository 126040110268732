import React, { useEffect, useState } from "react";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";

const FormPage = ({ workspaces = [], forms = [], isGlobal = false, newPage = {}, setNewPage = () => {} }) => {
  const [formList, setFormList] = useState([]);

  useEffect(() => {
    if (isGlobal) {
      setFormList(forms);
    } else {
      setFormList(workspaces?.find((wrk) => wrk?._id?.toString() === newPage?.workspace_id)?.forms || []);
    }
  }, [workspaces, newPage?.workspace_id, isGlobal, forms]);

  return (
    <SelectMenu
      label="Forms"
      inline={true}
      startIndex={formList?.findIndex((item) => item?._id?.toString() === newPage?.form_id)}
      options={formList?.map((item) => ({ key: item?.name, value: item?._id, object: item }))}
      searchableFields={["name"]}
      dropdownClass={"!min-w-0 w-full"}
      setOption={(option) => {
        setNewPage((prevData) => ({
          ...prevData,
          form_id: option.value,
        }));
      }}
    />
  );
};

export default FormPage;
