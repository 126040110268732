import React, { useEffect, useState } from "react";
import { apiRequest } from "src/async/apiUtils";
import ToggleFlags from "src/components/FeatureFlags/ToggleFlags";

export const FeatureFlags = React.createContext({
  flags: [],
  setFlags: () => {},
});

export const FeatureFlagsProvider = ({ children, value }) => {
  const [flags, setFlags] = useState([]);

  useEffect(() => {
    const ac = new AbortController();

    const loadMenuData = async () => {
      try {
        const {data: resData} = await apiRequest("POST", `/menu/list`, {}, {signal: ac.signal});
        setFlags(resData.data);
      } catch (error) {
        // console.dir("ERROR:", error);
      }
    };

    loadMenuData();

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("access_token")]);

  const editFlags = async (active, name) => {
    let foundFlag = flags.find((flag) => flag.name === name);
    foundFlag.active = active;
    try {
      await apiRequest("POST", `/menu/edit`, {body: {foundFlag}});

      let {data: res} = await apiRequest("POST", `/menu/list`, {});

      if (res.status === 200) {
        setFlags(res.data);
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return (
    <FeatureFlags.Provider value={flags}>
      {children}
      <ToggleFlags editFlags={editFlags} />
    </FeatureFlags.Provider>
  );
};
