import React from "react";
import { DocumentTextIcon, PlusCircleIcon, QueueListIcon } from "@heroicons/react/24/outline";
import Button from "src/components/Shared/Buttons/Button";
import formJson from "src/components/Forms/FormJson/formJson";
import { v4 } from "uuid";

const FormSectionAction = ({ section = null, setFormData = () => {} }) => {
  const addContent = (type) => {
    setFormData((prevData) => ({
      ...prevData,
      sections: [
        ...prevData?.sections?.map((prevSection) => {
          if ((prevSection?._id && section?._id && prevSection?._id === section?._id) || (prevSection?.id && section?.id && prevSection?.id === section?.id)) {
            return {
              ...prevSection,
              contents: [...prevSection?.contents, { ...formJson?.contentJson, id: v4(), type }],
            };
          } else {
            return prevSection;
          }
        }),
      ],
    }));
  };
  const addSection = () => {
    setFormData((prevData) => ({
      ...prevData,
      sections: [...prevData?.sections, { ...formJson?.sectionJson, id: v4(), contents: [{ ...formJson?.contentJson, id: v4() }] }],
    }));
  };
  return (
    <div className="flex-shrink-0">
      <div className="sticky top-24">
        <div className="flex flex-col gap-2 rounded bg-white px-1 py-1 shadow">
          <Button
            version="default"
            hoverText={"Add question"}
            onClick={() => addContent("question")}
            className="flex aspect-square !h-10 items-center justify-center rounded-md px-0 py-0 text-gray-400 transition-all duration-300 hover:bg-gray-100 hover:text-highlightColor">
            <PlusCircleIcon className="h-6 w-6 stroke-2" />
          </Button>
          <Button
            version="default"
            hoverText={"Add title and description"}
            onClick={() => addContent("title")}
            className="flex aspect-square !h-10 items-center justify-center rounded-md px-0 py-0 text-gray-400 transition-all duration-300 hover:bg-gray-100 hover:text-highlightColor">
            <DocumentTextIcon className="h-6 w-6 stroke-2" />
          </Button>
          <Button
            version="default"
            hoverText={"Add section"}
            onClick={addSection}
            className="flex aspect-square !h-10 items-center justify-center rounded-md px-0 py-0 text-gray-400 transition-all duration-300 hover:bg-gray-100 hover:text-highlightColor">
            <QueueListIcon className="h-6 w-6 stroke-2" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormSectionAction;
