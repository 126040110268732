import React, { useState, useRef, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { classNames } from "src/helpers/classNames";
import Button from "src/components/Shared/Buttons/Button";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { usePopper } from "react-popper";
import { bottom, top, left, right } from "@popperjs/core";

const MultiRowSelectButton = ({ disabled = true, name = "", component = null, Icon = null, onClick = () => { }, site = {}, isDropdown = false, xPlacement = "bottomLeft", items = [], dropdownContainer }) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement || ""],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 0],
        },
      },
    ],
  });

  return isDropdown ? (
    <Menu
      as="div"
      className="relative">
      <div>
        {!disabled && (
          <ReactTooltip
            id={`${name}-multi_select_button_option`}
            delayShow={100}
            positionStrategy="fixed"
            className="z-[200] rounded bg-gray-500 px-2 py-2 opacity-100">
            {name}
          </ReactTooltip>
        )}
        <Menu.Button
          ref={setTargetElement}
          data-tooltip-id={`${name}-multi_select_button_option`}
          disabled={disabled}
          className={classNames("group flex items-center justify-center rounded-full p-1.5 transition-all duration-200", disabled ? "cursor-not-allowed" : "hover:bg-gray-100")}
          //onClick={onClick}
        >
          {Icon ? <Icon className={classNames("group h-6 w-6 text-gray-400 transition-all duration-200 ", disabled ? "" : site?.highlight_color ? "group-hover:text-highlightColor" : "group-hover:text-gray-100")} /> : component}
          <ChevronDownIcon className="h-3 w-3 stroke-gray-500" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        className="z-50"
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        beforeEnter={() => setPopperElement(popperElRef.current)}
        afterLeave={() => setPopperElement(null)}
        ref={popperElRef}
        style={styles.popper}
        {...attributes.popper}>
        <Menu.Items className={classNames("absolute origin-top-right mt-1 w-56 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none", dropdownContainer)}>
          {items.map((item, i) =>
            item.type === "hr" ? (
              <div
                key={"hr-" + i}
                className={classNames(item?.hideWhen ? "hidden" : "", "my-1 h-[1px] w-full bg-gray-100")}></div>
            ) : (
              <Menu.Item key={item.name}>
                <Button
                  type="button"
                  version="default"
                  className={classNames(item?.hideWhen ? "hidden" : "", "relative flex w-full px-4 py-2 text-left text-sm", item.color ? item.color : "text-gray-700 hover:text-highlightColor")}
                  onClick={item.onClick}>
                  {item.name} {item?.isBeta && <div className="py-.5 absolute right-2 top-[8px] rounded-full bg-gray-50 px-2 text-gray-400 ">Beta</div>}
                </Button>
              </Menu.Item>
            ),
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  ) : (
    <div>
      {!disabled && (
        <ReactTooltip
          id={`${name}-multi_select_button_option`}
          delayShow={100}
          positionStrategy="fixed"
          className="z-[200] rounded bg-gray-500 px-2 py-2 opacity-100">
          {name}
        </ReactTooltip>
      )}
      <button
        data-tooltip-id={`${name}-multi_select_button_option`}
        disabled={disabled}
        className={classNames("group flex items-center justify-center rounded-full p-1.5 transition-all duration-200", disabled ? "cursor-not-allowed" : "hover:bg-gray-100")}
        onClick={onClick}>
        {Icon ? <Icon className={classNames("group h-6 w-6 text-gray-400 transition-all duration-200 ", disabled ? "" : site?.highlight_color ? "group-hover:text-highlightColor" : "group-hover:text-gray-100")} /> : component}
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(MultiRowSelectButton);
