import React from "react";
// import DisplayPageForm from "src/components/PageForm/DisplayPageForm";

const GroupContent = () => {
  return (
    <>
      <div className="relative px-8">
        <div className="space-y-6 py-2">
          {/* <DisplayPageForm />
          <DisplayPageForm /> */}
        </div>
      </div>
    </>
  );
};

export default GroupContent;
