import React from "react";
import FormFieldItem from "./FormFieldItem";
import LazyImage from "src/components/Shared/LazyImage";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import { formatDate } from "src/helpers/formatDate";

const FormSignatureItem = ({ value = null, responseCreatedAt = null, from = "form", section = null, content = null, setFormData = () => {} }) => {
  const onChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      sections: [
        ...prevData?.sections?.map((prevSection) => {
          if (prevSection?.id === section?.id) {
            return {
              ...prevSection,
              contents: [
                ...prevSection?.contents?.map((prevContent) => {
                  if (prevContent?.id === content?.id) {
                    return {
                      ...prevContent,
                      show_date_on_signing: e.target.checked,
                    };
                  } else {
                    return prevContent;
                  }
                }),
              ],
            };
          } else {
            return prevSection;
          }
        }),
      ],
    }));
  };
  return from === "response" ? (
    <div className="w-full">
      <div className="relative aspect-[3/1] w-56 overflow-hidden pt-2">
        <LazyImage
          src={value}
          wrapperClasses={"w-full h-full"}
          hideImageOnError
        />
      </div>
      {content?.show_date_on_signing && responseCreatedAt && (
        <div className="text-right">
          {formatDate(responseCreatedAt, "MM/DD/YYYY")} <span className="ml-1 text-gray-400">{formatDate(responseCreatedAt, "h:mm:ssA")}</span>
        </div>
      )}
    </div>
  ) : (
    <div className="flex flex-col gap-6">
      <FormFieldItem
        type={content?.question_type}
        from={from}
        placeholder={"Signature/Initials . . ."}
      />
      <div className="flex items-center">
        <Checkbox
          isChecked={content?.show_date_on_signing}
          checkboxInputClass={"!h-6 !w-6 !aspect-square !flex-shrink-0"}
          checkboxBoxClass={"!h-6 !w-6 !aspect-square !flex-shrink-0"}
          checkboxIconClass={"!h-5 !w-5"}
          checkboxLabel={"Show date of signing"}
          checkboxLableClass={"!text-base"}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default FormSignatureItem;
