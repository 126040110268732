import React from "react";
import Modal from "src/components/Shared/Modal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FormModalSectionContent from "src/components/Forms/FormContents/FormModalSectionContent";

const ReorderSectionsModal = ({ isOpen = false, formData = null, setFormData = () => {}, onCancel = () => {}, onSuccess = () => {} }) => {
  const handleOnSuccess = () => {
    onSuccess();
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result?.destination) {
      return;
    }

    const newSections = reorder(formData?.sections, result.source.index, result.destination.index);
    setFormData((prevData) => ({
      ...prevData,
      sections: [...newSections],
    }));
  };

  const moveUp = (index) => {
    const newSections = reorder(formData?.sections, index, index - 1);
    setFormData((prevData) => ({
      ...prevData,
      sections: [...newSections],
    }));
  };

  const moveDown = (index) => {
    const newSections = reorder(formData?.sections, index, index + 1);
    setFormData((prevData) => ({
      ...prevData,
      sections: [...newSections],
    }));
  };

  return (
    <Modal
      size="xs"
      title="Reorder"
      secondaryTitle="Sections"
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={handleOnSuccess}
      defaultStyles={{
        overFlowYVisible: false,
      }}
      defaultOptions={{
        onSuccessButtonVisible: false,
        onCancelButtonVisible: false,
      }}>
      <div className="relative -mx-4 divide-y divide-gray-200">
        {formData?.sections && formData?.sections?.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`section`}>
              {(provided, snapshot) => (
                <div
                  className="space-y-4"
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {formData?.sections?.map((section, index) => (
                    <Draggable
                      key={section?.id}
                      draggableId={section?.id}
                      index={index}>
                      {(provided, snapshot) => (
                        <FormModalSectionContent
                          key={section?.id}
                          section={section}
                          totalSection={formData?.sections?.length}
                          index={index}
                          innerRef={provided.innerRef}
                          draggableProps={provided.draggableProps}
                          dragHandleProps={provided.dragHandleProps}
                          moveUp={moveUp}
                          moveDown={moveDown}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
    </Modal>
  );
};

export default ReorderSectionsModal;
