import { Bars3Icon } from "@heroicons/react/24/outline";
import React, { useMemo } from "react";
import FormFieldItem from "src/components/Forms/FormQuestionItems/FormFieldItem";
import FormOptionItem from "src/components/Forms/FormQuestionItems/FormOptionItem";
import FormAddItem from "src/components/Forms/FormQuestionItems/FormAddItem";
import Input from "src/components/Shared/Forms/Inputs/Input";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import FormContentAction from "src/components/Forms/FormActions/FormContentAction";
import { questionTypes } from "src/helpers/formHelper";
import formJson from "src/components/Forms/FormJson/formJson";
import { v4 } from "uuid";
import Button from "src/components/Shared/Buttons/Button";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { classNames } from "src/helpers/classNames";
import { formatDate } from "src/helpers/formatDate";
import FormFileItem from "../FormQuestionItems/FormFileItem";
import FormChoiceGrid from "../FormQuestionItems/FormChoiceGrid";
import FormLinearScale from "../FormQuestionItems/FormLinearScale";
import FormSignatureItem from "../FormQuestionItems/FormSignatureItem";

const FormQuestionContent = ({ section = null, content = null, draggableProps, dragHandleProps, innerRef, setFormData = () => {}, from = "form", answers = [], responseCreatedAt = null }) => {
  const onHandleChange = ({ fields = [], option = "", addOption = false, addOtherOption = false, editOption = false, deleteOption = false, addRow = false, editRow = false, deleteRow = false }) => {
    setFormData((prevData) => ({
      ...prevData,
      sections: [
        ...prevData?.sections?.map((prevSection) => {
          if (prevSection?.id === section?.id) {
            return {
              ...prevSection,
              contents: [
                ...prevSection?.contents?.map((prevContent) => {
                  if (prevContent?.id === content?.id) {
                    if (editOption && option) {
                      return {
                        ...prevContent,
                        options: [
                          ...prevContent?.options?.map((prevOption) => {
                            if (prevOption?.id === option?.id) {
                              return {
                                ...prevOption,
                                ...fields?.reduce((acc, field) => {
                                  return {
                                    ...acc,
                                    [field?.field]: field?.value || "",
                                  };
                                }, {}),
                              };
                            } else {
                              return prevOption;
                            }
                          }),
                        ],
                      };
                    } else if (addOption) {
                      return {
                        ...prevContent,
                        options: [...prevContent?.options, { ...formJson?.optionJson, id: v4() }],
                      };
                    } else if (addOtherOption) {
                      return {
                        ...prevContent,
                        options: [...prevContent?.options, { ...formJson?.optionJson, id: v4(), other: true }],
                      };
                    } else if (deleteOption) {
                      return {
                        ...prevContent,
                        options: [...prevContent?.options?.filter((prevOption) => prevOption?.id !== option?.id)],
                      };
                    } else if (editRow && option) {
                      return {
                        ...prevContent,
                        rows: [
                          ...prevContent?.rows?.map((prevOption) => {
                            if (prevOption?.id === option?.id) {
                              return {
                                ...prevOption,
                                ...fields?.reduce((acc, field) => {
                                  return {
                                    ...acc,
                                    [field?.field]: field?.value || "",
                                  };
                                }, {}),
                              };
                            } else {
                              return prevOption;
                            }
                          }),
                        ],
                      };
                    } else if (addRow) {
                      return {
                        ...prevContent,
                        rows: [...prevContent?.rows, { ...formJson?.optionJson, id: v4() }],
                      };
                    } else if (deleteRow) {
                      return {
                        ...prevContent,
                        rows: [...prevContent?.rows?.filter((prevOption) => prevOption?.id !== option?.id)],
                      };
                    } else {
                      return {
                        ...prevContent,
                        ...fields?.reduce((acc, field) => {
                          return {
                            ...acc,
                            [field?.field]: field?.value,
                          };
                        }, {}),
                      };
                    }
                  } else {
                    return prevContent;
                  }
                }),
              ],
            };
          } else {
            return prevSection;
          }
        }),
      ],
    }));
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result, type) => {
    const { destination, source } = result;

    // If no destination (dropped outside the list), exit
    if (!destination) return;

    // Check if the destination or source is disabled (like `option?.other === true`)
    const destinationOption = content?.[type][destination.index];
    const sourceOption = content?.[type][source.index];

    // If the item is dropped into or from a disabled item, exit or adjust logic
    if (destinationOption?.other || sourceOption?.other) return;

    // Handle the reordering of options if the above checks pass
    const reorderedOptions = reorder(content?.[type], source.index, destination.index);

    setFormData((prevData) => ({
      ...prevData,
      sections: [
        ...prevData?.sections?.map((prevSection) => {
          if (prevSection?.id === section?.id) {
            return {
              ...prevSection,
              contents: [
                ...prevSection?.contents?.map((prevContent) => {
                  if (prevContent?.id === content?.id) {
                    return {
                      ...prevContent,
                      [type]: [...reorderedOptions],
                    };
                  } else {
                    return prevContent;
                  }
                }),
              ],
            };
          } else {
            return prevSection;
          }
        }),
      ],
    }));
  };

  const getAnswer = useMemo(() => {
    let value;
    if (content?.question_type === "date") {
      value = answers?.find((answer) => answer?.content_id?.toString() === content?._id?.toString())?.value;
      value = value ? formatDate(value) : "";
    } else if (content?.question_type === "time") {
      value = answers?.find((answer) => answer?.content_id?.toString() === content?._id?.toString())?.value;
      value = value ? formatDate(value, "hh:mm A") : "";
    } else {
      value = answers?.find((answer) => answer?.content_id?.toString() === content?._id?.toString())?.value;
    }
    return value;
  }, [answers, content]);
  return (
    <div
      className="relative w-full divide-y divide-gray-100 rounded-md border-l-4 border-highlightColor bg-white shadow"
      ref={innerRef}
      {...draggableProps}>
      {from !== "response" && (
        <Button
          version="default"
          className="flex !h-6 w-full !cursor-move items-center justify-center transition-all duration-300 hover:bg-gray-100"
          dragHandleProps={dragHandleProps}>
          <Bars3Icon className="h-6 w-full text-gray-300" />
        </Button>
      )}

      <div className={classNames("grid grid-cols-3 px-6 py-6", from !== "response" && "gap-6")}>
        <div className="col-span-2">
          <div className="relative">
            {from === "response" ? (
              <div className="text-xl font-bold text-gray-600">{content?.name}</div>
            ) : (
              <Input
                inline={true}
                label={"Question"}
                wrapperClass={"!bg-white"}
                value={content?.name}
                onChange={(e) => onHandleChange({ fields: [{ field: "name", value: e.target.value }] })}
              />
            )}
          </div>
        </div>
        {from !== "response" && (
          <div className="col-span-1">
            <div className="relative">
              <SelectMenu
                inline={true}
                label={"Question Type"}
                options={questionTypes}
                dropdownClass={"w-full"}
                startIndex={questionTypes?.findIndex((type) => type?.value === content?.question_type)}
                setOption={(option) => {
                  let totalData = [{ field: "question_type", value: option.value }];
                  if (option.value !== "file") {
                    totalData.push({ field: "file_types", value: formJson?.contentJson?.file_types }, { field: "file_number", value: formJson?.contentJson?.file_number }, { field: "file_size", value: formJson?.contentJson?.file_size });
                  }
                  if (["input", "textarea", "rich_text", "signature", "date", "time", "select", "file"]?.includes(option.value) && content?.other) {
                    totalData.push({ field: "other", value: false });
                  }
                  if (["input", "textarea", "rich_text", "signature", "date", "time", "file"]?.includes(option.value) && content?.options?.length > 0) {
                    totalData.push({ field: "options", value: [] });
                  }
                  if (["radio", "checkbox", "select", "radio_grid", "checkbox_grid"]?.includes(option.value) && content?.options?.length <= 0) {
                    totalData.push({ field: "options", value: [{ ...formJson?.optionJson, id: v4() }] });
                    if (["radio_grid", "checkbox_grid"]?.includes(option.value) && content?.rows?.length <= 0) {
                      totalData.push({ field: "rows", value: [{ ...formJson?.optionJson, id: v4() }] });
                    }
                  }
                  onHandleChange({ fields: totalData });
                }}
              />
            </div>
          </div>
        )}

        <div className="col-span-3">
          {["input", "textarea", "rich_text", "date", "time"]?.includes(content?.question_type) && (
            <FormFieldItem
              type={content?.question_type}
              from={from}
              placeholder={
                from === "response"
                  ? getAnswer
                  : content?.question_type === "input"
                    ? "Short-answer text . . ."
                    : content?.question_type === "textarea"
                      ? "Long-answer text . . ."
                      : content?.question_type === "rich_text"
                        ? "Rich-answer text . . ."
                        : content?.question_type === "signature"
                          ? "Signature/Initials . . ."
                          : content?.question_type === "date"
                            ? "Day, month, year"
                            : content?.question_type === "time"
                              ? "Time"
                              : ""
              }
            />
          )}
          {content?.question_type === "signature" && (
            <FormSignatureItem
              from={from}
              value={answers?.find((answer) => answer?.content_id?.toString() === content?._id?.toString())?.value}
              responseCreatedAt={responseCreatedAt}
              section={section}
              content={content}
              setFormData={setFormData}
            />
          )}
          {content?.question_type === "file" && (
            <FormFileItem
              section={section}
              content={content}
              answer={answers?.find((answer) => answer?.content_id?.toString() === content?._id?.toString())}
              from={from}
              onHandleChange={onHandleChange}
            />
          )}
          {from === "response" ? (
            <>
              {["radio", "checkbox", "select"]?.includes(content?.question_type) && (
                <div className="relative space-y-2 py-2">
                  <div className="flex flex-row">
                    <div className="w-full flex-shrink flex-grow">
                      {content?.options && content?.options?.length > 0 && (
                        <DragDropContext onDragEnd={(e) => onDragEnd(e, "options")}>
                          <Droppable droppableId={`option_${content?.id}`}>
                            {(provided, snapshot) => (
                              <div
                                className="space-y-4"
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {content?.options?.map((option, index) => (
                                  <Draggable
                                    key={option?.id || option?._id}
                                    draggableId={option?.id || option?._id}
                                    isDragDisabled={option?.other || from === "response"}
                                    index={index}>
                                    {(provided, snapshot) => (
                                      <FormOptionItem
                                        key={option?.id || option?._id}
                                        type={content?.question_type}
                                        option={option}
                                        index={index}
                                        options={content?.options}
                                        onChange={(e) => onHandleChange({ fields: [{ field: "name", value: e.target.value }], option, editOption: true })}
                                        onRemove={() => onHandleChange({ deleteOption: true, option })}
                                        innerRef={provided.innerRef}
                                        draggableProps={provided.draggableProps}
                                        dragHandleProps={provided.dragHandleProps}
                                        from={from}
                                        placeholder={["radio_grid", "checkbox_grid"]?.includes(content?.question_type) ? "Column" : "Option"}
                                        answer={answers?.find((answer) => answer?.content_id?.toString() === content?._id?.toString())}
                                      />
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}
                      {from !== "response" && (
                        <FormAddItem
                          buttonTitle={["radio_grid", "checkbox_grid"]?.includes(content?.question_type) ? "Add Column" : "Add Option"}
                          type={content?.question_type}
                          addOption={() => onHandleChange({ addOption: true })}
                          addOther={() => onHandleChange({ addOtherOption: true })}
                          isOthers={["radio", "checkbox"]?.includes(content?.question_type) && !content?.options?.find((option) => option?.other)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {["radio_grid", "checkbox_grid"]?.includes(content?.question_type) && (
                <FormChoiceGrid
                  content={content}
                  answer={answers?.find((answer) => answer?.content_id?.toString() === content?._id?.toString())}
                />
              )}
            </>
          ) : (
            <>
              {["radio", "checkbox", "select", "radio_grid", "checkbox_grid"]?.includes(content?.question_type) && (
                <div className="relative space-y-2 py-2">
                  <div className="flex flex-row">
                    {["radio_grid", "checkbox_grid"]?.includes(content?.question_type) && (
                      <div className="w-full flex-shrink flex-grow">
                        {content?.rows && content?.rows?.length > 0 && (
                          <DragDropContext onDragEnd={(e) => onDragEnd(e, "rows")}>
                            <Droppable droppableId={`row_${content?.id}`}>
                              {(provided, snapshot) => (
                                <div
                                  className="space-y-4"
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}>
                                  {content?.rows?.map((option, index) => (
                                    <Draggable
                                      key={option?.id || option?._id}
                                      draggableId={option?.id || option?._id}
                                      isDragDisabled={option?.other || from === "response"}
                                      index={index}>
                                      {(provided, snapshot) => (
                                        <FormOptionItem
                                          key={option?.id || option?._id}
                                          isCircleShow={false}
                                          type={content?.question_type}
                                          option={option}
                                          index={index}
                                          options={content?.rows}
                                          onChange={(e) => onHandleChange({ fields: [{ field: "name", value: e.target.value }], option, editRow: true })}
                                          onRemove={() => onHandleChange({ deleteRow: true, option })}
                                          innerRef={provided.innerRef}
                                          draggableProps={provided.draggableProps}
                                          dragHandleProps={provided.dragHandleProps}
                                          from={from}
                                          placeholder={"Row"}
                                        />
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        )}
                        {from !== "response" && (
                          <FormAddItem
                            buttonTitle={"Add Row"}
                            isCircleShow={false}
                            type={content?.question_type}
                            addOption={() => onHandleChange({ addRow: true })}
                            addOther={() => onHandleChange({ addOtherOption: true })}
                            isOthers={["radio", "checkbox"]?.includes(content?.question_type) && !content?.options?.find((option) => option?.other)}
                          />
                        )}
                      </div>
                    )}
                    <div className="w-full flex-shrink flex-grow">
                      {content?.options && content?.options?.length > 0 && (
                        <DragDropContext onDragEnd={(e) => onDragEnd(e, "options")}>
                          <Droppable droppableId={`option_${content?.id}`}>
                            {(provided, snapshot) => (
                              <div
                                className="space-y-4"
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {content?.options?.map((option, index) => (
                                  <Draggable
                                    key={option?.id || option?._id}
                                    draggableId={option?.id || option?._id}
                                    isDragDisabled={option?.other || from === "response"}
                                    index={index}>
                                    {(provided, snapshot) => (
                                      <FormOptionItem
                                        key={option?.id || option?._id}
                                        type={content?.question_type}
                                        option={option}
                                        index={index}
                                        options={content?.options}
                                        onChange={(e) => onHandleChange({ fields: [{ field: "name", value: e.target.value }], option, editOption: true })}
                                        onRemove={() => onHandleChange({ deleteOption: true, option })}
                                        innerRef={provided.innerRef}
                                        draggableProps={provided.draggableProps}
                                        dragHandleProps={provided.dragHandleProps}
                                        from={from}
                                        placeholder={["radio_grid", "checkbox_grid"]?.includes(content?.question_type) ? "Column" : "Option"}
                                        answer={answers?.find((answer) => answer?.content_id?.toString() === content?._id?.toString())}
                                      />
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}
                      {from !== "response" && (
                        <FormAddItem
                          buttonTitle={["radio_grid", "checkbox_grid"]?.includes(content?.question_type) ? "Add Column" : "Add Option"}
                          type={content?.question_type}
                          addOption={() => onHandleChange({ addOption: true })}
                          addOther={() => onHandleChange({ addOtherOption: true })}
                          isOthers={["radio", "checkbox"]?.includes(content?.question_type) && !content?.options?.find((option) => option?.other)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {content?.question_type === "linear_scale" && (
            <FormLinearScale
              section={section}
              content={content}
              answer={answers?.find((answer) => answer?.content_id?.toString() === content?._id?.toString())}
              from={from}
              onHandleChange={onHandleChange}
            />
          )}
        </div>
        {from !== "response" && (
          <FormContentAction
            section={section}
            content={content}
            setFormData={setFormData}
          />
        )}
      </div>
    </div>
  );
};
export default FormQuestionContent;
