import { connect } from "react-redux";
import Button from "src/components/Shared/Buttons/Button";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import QuickMenu from "src/components/Shared/QuickMenu";
import SSOManageFormContent from "./SSOManageFormContent";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";

const WorkspaceSSOSelectedItem = ({ itemModifiedCount, providerType = {}, workspaceId = null, onAfterSave = () => {}, activeItem = null, setActiveItem = () => {}, setItemModifiedCount = () => {}, setActionItem = () => {}, listToggle, setListToggle, ...props }) => {
  const [formData, setFormData] = useState(null);

  const resetFormData = () => {
    setFormData({
      ...activeItem,
      client_id: "",
      client_secret: "",
      client_id_placeholder: activeItem?.client_id,
      client_secret_placeholder: activeItem?.client_secret,
    });
  };

  const onClear = () => {
    resetFormData();
    setItemModifiedCount(0);
  };

  const onSave = async () => {
    try {
      setItemModifiedCount(0);
      const { data } = await apiRequest("POST", workspaceId ? `/workspaces/:workspace_id/sso/edit` : `/sso/edit`, {
        body: { ...formData, id: formData?._id },
        params: { workspace_id: workspaceId },
      });
      toast.success(data.message);
      onAfterSave();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onMakeDefault = async () => {
    try {
      const { data } = await apiRequest("POST", workspaceId ? `/workspaces/:workspace_id/sso/set-default` : `/sso/set-default`, {
        body: { id: formData?._id, default: !formData.default },
        params: { workspace_id: workspaceId },
      });
      toast.success(data.message);
      onAfterSave();
    } catch (error) {}
  };

  const menuItems = [
    {
      name: `Mapping to Groups`,
      color: "text-gray-500",
      onClick: () => {
        setActionItem({ state: activeItem, action: "MAP_TO_WORKSPACE" });
      },
    },
    {
      name: "Map SSO to User fields",
      color: "text-gray-500",
      onClick: () => {
        setActionItem({ state: activeItem, action: "MAP_TO_USER_ATTRIBUTES" });
      },
    },
    {
      name: "Delete",
      color: "text-red-500",
      onClick: () => {
        setActionItem({ state: activeItem, action: "DELETE" });
      },
    },
  ];

  useEffect(() => {
    resetFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);

  return (
    <>
      {listToggle && (
        <div className={classNames("flex w-full transition-all lg:hidden", listToggle ? "mb-3 h-auto opacity-100" : "h-0 overflow-hidden opacity-0")}>
          <Button
            version="primary"
            onClick={() => setListToggle(false)}>
            <ArrowUturnLeftIcon className="h-5 w-5" />
            Back
          </Button>
        </div>
      )}
      {(authorizeUserComponentAccess(props?.user, workspaceId, "settings", ["update"]) || authorizeUserComponentAccess(props?.user, workspaceId, "sso", ["update"])) && (
        <div className={classNames("flex w-full justify-end gap-x-4 rounded border transition-all", itemModifiedCount > 0 ? "mb-3 h-auto border-gray-200 bg-gray-50/50 p-3 py-2 opacity-100" : "h-0 overflow-hidden border-transparent opacity-0")}>
          <Button
            version="gray"
            onClick={onClear}
            disabled={itemModifiedCount < 1}>
            Undo
          </Button>
          <Button
            onClick={onSave}
            disabled={itemModifiedCount < 1}>
            Save
          </Button>
        </div>
      )}

      <div className="relative flex w-full flex-shrink rounded-lg border border-gray-200 py-2 shadow">
        <div className="relative flex w-full flex-col">
          <div className="relative flex w-full items-center justify-between space-x-2 px-6 py-4">
            <div className="relative flex w-full items-center">
              <div className="flex-shrink flex-grow space-y-1">
                <div className="flex items-center space-x-3">
                  <div className="truncate text-2xl font-semibold leading-none text-gray-700">{activeItem?.provider_name}</div>
                  <div className="truncate rounded-full bg-gray-200 px-2 pb-1 pt-1 text-xs font-semibold leading-none text-gray-500">{providerType[activeItem?.provider_type]}</div>
                </div>
                <div className="pr-10 text-sm font-medium text-gray-500">
                  <span className="block break-all">{activeItem?.provider_type !== "auth0" ? activeItem?.identity_url : activeItem?.domain}</span>
                </div>
              </div>
              <div className="flex-shrink">
                <Button
                  className={classNames("inline-block !h-8 rounded-full px-3 py-2 text-sm font-semibold leading-none", formData?.default ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600")}
                  version="default"
                  onClick={onMakeDefault}>
                  {formData?.default ? "Active" : "Inactive"}
                </Button>
              </div>
            </div>
            {(authorizeUserComponentAccess(props?.user, workspaceId, "settings", ["update"]) || authorizeUserComponentAccess(props?.user, workspaceId, "sso", ["update"])) && (
              <QuickMenu
                position="LEFT"
                items={menuItems}
              />
            )}
          </div>
          <div className="h-full space-y-2 border-t border-gray-200 px-4 py-2">
            <SSOManageFormContent
              workspaceId={workspaceId}
              formData={formData}
              setItemModifiedCount={setItemModifiedCount}
              setFormData={setFormData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};
export default connect(mapStateToProps, {})(WorkspaceSSOSelectedItem);
