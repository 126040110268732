import { ArrowPathIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Input from "src/components/Shared/Forms/Inputs/Input";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import { classNames } from "src/helpers/classNames";
import useFetch from "src/hooks/useFetch";

const ActivitySettings = ({ site, manageSiteSettings }) => {
  const [activityWarningEnable, setActivityWarningEnable] = useState(site?.activity_warning_enable || false);
  const [activityWarningView, setActivityWarningView] = useState(site?.activity_warning_view || "");
  const [activityWarningMessage, setActivityWarningMessage] = useState(site?.activity_warning_message || "");
  const [activityLockoutEnable, setActivityLockoutEnable] = useState(site?.activity_lockout_enable || false);
  const [activityLockoutView, setActivityLockoutView] = useState(site?.activity_lockout_view || "");
  const [activityLockoutMinute, setActivityLockoutMinute] = useState(site?.activity_lockout_minute || "");
  const [activityLockoutMessage, setActivityLockoutMessage] = useState(site?.activity_lockout_message || "");
  const [disabled, setDisabled] = useState(false);
  const [isSyncingWithDomo, setIsSyncingWithDomo] = useState(false);

  const [datasetId, setDatasetId] = useState("");
  const [credentialId, setCredentialId] = useState(null);
  const [syncEnabled, setSyncEnabled] = useState(false);
  const [datasetDisabled, setDatasetDisabled] = useState(false);

  const {
    response: { data: credentials },
  } = useFetch(`/credentials/all`, { query: { code: "domo" } });

  const setPageData = () => {
    if (site?._id) {
      setActivityWarningEnable(site.activity_warning_enable || false);
      setActivityWarningView(site.activity_warning_view || "");
      setActivityWarningMessage(site.activity_warning_message || "");
      setActivityLockoutEnable(site.activity_lockout_enable || false);
      setActivityLockoutView(site.activity_lockout_view || "");
      setActivityLockoutMinute(site.activity_lockout_minute || "");
      setActivityLockoutMessage(site.activity_lockout_message || "");
    }
  };

  const setDatasetData = () => {
    setDatasetId(site?.activity_import_dataset_id || "");
    setCredentialId(site?.activity_import_credential_id || null);
    setSyncEnabled(site?.activity_import_sync_enabled || null);
  };

  const viewDataset = async () => {
    setDatasetDisabled(true);
    try {
      const { data } = await apiRequest("POST", `/activities/view-dataset`, { body: { from: "activity" } }, { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} });
      window.open(data?.data?.url, "_blank");
    } catch (error) {}
    setDatasetDisabled(false);
  };

  const onSubmit = async () => {
    try {
      setDisabled(true);
      const message = await manageSiteSettings({
        activity_warning_enable: activityWarningEnable,
        activity_warning_view: activityWarningView,
        activity_warning_message: activityWarningMessage,
        activity_lockout_enable: activityLockoutEnable,
        activity_lockout_view: activityLockoutView,
        activity_lockout_minute: activityLockoutMinute,
        activity_lockout_message: activityLockoutMessage,
      });
      setDisabled(false);
      toast.success(message);
    } catch (error) {
      setDisabled(false);
      toast.error(error.message);
    }
  };

  const handleOnManualDatasetSync = async () => {
    setIsSyncingWithDomo(true);
    try {
      await apiRequest("POST", `/activities/push-dataset/domo`, {}, { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} });
    } catch (error) {
      toast.error(error.message);
    }
    setIsSyncingWithDomo(false);
  };

  const handleOnDatasetSubmit = async () => {
    setDatasetDisabled(true);
    try {
      const message = await manageSiteSettings({ activity_import_dataset_id: datasetId, activity_import_credential_id: credentialId, activity_import_sync_enabled: syncEnabled });
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    }
    setDatasetDisabled(false);
  };

  useEffect(() => {
    setPageData();
    setDatasetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  return (
    <>
      <EditContainer
        title="Anti-Automation / Abuse Prevention (BETA)"
        subtitle="Manage your all activity settings from here"
        preview={{ text: "Anti-Automation / Abuse Prevention" }}
        isLoading={disabled}
        onSuccess={onSubmit}
        onCancel={setPageData}
        defaultOptions={{
          onCancelButtonVisible: true,
          onSuccessButtonVisible: true,
        }}
        fullWidth={true}>
        <>
          <div className="relative w-full space-y-20">
            <div className="relative mt-10 w-full">
              <div className="mb-4 flex gap-x-5 font-medium text-gray-500">
                <ToggleHeader
                  title="Initial warning"
                  subtitle='Warn users of "malicious bot-like" behavior'>
                  <Toggle
                    checked={activityWarningEnable}
                    onChange={setActivityWarningEnable}
                  />
                </ToggleHeader>
              </div>
              <div className="flex w-full flex-col gap-x-5 gap-y-3 sm:px-8">
                <div className="w-[150px]">
                  <Input
                    disabled={!activityWarningEnable}
                    name="views"
                    label="Max views (per min.)"
                    inline={true}
                    value={activityWarningView}
                    onChange={(e) => setActivityWarningView(e.target.value)}
                  />
                </div>
                <div className="w-full max-w-xl">
                  <TextArea
                    disabled={!activityWarningEnable}
                    name="message"
                    label="Warning message"
                    inline={true}
                    value={activityWarningMessage}
                    onChange={(e) => setActivityWarningMessage(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="relative w-full">
              <div className="mb-4 flex gap-x-5 font-medium text-gray-500">
                <ToggleHeader
                  title="Temporary lockout"
                  subtitle="Lock out abusive users.">
                  <Toggle
                    checked={activityLockoutEnable}
                    onChange={setActivityLockoutEnable}
                  />
                </ToggleHeader>
              </div>
              <div className="flex w-full flex-col gap-x-5 gap-y-3 sm:px-8">
                <div className="flex gap-x-4">
                  <div className="w-[150px]">
                    <Input
                      disabled={!activityLockoutEnable}
                      name="views"
                      label="Max views (per min.)"
                      inline={true}
                      value={activityLockoutView}
                      onChange={(e) => setActivityLockoutView(e.target.value)}
                    />
                  </div>
                  <div className="w-[150px]">
                    <Input
                      disabled={!activityLockoutEnable}
                      name="locked_min"
                      label="Lockout clock (min.)"
                      inline={true}
                      value={activityLockoutMinute}
                      onChange={(e) => setActivityLockoutMinute(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full max-w-xl">
                  <TextArea
                    disabled={!activityLockoutEnable}
                    name="message"
                    label="Lockout message"
                    inline={true}
                    value={activityLockoutMessage}
                    onChange={(e) => setActivityLockoutMessage(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      </EditContainer>
      <EditContainer
        title="Push activity log to Domo Dataset"
        subtitle="Push your all activity log to Domo Dataset from here"
        preview={{ text: "Push activity log to Domo Dataset" }}
        isLoading={datasetDisabled}
        isDisabled={isSyncingWithDomo}
        onSuccess={handleOnDatasetSubmit}
        onCancel={setDatasetData}
        fullWidth={true}>
        <div className="mt-8 flex w-full justify-center">
          <div className="w-[400px] max-w-[520px]">
            <div className="flex flex-col gap-4">
              <div className="relative w-full max-w-md">
                <SelectMenu
                  inline={true}
                  label="DOMO credentials"
                  emptyListText={site?.activity_import_credential_name || "No listed items"}
                  defaultText={site?.activity_import_credential_name || "Select an option"}
                  startIndex={credentials?.findIndex((credential) => credential?._id?.toString() === credentialId?.toString())}
                  options={credentials?.map((credential) => ({ key: credential?.name, value: credential?._id, object: credential })) || [{ key: "Select credentials", value: -1 }]}
                  searchableFields={["name"]}
                  setOption={(option) => {
                    setCredentialId(option.value);
                  }}
                />
              </div>
              <div>
                <Button
                  version="default"
                  className={classNames("text-grey-500 group flex !h-8 items-center gap-1 text-sm font-light")}
                  disabled={datasetDisabled || !datasetId}
                  onClick={viewDataset}>
                  <p className={classNames(datasetDisabled || !datasetId ? "" : "group-hover:text-highlightColor group-hover:underline")}>View dataset</p>
                  <ArrowTopRightOnSquareIcon className={classNames(datasetDisabled || !datasetId ? "" : "group-hover:stroke-highlightColor", "h-4 w-4 stroke-1")} />
                </Button>
                <div className="flex gap-x-4">
                  <div className="w-full max-w-md">
                    <Input
                      name="dataset_id"
                      label="Dataset ID"
                      inline={true}
                      value={datasetId}
                      onChange={(e) => setDatasetId(e.target.value)}
                    />
                  </div>
                  <Button
                    disabled={datasetDisabled || !datasetId || isSyncingWithDomo}
                    version="gray"
                    onClick={handleOnManualDatasetSync}>
                    <p className="pb-1">Sync</p>
                    <ArrowPathIcon
                      className={classNames("h-5 w-5", !isSyncingWithDomo ? "" : "animate-spin")}
                      role="status"
                    />
                  </Button>
                </div>
              </div>
              <div className="relative">
                <ToggleHeader
                  title="Schedule data push"
                  subtitle="This scheduler is set update your dataset every night at 2am."
                  position="left">
                  <Toggle
                    checked={syncEnabled}
                    onChange={(e) => {
                      setSyncEnabled(!syncEnabled);
                    }}
                  />
                </ToggleHeader>
              </div>
            </div>
          </div>
        </div>
      </EditContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(ActivitySettings);
