import { PlusIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteSso } from "src/actions/sso";
import { apiRequest } from "src/async/apiUtils";
import SSOEntry from "src/components/SSO/SSOEntry";
import Button from "src/components/Shared/Buttons/Button";
import Modal from "src/components/Shared/Modal";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Search from "src/components/Shared/Search";
import TRHeader from "src/components/Shared/Table/TRHeader";
import Table from "src/components/Shared/Table/Table";
import TableOptions from "src/components/Shared/TableOptions";
import useWindowDimensions from "src/hooks/useWindowDimensions";

const SSOList = ({ sso, ...props }) => {
  const [deleteId, setDeleteId] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [isDeleteDisable, setIsDeleteDisable] = useState(false);
  const { width } = useWindowDimensions();

  const [ssos, setSsos] = useState([]);
  const [meta, setMeta] = useState(null);
  const [ssosLoaded, setSsosLoaded] = useState(false);

  const navigate = useNavigate();

  const getSsoList = useCallback(async () => {
    try {
      setSsosLoaded(false);
      const res = await apiRequest("get", `/sso/list`, {
        queries: { limit: limit, offset: offset, keyword: keyword },
      });

      if (res.data.status === 200) {
        setSsos(res.data.data || []);
        setMeta(res.data.meta);
      } else {
        setSsos([]);
        setMeta(null);
      }
      setSsosLoaded(true);
    } catch (error) {}
  }, [limit, offset, keyword]);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  useEffect(() => {
    getSsoList();
  }, [getSsoList]);

  const removeSSO = async (e) => {
    if (e) {
      e.preventDefault();
    }

    try {
      setIsDeleteDisable(true);
      const message = await props.deleteSso(deleteId);
      setDeleteId(null);
      if (ssos.length === 1 && offset !== 0) {
        setOffset(offset - 1);
      } else {
        getSsoList();
      }
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDeleteDisable(false);
    }
  };

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={(val) => {
              setOffset(0);
              setKeyword(val);
            }}
            placeholder={"Search"}
            inline={true}
          />
        }
        rightContent={
          <Button
            version="secondary"
            hoverText="Add a new SSO provider"
            onClick={() => navigate("/sso/add")}
            className="">
            <PlusIcon className="h-4 w-4 stroke-[3px]" />
            {width > 640 ? "Add SSO provider" : "SSO"}
          </Button>
        }></TableOptions>
      <Modal
        title="SSO"
        secondaryTitle="Delete"
        isOpen={!!deleteId}
        onCancel={() => setDeleteId(null)}
        onSuccess={removeSSO}
        isLoading={isDeleteDisable}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {ssos?.find((item) => item._id === deleteId)?.provider_name}?</div>
      </Modal>
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell sm:pl-6">
              Identity Provider Type
            </th>
            <th
              scope="col"
              className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell sm:pl-6">
              Identity Provider
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              IDP Entity ID OR Issuer / Domain
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              SAML login URL
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              Status
            </th>
            <th
              scope="col"
              className="relative hidden rounded-tr-lg py-3.5 pl-3 pr-4 sm:table-cell sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </TRHeader>
        }
        colSpan="5"
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        loaded={ssosLoaded}
        dataExists={ssos?.length > 0}>
        {ssos?.map((item) => (
          <SSOEntry
            key={item._id}
            sso={item}
            deleteId={deleteId}
            setDeleteId={setDeleteId}
            onChange={getSsoList}
          />
        ))}
      </Table>
      <PaginationFooter
        itemName="SSO provider"
        limit={limit}
        offset={offset}
        count={meta?.count}
        onChange={handlePaginationChange}
      />
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { deleteSso })(SSOList);
