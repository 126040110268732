import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { apiRequest } from "src/async/apiUtils";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { asyncLocalStorage } from "src/helpers/asyncLocalStorage";

const Authorization = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const tokenVerify = async () => {
    try {
      await asyncLocalStorage.removeItem("userId");
      await asyncLocalStorage.removeItem("impersonatedUserId");
      await asyncLocalStorage.removeItem("access_token");
      await asyncLocalStorage.removeItem("refresh_token");

      const { data } = await apiRequest("get", `switch-to-admin-token/verify`, { body: { token } });
      localStorage.setItem("userId", data?.data._id);
      localStorage.setItem("access_token", data?.access_token);
      localStorage.setItem("refresh_token", data?.refresh_token);
      navigate("/workspaces");
    } catch (error) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (token) tokenVerify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <Preloader text="Authentication..." />
    </div>
  );
};

export default Authorization;
