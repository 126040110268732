import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { workspaceIntegrationCredentialMakeDefault } from "src/actions/workspace";
import Button from "src/components/Shared/Buttons/Button";
import QuickMenu from "src/components/Shared/QuickMenu";
import { useDomo } from "src/context/DomoProvider";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import { formatDate } from "src/helpers/formatDate";
import DomoIntegrationClient from "./IntegrationClients/DomoIntegrationClient";
import MetabaseIntegrationClient from "./IntegrationClients/MetabaseIntegrationClient";
import QuickSightIntegrationClient from "./IntegrationClients/QuickSightIntegrationClient";
import { WindowIcon } from "@heroicons/react/24/outline";

const WorkspaceIntegrationClientsItem = ({
  workspaceId,
  user,
  integration,
  credential,
  editCredential = () => {},
  openCreatedByModal = () => {},
  removeCredential = () => {},
  makeDefaultButtonDisabled = false,
  setMakeDefaultButtonDisabled = () => {},
  refreshIntegrations = () => {},
  workspaces = [],
  ...props
}) => {
  const [makeDefaultIsLoading, setMakeDefaultIsLoading] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  const { openPageImportModal, isImporting } = useDomo();

  const getAttributeValue = (name) => {
    return credential.embed_attributes.find((embed_attribute) => embed_attribute.key === name)?.value;
  };

  const makeDefault = async () => {
    try {
      setMakeDefaultButtonDisabled(true);
      setMakeDefaultIsLoading(true);
      await props.workspaceIntegrationCredentialMakeDefault({ integration_id: integration._id, workspace_id: workspaceId, credential_id: credential?._id });
      setMakeDefaultIsLoading(false);
      setMakeDefaultButtonDisabled(false);
      refreshIntegrations();
    } catch (error) {
      setMakeDefaultIsLoading(false);
      setMakeDefaultButtonDisabled(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    let items = [];
    // Edit Cred.
    items.push({
      name: "Edit",
      onClick: () =>
        editCredential(
          integration,
          integration?.embed_types.find((embed_type) => embed_type.type === credential?.integration_type),
          credential,
        ),
      color: "text-gray-800 hover:text-highlightColor",
    });
    // Manage Created By
    user?.type === "admin" && !credential?.user?.name && items.push({ name: "Manage Created By", onClick: () => openCreatedByModal(integration, credential), color: "text-gray-800 hover:text-highlightColor" });
    // Page Import
    integration?.code === "domo" &&
      (user?._id === credential.created_by || !!user?.default_user) &&
      !isImporting &&
      credential?.embed_attributes?.find((embed_attribute) => embed_attribute.key === `developer_token`)?.value > 0 &&
      items.push({ name: "Page Import", onClick: () => openPageImportModal({ integrationId: integration._id, credentialId: credential?._id, workspaceId: credential?.workspace_id, workspaces }), color: "text-gray-800 hover:text-highlightColor" });
    // Delete Cred.
    items.push({ name: "Delete", onClick: () => removeCredential(integration._id, credential?._id), color: "text-red-500" });
    setMenuItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.type, integration, credential, workspaces.length, isImporting]);

  return (
    <div className="relative flex w-full flex-col space-y-4 rounded-md border border-gray-50 bg-white px-3 py-3 shadow md:space-y-0 lg:h-12 lg:flex-row lg:px-4 lg:py-1">
      <div className="flex pr-10 md:pr-[200px] lg:w-full lg:pr-0">
        {integration?.code === "domo" && (
          <DomoIntegrationClient
            credential={credential}
            getAttributeValue={getAttributeValue}
          />
        )}
        {integration?.code === "quicksight" && (
          <QuickSightIntegrationClient
            credential={credential}
            getAttributeValue={getAttributeValue}
          />
        )}
        {integration?.code === "metabase" && (
          <MetabaseIntegrationClient
            credential={credential}
            getAttributeValue={getAttributeValue}
          />
        )}
      </div>

      <div className="static flex h-auto flex-shrink-0 items-center justify-between md:absolute md:bottom-0 md:right-3 md:h-full md:items-center md:justify-end md:py-2 lg:relative lg:bottom-auto lg:right-auto lg:w-60 lg:justify-between lg:gap-3 lg:py-0 xl:w-96 2xl:w-[400px]">
        <div className={classNames("flex items-center justify-center gap-1 text-sm", credential?.pages > 0 ? "text-gray-900" : "text-gray-300")}>
          <WindowIcon className="h-5 w-5" /> {credential?.pages || 0}
        </div>
        <div className="flex flex-col sm:items-end lg:items-start lg:justify-center">
          {credential?.user?.name ? <div className="text-sm font-semibold text-gray-800">{credential?.user?.name}</div> : <div className="text-sm font-semibold text-red-500">Unknown User</div>}
          <div className="flex items-center text-xs text-gray-400">Created {formatDate(credential?.created_at, "MM/DD/YYYY")}</div>
        </div>
        <div className="flex items-center gap-x-1">
          {authorizeUserComponentAccess(user, workspaceId, "integration", ["update"]) && (
            <>
              {workspaceId && credential?.created_by?.toString() === user?._id?.toString() && (
                <Button
                  disabled={makeDefaultButtonDisabled || credential?.default_users_credential?.includes(user?._id)}
                  loading={makeDefaultIsLoading}
                  onClick={makeDefault}
                  version={credential?.default_users_credential?.includes(user?._id) ? "default" : "gray"}
                  className={classNames("flex !h-9 items-center justify-center rounded-md px-2 text-xs font-semibold leading-5", credential?.default_users_credential?.includes(user?._id) ? "bg-green-200 text-green-700 opacity-50" : "bg-gray-50 text-gray-500")}>
                  {credential?.default_users_credential?.includes(user?._id) ? "Default" : "Make default"}
                </Button>
              )}
              <div className="absolute right-0 top-2 sm:-right-2 lg:relative lg:right-auto lg:top-auto">
                <QuickMenu
                  xPlacement="bottomRight"
                  flip={false}
                  items={menuItems}
                  buttonClass="!w-8 !aspect-square !flex-shrink-0 !rounded-md !p-0"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { workspaceIntegrationCredentialMakeDefault })(WorkspaceIntegrationClientsItem);
