import { ArrowPathIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { handleUpdateDomoData, manageSiteSettings, updateSiteSettingsApiState } from "src/actions/site";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import QuickMenu from "src/components/Shared/QuickMenu";
import { SecureSettingsContext } from "src/context/SecureSettingsContext";
import { classNames } from "src/helpers/classNames";
import useFetch from "src/hooks/useFetch";
import Input from "src/components/Shared/Forms/Inputs/Input";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";

const UserImportDataset = ({ updateSiteSettingsApiState, ...props }) => {
  const { site, loadSite: loadSecureSiteData } = useContext(SecureSettingsContext);

  const [updateChanges, setUpdateChanges] = useState(0);
  const [apiState, setApiState] = useState("PORTAL_CREATED");
  const [isSync, setSync] = useState(false);
  const [credentialId, setCredentialId] = useState(null);
  const [userImportDatasetId, setUserImportDatasetId] = useState("");
  const [passwordState, setPasswordState] = useState(false);
  const [individualPageAccess, setIndividualPageAccess] = useState(false);
  const [isUpdateDisable, setIsUpdateDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    response: { data: credentials },
  } = useFetch(`/credentials/all`, { query: { code: "domo" } });

  useEffect(() => {
    loadSecureSiteData();
  }, [loadSecureSiteData]);

  const handleStateUpdate = (data) => {
    setCredentialId(data?.user_import_credential_id || null);
    setApiState(data.api_state || "");
    setUserImportDatasetId(data.user_import_dataset_id || "");
    setPasswordState(data.password_state || false);
    setIndividualPageAccess(data?.individual_page_access || false);
  };

  useEffect(() => {
    if (site._id) {
      handleStateUpdate(site);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const onClear = () => {
    handleStateUpdate(site);
    setUpdateChanges(0);
  };

  const viewDataset = async () => {
    try {
      setIsUpdateDisable(true);
      const { data } = await apiRequest("POST", `/activities/view-dataset`, { body: { from: "user" } }, { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} });
      window.open(data?.data?.url, "_blank");
    } catch (error) {
    } finally {
      setIsUpdateDisable(false);
    }
  };

  const onSubmit = async () => {
    try {
      setIsUpdateDisable(true);
      let body = {
        password_state: passwordState,
        user_import_dataset_id: userImportDatasetId,
        user_import_credential_id: credentialId,
        api_state: apiState,
        individual_page_access: individualPageAccess,
      };

      await apiRequest("PUT", `/site-settings/user_import_dataset`, { body }, { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} });
      loadSecureSiteData();
      updateSiteSettingsApiState({ api_state: apiState });

      setUpdateChanges(0);
    } catch (error) {
    } finally {
      setIsUpdateDisable(false);
    }
  };

  const updateDomo = async (requestType = "pull") => {
    try {
      setIsLoading(true);
      setSync(true);
      let message = await handleUpdateDomoData(requestType);
      toast.success(message);
      setSync(false);

      setUpdateChanges(0);
    } catch (error) {
      toast.error(error.message);
      setSync(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="right-3 top-6 -ml-4 sm:absolute">
        <ToggleHeader>
          <Toggle
            checked={"DOMO_DATASET" === apiState}
            onChange={(e) => {
              setApiState(e ? "DOMO_DATASET" : "PORTAL_CREATED");
              setUpdateChanges((cur) => cur + 1);
            }}
          />
        </ToggleHeader>
      </div>
      <div className="flex w-full flex-col justify-between gap-y-2 lg:gap-x-12">
        <div className="relative w-full rounded-md">
          <div className={classNames("absolute z-20 flex h-full w-full items-center justify-center rounded-xl bg-gray-100 opacity-80", apiState !== "DOMO_DATASET" ? "absolute" : "hidden")}>
            <div className="flex w-full rotate-[-10deg] select-none justify-center text-center text-4xl font-extrabold text-gray-300 md:text-4xl lg:text-5xl xl:text-7xl">For Domo Dataset Use</div>
          </div>
          <div className="flex w-full flex-col gap-y-6 px-4 pb-6 pt-12">
            <div className="grid space-y-8 text-sm ">
              <div className="grid space-y-2 text-sm">
                <div className="relative flex w-full items-center justify-end gap-x-4">
                  <div className="absolute -top-10 right-0 flex items-center">
                    <div className="flex justify-end">
                      <QuickMenu
                        disabled={site?.api_state !== "DOMO_DATASET" || apiState !== "DOMO_DATASET"}
                        items={[
                          {
                            name: "Upload users to dataset",
                            onClick: () => updateDomo("push"),
                            color: "text-red-600",
                          },
                        ]}
                      />
                    </div>
                    <Button
                      className="!h-10"
                      disabled={site?.api_state !== "DOMO_DATASET" || apiState !== "DOMO_DATASET" || isLoading}
                      version="gray"
                      onClick={() => updateDomo("pull")}>
                      <p className="pb-1">Run manual sync</p>
                      <ArrowPathIcon
                        className={classNames("h-5 w-5", !isSync ? "" : "animate-spin")}
                        role="status"
                      />
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="relative">
                    <SelectMenu
                      inline={true}
                      label="DOMO credentials"
                      emptyListText={props?.siteData?.user_import_credential_name || "No listed items"}
                      defaultText={props?.siteData?.user_import_credential_name || "Select an option"}
                      startIndex={credentials?.findIndex((credential) => credential?._id?.toString() === credentialId?.toString())}
                      options={credentials?.map((credential) => ({ key: credential?.name, value: credential?._id, object: credential })) || [{ key: "Select credentials", value: -1 }]}
                      searchableFields={["name"]}
                      setOption={(option) => {
                        setCredentialId(option.value);
                        setUpdateChanges((cur) => cur + 1);
                      }}
                    />
                  </div>
                  <div className="relative">
                    <Input
                      name="dataset_id"
                      label="Dataset ID"
                      inline={true}
                      value={userImportDatasetId}
                      onChange={(e) => {
                        setUserImportDatasetId(e.target.value);
                        setUpdateChanges((cur) => cur + 1);
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <Button
                    version="default"
                    className="text-grey-500 flex !h-8 items-center gap-1 text-sm font-semibold hover:text-highlightColor hover:underline"
                    disabled={isUpdateDisable}
                    onClick={viewDataset}>
                    View Dataset
                    <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {updateChanges > 0 && (
          <div className="flex w-full justify-end gap-x-4">
            <Button
              disabled={isUpdateDisable || updateChanges === 0}
              onClick={onSubmit}>
              Save
            </Button>
            <Button
              disabled={isUpdateDisable || updateChanges === 0}
              version="gray"
              onClick={onClear}>
              Cancel
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteData: state.site,
  };
};

export default connect(mapStateToProps, {
  manageSiteSettings,
  updateSiteSettingsApiState,
})(UserImportDataset);
