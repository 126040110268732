import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Modal from "src/components/Shared/Modal";
import PillEntry from "src/components/Workspaces/PillEntry";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";

const WorkspaceGroupTagManageModal = ({ title = "", secondaryTitle = "", isOpen, onCancel = () => {}, onSuccess = () => {}, groupDetails = {}, workspaceId = null }) => {
  const [tag, setTag] = useState("");
  const [tagList, setTagList] = useState([]);
  const [disabled, setDisabled] = useState(null);

  const handleTag = async (e) => {
    if ((e.code === "Enter" || e.code === "Comma" || e.submit === true) && tag.trim()) {
      e.preventDefault();
      if (tagList.findIndex((tg) => tg.toLowerCase() === tag.toLowerCase()) === -1) {
        const tags = [...tagList, tag];
        setTagList(tags);
        setTag("");
      }
    }
  };

  const removeTag = async (index) => {
    tagList.splice(index, 1);
    setTagList([...tagList]);
  };

  const handleManageTagGroup = async () => {
    try {
      setDisabled(true);
      const { data } = await apiRequest("put", `/workspaces/:workspace_id/groups/:group_id/tags`, { body: { tags: tagList }, params: { workspace_id: workspaceId, group_id: groupDetails._id || groupDetails.id } });
      onSuccess();
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    setTagList(groupDetails?.tags || []);
    setTag("");
  }, [groupDetails?._id, groupDetails?.tags]);

  return (
    <>
      <Modal
        title={title}
        secondaryTitle={secondaryTitle}
        isOpen={isOpen}
        onCancel={onCancel}
        onSuccess={handleManageTagGroup}
        isLoading={disabled}
        defaultOptions={{
          onSuccessButtonText: "Save",
        }}
        size="lg"
        defaultStyles={{
          overFlowYVisible: false,
        }}>
        <div className="relative min-h-[200px] overflow-visible py-4">
          <div className="mb-4 space-y-4">
            <div className="w-full">
              <Input
                type="text"
                autoComplete="off"
                name="tag-name"
                label="Tags"
                inline={true}
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                onKeyDown={handleTag}
              />
            </div>
            {tagList?.length > 0 && (
              <div className="flex flex-wrap items-center gap-2">
                {tagList?.map((tag, index) => {
                  return (
                    <PillEntry
                      index={index}
                      tag={tag}
                      onDelete={removeTag}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(WorkspaceGroupTagManageModal);
