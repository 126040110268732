import React, { useCallback } from "react";
import { connect } from "react-redux";
import LazyImage from "src/components/Shared/LazyImage";

const AuthDataLayout = ({ site, header, children }) => {
  const BackgroundImage = useCallback(
    () => (
      <div className="absolute left-0 top-0 z-[-1] flex h-full w-full">
        {site?._id && (
          <LazyImage
            src={`${site?.background_logo}?${new Date().getTime()}`}
            alt={"Logo"}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            classes={"object-cover"}
            hideImageOnError
          />
        )}
      </div>
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [site?._id],
  );
  return (
    <>
      <BackgroundImage />
      {header}
      {children}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, null)(AuthDataLayout);
