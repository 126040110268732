import React, { useEffect, useState } from "react";
import { QuestionMarkCircleIcon, InboxStackIcon, Cog6ToothIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { H1 } from "src/components/Shared/Text/Headers";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { connect } from "react-redux";
import BackIndicator from "src/components/Navigation/BackIndicator";
import FormQuestion from "src/components/Forms/FormQuestion";
import FormResponse from "src/components/Forms/FormResponse";
import FormSettings from "src/components/Forms/FormSettings";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Button from "src/components/Shared/Buttons/Button";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";

const FormManage = ({ ...props }) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [name, setName] = useState("");
  const [editName, setEditName] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const router = useLocation();
  const navigate = useNavigate();

  const existsPath = props.menus.some((obj) => router?.pathname?.includes(obj.path));

  if (Object.keys(props.menus).length && !existsPath) {
    navigate("/");
  }

  const onHandleSubmit = async () => {
    try {
      setEditName(false);
      let url = `/forms/:form_id/name`;
      let method = "PUT";
      if (searchParams.get("workspace_id")) url = `/workspaces/:workspace_id${url}`;

      const { data } = await apiRequest(method, url, {
        body: { name },
        params: { workspace_id: searchParams.get("workspace_id"), form_id: id },
      });
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (id) {
      setTabs([
        {
          name: "Questions",
          icon: QuestionMarkCircleIcon,
          component: (
            <FormQuestion
              name={name}
              setName={setName}
            />
          ),
          // hidden: searchParams.get("is_global") === "true" && searchParams.get("workspace_id"),
        },
        {
          name: "Responses",
          icon: InboxStackIcon,
          component: <FormResponse />,
        },
        {
          name: "Settings",
          icon: Cog6ToothIcon,
          component: <FormSettings />,
          // hidden: searchParams.get("is_global") === "true" && searchParams.get("workspace_id"),
        },
      ]);
    } else {
      setTabs([]);
    }
  }, [id, name, searchParams]);

  if (!props?.user?._id) return <></>;

  return (
    <>
      {Object.keys(props.menus).length > 0 && existsPath && (
        <>
          <H1 item={{ icon: BackIndicator, onClick: () => navigate(searchParams.get("pathname")) }}>
            <p className="font-light capitalize text-gray-300">Form /</p>
            {id ? `Edit` : `Add`}
            <div className="relative ml-10 h-10 font-normal text-gray-500">
              {!id || editName ? (
                <>
                  {!["Responses", "Settings"]?.includes(searchParams?.get("tab")) && (
                    <div className="-mt-1 flex gap-x-2 lg:min-w-[400px]">
                      <Input
                        inline={true}
                        label={"Form name"}
                        wrapperClass={"!bg-white !font-normal"}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setDisabled(false);
                        }}
                      />
                      {id && (
                        <div className="flex gap-x-1">
                          <Button
                            version="gray"
                            disabled={disabled}
                            onClick={() => {
                              setName("");
                            }}>
                            Cancel
                          </Button>
                          <Button
                            disabled={disabled}
                            onClick={onHandleSubmit}>
                            Save
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <div className="flex">
                  {name}
                  {!["Responses", "Settings"]?.includes(searchParams?.get("tab")) && (
                    <button
                      onClick={() => {
                        setEditName(name);
                      }}
                      className="-mt-1 ml-2 flex cursor-pointer">
                      <PencilSquareIcon className="h-5 w-5 stroke-2 transition-all hover:text-gray-800" />
                    </button>
                  )}
                </div>
              )}
            </div>
          </H1>
          {id && searchParams.get("is_global") === "true" && searchParams.get("workspace_id") ? (
            <FormResponse />
          ) : tabs?.length > 0 ? (
            <Tabs tabs={tabs} />
          ) : (
            <FormQuestion
              name={name}
              setName={setName}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    menus: Object.values(state.menus),
  };
};

export default connect(mapStateToProps, {})(FormManage);
