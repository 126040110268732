import { CheckIcon } from "@heroicons/react/24/outline";
import { classNames } from "src/helpers/classNames";

const RadioGroup = ({
  name = "option-method",
  title = "Radio Group",
  subtitle = "Which of these options do you prefer?",
  options = [
    { id: "yes", title: "Yes" },
    { id: "no", title: "No" },
  ],
  defaultChecked = "yes",
  onChange = () => {},
  layout = "veritcal",
  required = false,
  labelClass,
  scaleTitle = null,
}) => {
  return (
    <div className="space-y-4">
      <label className={classNames("text-base font-medium text-gray-700", labelClass)}>
        {title} {required && <span className="pl-1 text-base font-semibold !leading-3 text-red-500">*</span>}
      </label>
      {subtitle && <p className="text-sm text-gray-400">{subtitle}</p>}
      <fieldset className="">
        <legend className="sr-only">
          {title} - {subtitle}
        </legend>
        <div className={classNames("space-y-2", layout === "horizontal" || layout === "scale" ? "sm:flex sm:items-end sm:space-x-10 sm:space-y-0" : "")}>
          {layout === "scale" && scaleTitle?.from && (
            <div className={classNames("relative flex items-center")}>
              <div className={classNames("block text-sm font-medium leading-6 text-gray-600")}>{scaleTitle?.from}</div>
            </div>
          )}
          <div className={classNames("", layout === "horizontal" ? "space-y-2 sm:flex sm:items-center sm:space-x-10 sm:space-y-0" : layout === "scale" ? "space-x-10 sm:flex sm:items-center" : "")}>
            {options.map((option) => (
              <div
                key={option.id}
                className={classNames("relative flex items-center ", layout === "scale" ? "flex-col-reverse gap-y-4 p-0" : " py-1")}>
                <div className={classNames("relative flex items-center")}>
                  <input
                    id={option.id}
                    name={name}
                    type="radio"
                    defaultChecked={option.id === defaultChecked}
                    value={option.id}
                    onChange={(e) => onChange(e.target.value)}
                    className="peer absolute left-px top-px h-5 w-5 cursor-pointer rounded-full opacity-0"
                  />
                  <div className="flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full border border-gray-300 bg-transparent text-transparent peer-checked:border-highlightColor peer-checked:bg-highlightColor peer-checked:text-white">
                    <CheckIcon className="h-4 w-4" />
                  </div>
                </div>
                <label
                  htmlFor={option.id}
                  className={classNames("block text-sm font-medium leading-6 text-gray-600", layout !== "scale" ? "ml-3" : "")}>
                  {option.title}
                </label>
              </div>
            ))}
          </div>
          {layout === "scale" && scaleTitle?.to && (
            <div className={classNames("relative flex items-center")}>
              <div className={classNames(" block text-sm font-medium leading-6 text-gray-600")}>{scaleTitle?.to}</div>
            </div>
          )}
        </div>
      </fieldset>
    </div>
  );
};

export default RadioGroup;
