import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { logout } from "src/actions/auth";
import { getMenuData } from "src/actions/menu";
import { useNavigate } from "react-router";

import AdminLayout from "src/layouts/AdminLayout";
import AuthLayout from "src/layouts/AuthLayout";
import UserLayout from "src/layouts/UserLayout";

import { getAuthorizedUser } from "src/actions/auth";
import useFetch from "src/hooks/useFetch";
import UserLayoutWrapper from "./UserLayoutWrapper";

import { PortalWorkspaceLoadContext } from "src/context/PortalWorkspaceLoad";
import BlankLayout from "./BlankLayout";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { resetWorkspaceDetails } from "src/actions/page";

const Layout = ({ logout, ...props }) => {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);

  const [routeLayout, setRouteLayout] = useState(<AuthLayout {...props}>{props.children}</AuthLayout>);
  const [portalWorkspaceLoaded, setPortalWorkspaceLoaded] = useState(false);

  const routeTypes = {
    auth: { routes: ["/forgot-password/submit-email", "/forgot-password/verify-token", "/forgot-password/password-reset", "/token", "/login", "/sso-logout"], layout: <AuthLayout {...props}>{props.children}</AuthLayout> },
    error: { routes: ["/404", "/500", "/technical-issues"], layout: <>{props.children}</> },
  };

  const { response: authorizedUser, refreshData: refreshAuthorizedUser } = useFetch(
    localStorage.getItem("impersonatedUserId") ? "/users/get-details" : "/users/self-details",
    {
      method: "post",
      data: {
        id: localStorage.getItem("impersonatedUserId") || localStorage.getItem("userId") || props.user?._id,
        token: localStorage.getItem("refresh_token") || props.user?.refresh_token,
      },
    },
    localStorage.getItem("access_token") || props.user?.access_token,
    // window.location.pathname !== '/' && window.location.pathname !== '/login' && localStorage.getItem('accessToken') && localStorage.getItem('accessToken').length > 0
  );

  const userTypeRouteLayout = async () => {
    if (authorizedUser?.data?.type === "user" || (authorizedUser?.data?.type === "editor" && localStorage.getItem("impersonatedUserId"))) {
      setRouteLayout(
        <PortalWorkspaceLoadContext.Provider value={{ portalWorkspaceLoaded, setPortalWorkspaceLoaded, setShowLoader }}>
          <UserLayout {...props}>
            <UserLayoutWrapper setShowLoader={setShowLoader}>{props.children}</UserLayoutWrapper>
          </UserLayout>
        </PortalWorkspaceLoadContext.Provider>,
      );
    } else {
      setShowLoader(false);
      setRouteLayout(<AdminLayout {...props}>{props.children}</AdminLayout>);
    }
  };

  useEffect(() => {
    refreshAuthorizedUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    if (window.location.pathname === "/logout/callback" && window.location.pathname?.includes("/authorization")) {
      setRouteLayout(<BlankLayout {...props}>{props.children}</BlankLayout>);
    } else {
      if (!localStorage.getItem("access_token")) {
        setRouteLayout(<AuthLayout {...props}>{props.children}</AuthLayout>);
      } else {
        if (authorizedUser.status === 200) {
          userTypeRouteLayout(window.location.pathname);
          const inactivityLimit = 43200;
          let inactivityTime = 0;

          const resetInactivityTime = () => {
            inactivityTime = 0;
          };

          const incrementInactivityTime = () => {
            inactivityTime++;
            if (inactivityTime >= inactivityLimit) {
              logout(props.site);
            }
          };

          document.addEventListener("mousemove", resetInactivityTime);
          document.addEventListener("keydown", resetInactivityTime);
          document.addEventListener("click", resetInactivityTime);

          const interval = setInterval(incrementInactivityTime, 60000); // Check every minute
          return () => {
            clearInterval(interval);
          };
        } else {
          isAuthRouteLayout(window.location.pathname);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizedUser]);

  const isAuthRouteLayout = (url) => {
    if ("/" === url) {
      setRouteLayout(routeTypes.auth.layout);
      return;
    }

    for (let type in routeTypes) {
      if (routeTypes[type]) {
        let foundRoute = routeTypes[type].routes.filter((route) => url?.includes(route));
        if (foundRoute.length > 0) {
          setRouteLayout(routeTypes[type].layout);
          return;
        }
      }
    }
  };

  if (showLoader) {
    setTimeout(async () => {
      await refreshAuthorizedUser();
      navigate("/pages");
      props.resetWorkspaceDetails();
    }, 1000);
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Preloader />
      </div>
    );
  }

  return routeLayout;
};

const mapStateToProps = (state) => {
  return {
    collapse: state.collapse,
    menus: Object.values(state.menus),
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { getMenuData, logout, getAuthorizedUser, resetWorkspaceDetails })(Layout);
