import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { classNames } from "src/helpers/classNames";

const SubArrowIcon = ({ className = "" }) => {
  return (
    <div className={classNames("flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-gray-50", className)}>
      <ChevronRightIcon className="ml-[1px] h-4 w-4 stroke-gray-400 stroke-[3px]" />
    </div>
  );
};

export default SubArrowIcon;
