import { useEffect, useState } from "react";
import { connect } from "react-redux";
import QuickMenu from "src/components/Shared/QuickMenu";
import TRBody from "src/components/Shared/Table/TRBody";
import TableRow from "src/components/Shared/Table/TableRow";
import { isSVGString } from "src/helpers";
import Image from "src/components/Shared/Image";
import { noFavicon } from "src/config/host";
import { UserIcon, WindowIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { classNames } from "src/helpers/classNames";

const GroupEntry = ({ user, group, setDeleteId, site, ...props }) => {
  const [items, setItems] = useState([]);
  const [image, setImage] = useState(null);

  useEffect(() => {
    let item = [];
    if (user?.type === "admin" || (user?.user_permissions && user?.user_permissions?.group_update)) {
      item.push({
        name: "Edit",
        onClick: () => {
          // navigate(`/groups/edit/${group._id}`);
          props.setEditId(group._id);
          props.setGroupModalOpen(true);
          props.setEditGroupStatus(true);
          props.setAddGroupStatus(false);
        },
      });
    }
    if (user?.type === "admin" || (user?.user_permissions && user?.user_permissions?.group_delete)) {
      item.push({
        name: "Delete",
        onClick: () => {
          setDeleteId(group._id);
        },
        color: "text-red-500",
      });
    }
    setItems(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setImage(group.image);
  }, [group.image]);

  return (
    <TableRow keyId={group?._id + "group"}>
      <TRBody className="whitespace-pre-wrap">
        <div className="flex h-11 items-center">
          {image && (
            <div className="mr-2 h-8 w-8 overflow-hidden p-1">
              {isSVGString(image) ? (
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: image }}
                />
              ) : (
                <Image
                  image={image}
                  failImage={noFavicon}
                  alt={"Groups"}
                  onError={() => {
                    setImage(null);
                  }}
                />
              )}
            </div>
          )}
          <div className="font-medium text-gray-900">{group?.name}</div>
        </div>
      </TRBody>

      <TRBody className="whitespace-pre-wrap">
        <Link to={`/workspaces/${group?.workspace_id?._id}?tab=Pages`}>
          <div
            className={classNames(
              "flex w-12 cursor-pointer gap-x-1 font-medium transition-all duration-100 hover:underline",
              (group?.workspace_id?.workspace_type === "JWT_FULL_EMBED" ? group?.permissions?.length > 0 : group?.permissions?.length > 0) ? "text-gray-900" : "text-gray-300",
            )}>
            <WindowIcon className="h-5 w-5" />
            {group?.permissions.length}
          </div>
        </Link>
      </TRBody>
      <TRBody className="whitespace-pre-wrap">
        <Link to={`/workspaces/${group?.workspace_id?._id}?tab=Users`}>
          <div
            className={classNames("flex w-12 cursor-pointer gap-x-1 font-medium transition-all duration-100 hover:underline", (group?.workspace_id?.workspace_type === "JWT_FULL_EMBED" ? group?.user_id.length > 0 : group?.user_id.length > 0) ? "text-gray-900" : "text-gray-300")}>
            <UserIcon className="h-5 w-5" />
            {group?.user_id.length}
          </div>
        </Link>
      </TRBody>
      <td className="whitespace-nowrap py-2 pl-3 pr-4 text-sm font-medium">
        {(user?.type === "admin" || (user?.user_permissions && (user?.user_permissions?.group_update || user?.user_permissions?.group_delete))) && (
          <div className="flex justify-end">
            <QuickMenu items={items} />
          </div>
        )}
      </td>
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(GroupEntry);
