import { Listbox, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { classNames } from "src/helpers/classNames";
import Input from "src/components/Shared/Forms/Inputs/Input";

export default function SelectMenu({ options, defaultText = "Select an option", disabled = false, isSelected = false, emptyListText = "No listed items", setOption, label, labelClass, startIndex = 0, classes = "", searchableFields = [], dropdownClass, inline = false, required }) {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selected, setSelected] = useState(0);
  const handleSelection = (e) => {
    setSelected(e);
    setOption(e);
  };

  useEffect(() => {
    setFilteredOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (searchableFields.length > 0) {
      let updatedFilteredOptions = options.filter((option) => {
        let keywordMatched = false;
        searchableFields.forEach((searchableField) => {
          if (option?.object[searchableField]?.toLowerCase().includes(keyword.toLowerCase())) {
            keywordMatched = true;
          }
        });
        return keywordMatched;
      });
      setFilteredOptions(updatedFilteredOptions);
    }
  }, [keyword, options, searchableFields]);

  useEffect(() => {
    if (startIndex >= 0) {
      setSelected(options[startIndex]);
    } else {
      setSelected({ key: defaultText, value: -1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startIndex, defaultText]);

  return (
    <div className="relative">
      <div className={classNames("absolute left-0 top-0 z-20 h-full w-full", disabled ? "cursor-not-allowed" : "hidden")}></div>
      <Listbox
        value={selected}
        onChange={handleSelection}>
        {({ open }) => (
          <>
            {label && !inline && <Listbox.Label className={classNames("mb-1 block text-sm font-medium text-gray-700", labelClass)}>{label} {required && (<span className="pl-1 text-red-500 text-base !leading-3 font-semibold">*</span>)}</Listbox.Label>}
            <div className={classNames("relative flex rounded-md")}>
              <Listbox.Button
                disabled={options.length === 0}
                className={classNames(
                  "relative h-[47px] w-full cursor-pointer rounded-md border border-gray-300 bg-white text-left hover:bg-gray-50  focus:outline-none sm:text-sm md:h-[40px] 2xl:h-[47px]",
                  inline ? "px-0 pt-2 md:pt-1 2xl:pt-2" : "py-2 pl-3 pr-10",
                  disabled ? "bg-gray-100" : "",
                  open ? "border-highlightColor" : "",
                  classes,
                )}>
                {inline && <Listbox.Label className={classNames("m-0 flex justify-between pl-3 text-xs font-semibold uppercase leading-none text-gray-400 md:pl-2 md:text-[10px] 2xl:pl-3", labelClass)}>{label} {required && (<span className="pl-1 text-red-500 text-base !leading-3 font-semibold">*</span>)}</Listbox.Label>}
                <div className={classNames(inline && "flex h-8 w-full items-center  rounded-none border-none px-3 py-0 leading-none text-gray-700 shadow-none focus:ring-0 sm:text-sm md:h-7 md:px-2 2xl:h-8 2xl:px-3")}>
                  {disabled ? (
                    options.length && options[startIndex]?.Component ? (
                      options[startIndex]?.Component()
                    ) : (
                      defaultText
                    )
                  ) : (
                    <span className="block truncate">{options.length === 0 ? emptyListText : selected ? (selected?.Component ? selected.Component() : selected.key) : defaultText}</span>
                  )}
                </div>

                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                  {isSelected ? (
                    <CheckIcon
                      className="mr-2 mt-5 h-5 w-5 text-highlightColor"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as="div"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className={classNames("absolute left-0 top-full z-50 flex min-w-[200px] flex-col overflow-hidden rounded-md bg-white text-base shadow-lg sm:text-sm", dropdownClass)}>
                  <Listbox.Options className={classNames("text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm")}>
                    {searchableFields.length > 0 && (
                      <div className="sticky left-0 top-0 z-20 mb-2 w-full bg-white p-1.5">
                        <Input
                          type="text"
                          autoComplete="off"
                          name="search"
                          id="search"
                          value={keyword}
                          className="block w-full rounded-md border-gray-300 pl-9 text-sm focus:border-highlightColor focus:ring-0"
                          inputClassNames={"!h-10 block w-full sm:text-sm border-gray-300 rounded-md shadow-sm focus:ring-0 focus:border-highlightColor !pl-8"}
                          onKeyDown={(e) => e.stopPropagation()}
                          onChange={(e) => setKeyword(e.target.value)}
                        />
                        <div
                          className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                          aria-hidden="true">
                          <MagnifyingGlassIcon
                            className="mr-3 h-4 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    )}
                    <div className="max-h-60 overflow-auto">
                      {filteredOptions.length === 0 ? (
                        <Listbox.Option
                          key="no-option"
                          value={0}
                          className="relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-400 hover:bg-gray-50">
                          {emptyListText}
                        </Listbox.Option>
                      ) : (
                        filteredOptions.map((option) => (
                          <Listbox.Option
                            key={option.value}
                            className={({ active }) => classNames(active ? "text-highlightColor" : "text-gray-900", "relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-gray-50")}
                            value={option}>
                            {({ selected, active }) => (
                              <>
                                {option.Component ? option.Component() : <p className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>{option.key}</p>}
                                {selected ? (
                                  <span className={classNames(active ? "text-highlightColor" : "text-gray-900", "absolute inset-y-0 right-0 flex items-center pr-4")}>
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))
                      )}
                    </div>
                  </Listbox.Options>
                </div>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
