import React, { useState } from "react";
import useEventListener from "src/hooks/useEventListener";
import { classNames } from "src/helpers/classNames";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import { FeatureFlags } from "src/context/FeatureFlagContext";
import Button from "src/components/Shared/Buttons/Button";

const ToggleFlags = ({ editFlags }) => {
  const [showMenu, setShowMenu] = useState(false);

  function handler(e) {
    if (e.key?.toLowerCase(e.key) === "o" && e.ctrlKey && e.shiftKey) {
      setShowMenu(!showMenu);
    }
  }

  useEventListener("keydown", handler);

  return (
    <FeatureFlags.Consumer>
      {(flags) => {
        return (
          <div className={classNames("p-4 pt-14 fixed z-30 -right-[300px] top-0 h-full w-[300px] bg-white shadow-lg border-l-[1px] border-gray-200 transition-all duration-150", showMenu ? "right-[0px]" : "")}>
            <Button type="button" version="default" className="hover:bg-gray-100 p-1 rounded-full absolute top-2 right-2 bg-white text-red-400 hover:text-red-500 focus:outline-none" onClick={() => setShowMenu(!showMenu)}>
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </Button>
            <div className="relative border border-gray-200 h-full rounded-md pt-6">
              <div className="absolute -top-2.5 left-5 bg-white px-2 text-gray-400">Feature flags</div>
              {Array.isArray(flags) &&
                flags?.map((flag) => {
                  return (
                    <div key={flag.name} className="flex py-4 px-6 space-x-2 items-center rounded-full">
                      <Toggle checked={flag?.active} onChange={(e) => editFlags(e, flag.name)} />
                      <span className="text-sm font-medium">{flag.name}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      }}
    </FeatureFlags.Consumer>
  );
};

export default ToggleFlags;
