import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { userWorkspacesAndAssociatedPermissions, userPermissionCheckboxManage } from "src/actions/workspace";
import MultiColumnContent from "src/components/Shared/MultiColumnContent/MultiColumnContent";
import WorkspacePermissionSelectedItem from "src/components/Workspaces/Workspace/Permissions/WorkspacePermissionSelectedItem";
import UserPermissionHeader from "./UserPermissionHeader";
import WarningModal from "src/components/Shared/WarningModal";
import { toast } from "react-toastify";

const UserPermissions = ({ loaded = false, workspaceList = [], setWorkspaceEditorList = () => {}, userId = null, ...props }) => {
  const [workspaceSearchList, setWorkspaceSearchList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [activeWorkspace, setActiveWorkspace] = useState(null);
  const [permissionChanges, setPermissionChanges] = useState(0);
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(null);

  const onClear = () => {
    setActiveWorkspace((prevData) => ({
      ...prevData,
      ...workspaceList.find((wrk) => wrk?.workspace_id?._id === prevData?.workspace_id?._id),
    }));
    setPermissionChanges(0);
  };

  const savePermissionChanges = async () => {
    try {
      const resp = await props.userPermissionCheckboxManage({ user_id: userId, permissions: activeWorkspace, workspace_id: activeWorkspace?.workspace_id?._id });
      setWorkspaceEditorList((prevData) => [
        ...prevData.map((data) => {
          if (data?.workspace_id?._id === activeWorkspace?.workspace_id?._id) {
            return {
              ...data,
              ...activeWorkspace,
            };
          } else {
            return data;
          }
        }),
      ]);
      setPermissionChanges(0);
      toast.success(resp);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    if (keyword) {
      setWorkspaceSearchList(workspaceList.filter((workspace) => workspace.workspace_id.name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())));
    } else {
      setWorkspaceSearchList(workspaceList ? workspaceList : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceList, keyword]);

  useEffect(() => {
    if (workspaceSearchList.length > 0) {
      if (!activeWorkspace) {
        setActiveWorkspace(workspaceSearchList[0]);
      }
      if (activeWorkspace && !workspaceSearchList.map((workspace) => workspace?._id).includes(activeWorkspace?._id)) {
        setActiveWorkspace(workspaceSearchList[0]);
      }
    } else {
      setActiveWorkspace(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceSearchList]);

  return (
    <>
      <MultiColumnContent
        headerContentConfig={{
          headerEnabled: true,
          searchEnabled: true,
          keyword: keyword,
          onKeywordChange: (value) => setKeyword(value),
        }}
        listContentConfig={{
          listLoaded: loaded,
          listData: workspaceSearchList,
          listComponent: (props) => (
            <UserPermissionHeader
              {...props}
              activeWorkspace={activeWorkspace}
              permissionChanges={permissionChanges}
              setActiveWorkspace={setActiveWorkspace}
              setWarningModalIsOpen={setWarningModalIsOpen}
            />
          ),
        }}
        selectedContentConfig={{
          selectedData: activeWorkspace,
          selectedComponent: () => (
            <WorkspacePermissionSelectedItem
              activeUser={activeWorkspace}
              setActiveUser={setActiveWorkspace}
              permissionChanges={permissionChanges}
              setPermissionChanges={setPermissionChanges}
              savePermissionChanges={savePermissionChanges}
              onClear={onClear}
              workspaceId={activeWorkspace?.workspace_id?._id}
              disabled={false}
              pageName={"user"}
            />
          ),
        }}
      />
      <WarningModal
        isOpen={warningModalIsOpen?._id ? true : false}
        onSuccess={() => {
          setActiveWorkspace(warningModalIsOpen);
          setPermissionChanges(0);
          setWarningModalIsOpen(null);
        }}
        onCancel={() => setWarningModalIsOpen(null)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, { userWorkspacesAndAssociatedPermissions, userPermissionCheckboxManage })(UserPermissions);
