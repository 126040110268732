export const questionTypes = [
  { key: "Short answer", value: "input" },
  { key: "Paragraph", value: "textarea" },
  { key: "Rich text", value: "rich_text" },
  { key: "Signature/Initials", value: "signature" },
  { key: "Multiple choice", value: "radio" },
  { key: "Checkboxes", value: "checkbox" },
  { key: "Dropdown", value: "select" },
  { key: "File upload", value: "file" },
  { key: "Linear scale", value: "linear_scale" },
  { key: "Multiple-choice grid", value: "radio_grid" },
  { key: "Tick box grid", value: "checkbox_grid" },
  { key: "Date", value: "date" },
  { key: "Time", value: "time" },
];

export const fileTypes = [
  { key: "txt", value: "Text" },
  { key: "document", value: "Document" },
  { key: "spreadsheet", value: "Spreadsheet" },
  { key: "image", value: "Image" },
  { key: "audio", value: "Audio" },
  { key: "video", value: "Video" },
  { key: "compressed", value: "Compressed" },
  { key: "presentation", value: "Presentation" },
];

export const fileSizes = [
  { key: "1MB", value: 1 },
  { key: "5MB", value: 5 },
  { key: "10MB", value: 10 },
  { key: "20MB", value: 20 },
];

export const linearScaleFrom = [
  { key: 0, value: 0 },
  { key: 1, value: 1 },
];

export const linearScaleTo = [
  { key: 2, value: 2 },
  { key: 3, value: 3 },
  { key: 4, value: 4 },
  { key: 5, value: 5 },
  { key: 6, value: 6 },
  { key: 7, value: 7 },
  { key: 8, value: 8 },
  { key: 9, value: 9 },
  { key: 10, value: 10 },
];
