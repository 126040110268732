import { CloudArrowDownIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import { useState, useRef } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { uploadUsersByCSV } from "src/actions/user";
import Button from "src/components/Shared/Buttons/Button";
import { convertToBase64 } from "src/helpers/image";

const BulkUpload = ({ users, type, limit, status, setStatus, group, setGroup, keyword, setKeyword, offset, groups, site = {}, ...props }) => {
  const inputFileRef = useRef(null);

  const [disable, setDisable] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const handleFileChange = async (e) => {
    if (e.target.files[0]?.name) {
      setFileName(e.target.files[0]?.name);
      convertToBase64(e.target.files[0], setFile);
      setDisable(false);

      inputFileRef.current.value = null;
    }
  };

  const onSubmit = async () => {
    try {
      setDisable(true);
      const message = await props.uploadUsersByCSV({ file, sendWelcomeEmail: site?.bulk_upload_send_welcome_email });
      setFileName(null);
      toast.success(message);
      // return navigate("/users");
    } catch (error) {
      setDisable(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="grid w-full gap-5">
        <div className="relative">
          <div className="relative flex h-[252px] max-w-4xl flex-col items-center justify-center overflow-hidden rounded-3xl border-2 border-dashed border-gray-300 p-5 transition-all duration-150">
            <input
              ref={inputFileRef}
              type="file"
              onChange={handleFileChange}
              accept=".csv"
              className="absolute left-0 top-0 z-10 h-full w-full cursor-pointer opacity-0"
            />
            {fileName ? (
              <div className="flex cursor-pointer border-leftNavColor font-medium text-gray-400 focus:outline-none sm:w-auto">
                <div className="flex items-center gap-x-2">
                  <DocumentTextIcon className="h-6 w-6" /> {fileName ? fileName : "No file chosen."}
                </div>
              </div>
            ) : (
              <div className="relative flex h-full w-full flex-col items-center justify-center p-5">
                <div className="flex flex-col items-center justify-center space-y-4">
                  <CloudArrowDownIcon className="h-12 w-12 text-gray-400" />
                  <div className="font-semibold text-gray-400">Drag & Drop file here</div>
                  <div className="!my-8 flex h-[1px] w-full items-center justify-center bg-gray-200">
                    <span className="flex h-8 w-8 items-center justify-center rounded-full border border-gray-900/10 bg-gray-50 text-xs font-semibold uppercase text-gray-700">or</span>
                  </div>
                </div>
                <label htmlFor="bulk_upload-input">
                  <Button
                    version="secondary"
                    onClick={() => {}}>
                    Select file
                  </Button>
                </label>
                <input
                  type="file"
                  onChange={(e) => {
                    handleFileChange(e);
                    e.target.value = null;
                  }}
                  id="bulk_upload-input"
                  name="bulk_upload-input"
                  accept=".csv"
                  className="hidden"
                />
              </div>
            )}
          </div>
        </div>
        {fileName && (
          <div className="relative flex flex-col items-start justify-end">
            <div className="flex w-full justify-end">
              <Button
                disabled={disable || fileName === ""}
                onClick={onSubmit}>
                Upload file
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    groups: Object.values(state.groups),
  };
};

export default connect(mapStateToProps, { uploadUsersByCSV })(BulkUpload);
