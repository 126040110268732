import { Cog6ToothIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Equals from "src/assets/svgs/operators/equals.svg";
import GreaterThanOrEqual from "src/assets/svgs/operators/greater-than-equal.svg";
import GreaterThan from "src/assets/svgs/operators/greater-than.svg";
import LessThanOrEqual from "src/assets/svgs/operators/less-than-equal.svg";
import LessThan from "src/assets/svgs/operators/less-than.svg";
import NotEqual from "src/assets/svgs/operators/not-equal.svg";
import AddWhereContent from "src/components/PageForm/AddWhereContent";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Select from "src/components/Shared/Forms/Selects/Select";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import QuickMenu from "src/components/Shared/QuickMenu";
import PillEntry from "src/components/Workspaces/PillEntry";
import { classNames } from "src/helpers/classNames";
import { operatorNameConverter } from "src/helpers/operators/operatorNameConverter";
import useColumnNamesByPage from "src/hooks/useColumnNamesByPage";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { validate as uuidValidate } from "uuid";

let conversionTableSVG = {
  EQUALS: Equals,
  NOT_EQUALS: NotEqual,
  GREATER_THAN: GreaterThan,
  GREATER_THAN_EQUALS_TO: GreaterThanOrEqual,
  LESS_THAN: LessThan,
  LESS_THAN_EQUALS_TO: LessThanOrEqual,
};

let conversionTableText = {
  IN: "IN",
  NOT_IN: "NOT IN",
  CONTAINS: "CONTAINS",
};

const FilterItem = ({
  state,
  setState = () => {},
  operatorList,
  operatorId,
  trustedAttributeArr,
  trustedAttributeId,
  setTrustedAttributeId = () => {},
  viewOnly = false,
  authorizeUserComponentAccessPermission = true,
  showButtonVisibility = () => {},
  items = [],
  columnLoading = false,
  columns = [],
  from,
}) => {
  return (
    <div className={classNames("relative flex w-full flex-col items-center gap-2 sm:flex-row md:gap-1 xl:gap-3", viewOnly ? "pr-0" : from === "settings" || from === "group" ? "pr-10 sm:pr-0" : "")}>
      <div className="relative w-full flex-shrink flex-grow">
        {columnLoading ? (
          <div className="relative rounded-md border border-gray-300 bg-gray-50 px-0 pt-2 shadow-sm  focus-within:border-highlightColor">
            <div className="m-0 pl-3 text-[10px] font-semibold uppercase leading-none text-gray-400">Table Column</div>
            <div className="flex h-8 flex-shrink flex-grow items-center">
              <Preloader
                size="xs"
                circleDimension={"4"}
                className={"!mx-0 !justify-start !gap-x-2 !px-3 !py-0"}
              />
            </div>
          </div>
        ) : columns?.length > 0 ? (
          <Select
            label="Table column"
            inline={true}
            options={columns?.map((column) => ({ _id: column?.name, name: column?.name, object: column }))}
            selected={columns?.map((column) => ({ _id: column?.name, name: column?.name })).find((item) => item?.name === state?.column_name) || { _id: -1, name: "" }}
            disabled={viewOnly}
            dropdownClasses={"!max-h-48"}
            setSelected={(e) => {
              setState({ ...state, column_name: e._id });
              showButtonVisibility({ ...state, column_name: e._id });
            }}
            searchableFields={["name"]}
          />
        ) : (
          <Input
            name="column-name"
            label="Table column"
            inline={true}
            disabled={viewOnly}
            value={state?.column_name || ""}
            onChange={(e) => {
              setState({ ...state, column_name: e.target.value });
              showButtonVisibility({ ...state, column_name: e.target.value });
            }}
          />
        )}
      </div>
      <div className="w-full flex-shrink-0 md:w-[120px]">
        <Select
          label="Condition"
          inline={true}
          options={operatorList}
          selected={operatorId}
          disabled={viewOnly}
          setSelected={(e) => {
            setState({ ...state, operator_id: e._id });
            showButtonVisibility({ ...state, operator_id: e._id });
          }}
        />
      </div>
      <div className="relative w-full flex-shrink flex-grow">
        {state?.value_type === "attribute" ? (
          <Select
            label="Criteria"
            inline={true}
            options={trustedAttributeArr}
            selected={trustedAttributeId}
            disabled={viewOnly}
            setSelected={(e) => {
              setState({ ...state, trusted_attribute: e._id });
              showButtonVisibility({ ...state, trusted_attribute: e._id });
            }}
          />
        ) : (
          <Input
            label="Criteria"
            inline={true}
            name="column-value"
            disabled={viewOnly}
            value={state?.column_value || ""}
            onChange={(e) => {
              setState({ ...state, column_value: e.target.value });
              showButtonVisibility({ ...state, column_value: e.target.value });
            }}
          />
        )}
        <div className="absolute right-3 top-px 2xl:top-1">
          <span className="flex items-center justify-center space-x-1 text-[10px] font-normal text-gray-300 2xl:text-xs">{state?.value_type === "value" ? "Text Value" : "User Attributes"}</span>
        </div>
      </div>
      {items?.length > 0 && (
        <div className={classNames("absolute right-0 top-0 flex-shrink-0 md:relative md:right-auto md:top-auto")}>
          <QuickMenu
            icon={
              <div className="flex h-8 w-8 items-center  justify-center rounded-md text-gray-400 hover:bg-gray-50 hover:text-gray-500 2xl:h-10 2xl:w-10">
                <Cog6ToothIcon className="h-6 w-6" />
              </div>
            }
            position="LEFT"
            items={items}
          />
        </div>
      )}
    </div>
  );
};

const DataAccessAndWorkspaceFilterSaveButton = ({ state, setShowSaveButton = () => {}, setState = () => {}, manageFilter = () => {} }) => {
  return (
    <Button
      type="button"
      version="default"
      disabled={state?.saveDisabled}
      className={classNames("flex !h-8 flex-shrink-0 flex-grow items-center justify-center rounded-md bg-highlightColor !px-3 text-white outline-none ring-0 transition-all duration-150 2xl:!h-10", state?.saveDisabled ? "opacity-50" : "")}
      onClick={() => {
        setShowSaveButton(false);
        setState({ ...state, saveDisabled: true });
        manageFilter(state);
      }}>
      Save
    </Button>
  );
};

const FilterActions = ({ from, state, removeFilter = () => {}, setState = () => {} }) => {
  return (
    <Button
      type="button"
      version="default"
      disabled={state?.saveDisabled}
      className={classNames("flex aspect-square !h-8 w-8 flex-shrink-0 flex-grow items-center justify-center rounded-md text-gray-400 outline-none ring-0 transition-all duration-150 hover:bg-gray-50 hover:text-gray-500 2xl:w-10", state?.saveDisabled ? "opacity-50" : "")}
      onClick={removeFilter}>
      <span className="sr-only">Delete</span>
      <TrashIcon className="h-5 w-5" />
    </Button>
  );
};

const FilterAddWhereDataset = ({ state, viewOnly = false, setState = () => {}, showButtonVisibility = () => {}, authorizeUserComponentAccessPermission = false, width }) => {
  return (
    <div className="relative flex items-center gap-1 pt-3 xl:pl-5">
      <div className="relative flex flex-shrink flex-grow flex-col flex-wrap items-start gap-1 whitespace-nowrap pb-2">
        <div className="text-sm text-gray-400">Filters apply only to the following datasets:</div>
        <div className="group relative flex flex-shrink-0 flex-wrap gap-1">
          {state?.datasource_id?.split(",")?.map((item, index) => (
            <PillEntry
              index={index}
              tag={item}
              onDelete={() => {
                setState({
                  ...state,
                  datasource_id: state?.datasource_id
                    ?.split(",")
                    .filter((_, i) => i !== index)
                    ?.toString(),
                });
                showButtonVisibility({
                  ...state,
                  datasource_id: state?.datasource_id
                    ?.split(",")
                    .filter((_, i) => i !== index)
                    ?.toString(),
                });
              }}
              deleteEnabled={!viewOnly && authorizeUserComponentAccessPermission}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const WorkspaceIntegrationFiltersItem = ({
  index,
  page_id = null,
  filters = [],
  state,
  setState = () => {},
  removeFilter = () => {},
  manageFilter = () => {},
  operators,
  trustedAttributeArr,
  from = null,
  viewOnly = false,
  authorizeUserComponentAccessPermission = true,
  isAddWhere = true,
}) => {
  const [operatorId, setOperatorId] = useState({ _id: -1, name: "" });
  const [trustedAttributeId, setTrustedAttributeId] = useState({ _id: -1, name: "" });
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [operatorList, setOperatorList] = useState([]);
  const [items, setItems] = useState([]);
  const { width } = useWindowDimensions();

  const { loading: columnLoading, columns } = useColumnNamesByPage(page_id);

  const showButtonVisibility = (state) => {
    if (state?.column_name && state?.operator_id && ((state?.value_type === "value" && state?.column_value) || (state?.value_type === "attribute" && state?.trusted_attribute))) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  };

  useEffect(() => {
    setOperatorList(
      operators.map((operatorOption) => ({
        _id: operatorOption._id,
        code: operatorOption.code,
        fullName: operatorOption?.name?.replaceAll("_", " "),
        name: operatorNameConverter(operatorOption.name),
        value: (
          <div className="">
            {conversionTableSVG[operatorOption.name] ? (
              <img
                className="m-0 h-5 w-auto p-0"
                src={conversionTableSVG[operatorOption.name]}
                alt="operator"
              />
            ) : (
              <p className="min-w-40 text-lg font-semibold subpixel-antialiased">{conversionTableText[operatorOption.name]}</p>
            )}
          </div>
        ),
      })),
    );
  }, [operators]);

  useEffect(() => {
    const index = operatorList.findIndex((item) => item._id === state?.operator_id);
    if (index !== -1) {
      setOperatorId(operatorList[index]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.operator_id, operatorList?.length]);

  useEffect(() => {
    const index = trustedAttributeArr.findIndex((item) => item._id === state?.trusted_attribute);
    if (index !== -1) {
      setTrustedAttributeId(trustedAttributeArr[index]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.trusted_attribute, trustedAttributeArr]);

  const [lineHeightMeasured, setLineHeightMeasured] = useState("");
  const [bottomMeasured, setBottomMeasured] = useState("");

  useEffect(() => {
    if (index === 0 && filters?.length === 1) {
      width > 1280 || width < 990 ? setLineHeightMeasured("calc(50% + 1rem)") : setLineHeightMeasured("calc(50% + 0.5rem)");
      setBottomMeasured("50%");
    } else if (index === 0 && filters?.length > 1) {
      width > 1280 || width < 990 ? setLineHeightMeasured("calc(100% + 1rem)") : setLineHeightMeasured("calc(100% + 0.5rem)");
      setBottomMeasured("0%");
    } else if (index + 1 === filters?.length && filters?.length !== 1) {
      width > 1280 || width < 990 ? setLineHeightMeasured("calc(100% + 1rem)") : setLineHeightMeasured("calc(100% + 0.5rem)");
      setBottomMeasured("50%");
    } else {
      width > 1280 || width < 990 ? setLineHeightMeasured("calc(100% + 1rem)") : setLineHeightMeasured("calc(100% + 0.5rem)");
      setBottomMeasured("0%");
    }
  }, [index, filters?.length, width, lineHeightMeasured, bottomMeasured]);

  useEffect(() => {
    let item = [];
    if (!viewOnly && authorizeUserComponentAccessPermission) {
      if (isAddWhere)
        item.push({
          name: "Assign datasets",
          component: (
            <AddWhereContent
              data={{ datasource_id: state?.datasource_id }}
              onClick={(data) => {
                setState({ ...state, ...data });
                showButtonVisibility({ ...state, ...data });
              }}
            />
          ),
        });
      item.push({
        _id: 2,
        name: `Switch to ${state?.value_type === "value" ? "User Attributes" : "Text (Default)"}`,
        onClick: () => {
          setState({ ...state, value_type: state?.value_type === "value" ? "attribute" : "value", column_value: "", trusted_attribute: "" });
          setTrustedAttributeId({ _id: -1, name: "" });
          showButtonVisibility({ ...state, value_type: state?.value_type === "value" ? "attribute" : "value", column_value: "", trusted_attribute: "" });
        },
      });
    }
    setItems(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewOnly, authorizeUserComponentAccessPermission, isAddWhere, state, filters]);

  const beforeStyle = {
    position: "absolute",
    top: "50%",
    left: -2,
    width: width > 1540 ? 24 : width > 1280 ? 17 : width > 990 ? 10 : 17,
    transform: "translateY(-50%)",
    borderTopWidth: "2px",
    borderTopColor: "#E5E7EB",
    borderTopStyle: "solid",
  };
  const afterStyle = {
    position: "absolute",
    bottom: bottomMeasured,
    left: -2,
    height: lineHeightMeasured,
    borderLeftWidth: "2px",
    borderLeftColor: "#E5E7EB",
    borderLeftStyle: "solid",
  };

  return (
    <div className={classNames("relative", from === "group" ? "pl-4 lg:pl-2 xl:pl-4 2xl:pl-6" : "")}>
      {from === "group" && (
        <>
          <div style={beforeStyle}></div>
          <div style={afterStyle}></div>
        </>
      )}
      <div className={classNames("relative w-full rounded-md border border-gray-200 px-2 py-2 xl:px-4 xl:py-3")}>
        <div className="w-full">
          <div className="relative flex items-start space-x-1 md:items-center">
            <div className={"flex w-full items-center"}>
              <FilterItem
                state={state}
                setState={setState}
                operatorList={operatorList}
                operatorId={operatorId}
                trustedAttributeArr={trustedAttributeArr}
                trustedAttributeId={trustedAttributeId}
                setTrustedAttributeId={setTrustedAttributeId}
                viewOnly={viewOnly}
                authorizeUserComponentAccessPermission={authorizeUserComponentAccessPermission}
                isAddWhere={isAddWhere}
                showButtonVisibility={showButtonVisibility}
                items={items}
                columnLoading={columnLoading}
                columns={columns}
                from={from}
              />
            </div>
            <div className="absolute right-0 top-10 flex flex-shrink-0 flex-col items-center justify-center sm:relative sm:right-auto sm:top-auto sm:flex-row">
              {!viewOnly && authorizeUserComponentAccessPermission && (!uuidValidate(state._id) || from === "group") && (
                <FilterActions
                  state={state}
                  setState={setState}
                  from={from}
                  removeFilter={removeFilter}
                />
              )}
              {!viewOnly && authorizeUserComponentAccessPermission && showSaveButton && from === "settings" && (
                <DataAccessAndWorkspaceFilterSaveButton
                  state={state}
                  setShowSaveButton={setShowSaveButton}
                  setState={setState}
                  manageFilter={manageFilter}
                />
              )}
            </div>
          </div>

          {state?.datasource_id && (
            <FilterAddWhereDataset
              width={width}
              state={state}
              setState={setState}
              viewOnly={viewOnly}
              showButtonVisibility={showButtonVisibility}
              authorizeUserComponentAccessPermission={authorizeUserComponentAccessPermission}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkspaceIntegrationFiltersItem;
