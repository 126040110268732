import { Bars3Icon } from "@heroicons/react/24/outline";
import React from "react";
import Input from "src/components/Shared/Forms/Inputs/Input";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";
import FormContentAction from "src/components/Forms/FormActions/FormContentAction";
import Button from "src/components/Shared/Buttons/Button";
import { classNames } from "src/helpers/classNames";

const FormTextContent = ({ section = null, content = null, draggableProps, dragHandleProps, innerRef, setFormData = () => {}, from = "form" }) => {
  const onHandleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      sections: [
        ...prevData?.sections?.map((prevSection) => {
          if (prevSection?.id === section?.id) {
            return {
              ...prevSection,
              contents: [
                ...prevSection?.contents?.map((prevContent) => {
                  if (prevContent?.id === content?.id) {
                    return {
                      ...prevContent,
                      [field]: value,
                    };
                  } else {
                    return prevContent;
                  }
                }),
              ],
            };
          } else {
            return prevSection;
          }
        }),
      ],
    }));
  };
  return (
    <div
      className="relative w-full divide-y divide-gray-100 rounded-md border-l-4 border-highlightColor bg-white shadow"
      ref={innerRef}
      {...draggableProps}>
      {from !== "response" && (
        <Button
          version="default"
          className="flex !h-6 w-full !cursor-move items-center justify-center transition-all duration-300 hover:bg-gray-100"
          dragHandleProps={dragHandleProps}>
          <Bars3Icon className="h-6 w-full text-gray-300" />
        </Button>
      )}

      <div className={classNames("grid grid-cols-3 px-6 py-6", from !== "response" ? "gap-6" : "gap-1")}>
        <div className="col-span-3">
          <div className="relative">
            {from === "response" ? (
              <div className="text-2xl font-bold text-gray-600">{content?.name}</div>
            ) : (
              <Input
                inline={true}
                label={"Title"}
                wrapperClass={"!bg-white"}
                value={content?.name}
                onChange={(e) => onHandleChange("name", e.target.value)}
              />
            )}
          </div>
        </div>
        <div className="col-span-3">
          <div className="relative">
            {from === "response" ? (
              <div className="font-medium text-gray-500">{content?.description}</div>
            ) : (
              <TextArea
                inline={true}
                wrapperClass={"!bg-white"}
                label={"Description"}
                value={content?.description}
                onChange={(e) => onHandleChange("description", e.target.value)}
              />
            )}
          </div>
        </div>
        {from !== "response" && (
          <FormContentAction
            section={section}
            content={content}
            setFormData={setFormData}
          />
        )}
      </div>
    </div>
  );
};

export default FormTextContent;
