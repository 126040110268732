import { connect } from "react-redux";
import Image from "src/components/Shared/Image";
import { classNames } from "src/helpers/classNames";

const UserPermissionHeader = ({ item, activeWorkspace = {}, setActiveWorkspace = () => {}, permissionChanges, setWarningModalIsOpen = () => {} }) => {
  return (
    <div className={classNames("relative flex h-16 w-full rounded-md bg-white shadow", item._id === activeWorkspace?._id ? "" : "")}>
      <div
        onClick={() => (permissionChanges > 0 ? setWarningModalIsOpen(item) : setActiveWorkspace(item))}
        className={classNames("relative flex w-full max-w-full cursor-pointer items-center justify-between gap-10 rounded-md border sm:border-2 bg-white px-6 transition-all", item?._id === activeWorkspace?._id ? "sm:border-highlightColor" : "border-gray-100 ")}>
        <div className="relative flex w-full items-center gap-x-2">
          <div className="flex h-12 w-12 items-center justify-center overflow-hidden rounded-full">
            <Image
              image={item?.workspace_id?.square_logo || item?.workspace_id?.image_favicon || item?.workspace_id?.image_logo}
              alt={"User permission tab data item"}
            />
          </div>
          <p className="max-w-[150px] truncate font-semibold text-gray-700">{item?.workspace_id?.name}</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(UserPermissionHeader);
