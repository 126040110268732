import React from "react";
import { classNames } from "src/helpers/classNames";

const WorkspaceSSOHeader = ({ item, providerType = {}, activeItem = {}, setActiveItem = () => { }, itemModifiedCount = 0, setListToggle, setWarningModalIsOpen = () => {} }) => {
  return (
    <div className={classNames("relative flex min-h-16 w-full rounded-md bg-white shadow", item._id === activeItem?._id ? "" : "")}>
      <div
        onClick={itemModifiedCount > 0 ? () => { setWarningModalIsOpen(item); setListToggle(true) } : () => {setActiveItem(item);setListToggle(true)}}
        className={classNames("relative flex w-full max-w-full cursor-pointer items-center justify-between rounded-md border sm:border-2 bg-white px-3 py-2 transition-all", item?._id === activeItem?._id ? "sm:border-highlightColor" : "border-gray-100 ")}>
        <div className="relative flex w-full items-center gap-x-2">
          <div className="w-full space-y-1">
            <div className="flex items-center justify-between space-x-3">
              <p className="truncate font-semibold text-gray-700">{item?.provider_name}</p>
              <p className="flex-shrink-0 truncate text-xs font-semibold text-gray-600">
                <span className="inline-block rounded-full  px-2 py-1 leading-none bg-gray-200 text-gray-500">{providerType[item?.provider_type]}</span>
              </p>
            </div>
            <div className="flex items-center justify-between space-x-3">
              <p className="truncate text-xs text-gray-600">{item?.provider_type !== "auth0" ? item?.identity_url : item?.domain}</p>
              <p className="flex-shrink-0 truncate text-xs">
                <span className={classNames("inline-block rounded-full  px-2 py-1 leading-none ", item?.default ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600")}>{item?.default ? "Active" : "Inactive"}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceSSOHeader;
