import React from "react";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Select from "src/components/Shared/Forms/Selects/Select";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";

const GeneralForm = ({ formData = null, setFormData = () => {}, setItemModifiedCount = () => {} }) => {
  const certificateText = "-----BEGIN CERTIFICATE----- \n (certificate goes here) \n -----END CERTIFICATE-----";
  return (
    <>
      <div className="col-span-4 sm:col-span-4 lg:col-span-3">
        <Input
          inline={true}
          name="identity-url"
          label="IDP Entity ID or Issuer"
          value={formData?.identity_url || ""}
          onChange={(e) => {
            setFormData((prevData) => ({ ...prevData, identity_url: e.target.value }));
            setItemModifiedCount((prevData) => prevData + 1);
          }}
        />
        <div className="mt-1 text-xs text-gray-400">*You can find the EntityID in Your IdP-Metadata XML file enclosed in EntityDescriptor tag having attribute as entityID</div>
      </div>
      <div className="col-span-4 sm:col-span-4 lg:col-span-3">
        <Input
          inline={true}
          name="login-url"
          label="SAML Login URL"
          value={formData?.login_url || ""}
          onChange={(e) => {
            setFormData((prevData) => ({ ...prevData, login_url: e.target.value }));
            setItemModifiedCount((prevData) => prevData + 1);
          }}
        />
        <div className="mt-1 text-xs text-gray-400">*You can find the SAML Login URL in Your IDP-Metadata XML file enclosed in SingleSignOnService tag (Binding type: HTTP-Redirect)</div>
      </div>
      <div className="col-span-4 sm:col-span-4 lg:col-span-3">
        <Input
          inline={true}
          name="logout-url"
          label="SAML Logout URL"
          value={formData?.logout_url || ""}
          onChange={(e) => {
            setFormData((prevData) => ({ ...prevData, logout_url: e.target.value }));
            setItemModifiedCount((prevData) => prevData + 1);
          }}
        />
        <div className="mt-1 text-xs text-gray-400">*You can find the SAML Logout URL in Your IDP-Metadata XML file enclosed in SingleSignOnService tag (Binding type: HTTP-Redirect)</div>
      </div>
      <div className="col-span-4 sm:col-span-4 lg:col-span-4">
        <TextArea
          inline={true}
          inputClassNames="w-full h-40 text-sm rounded border-gray-300 border outline-none no-shadow py-2 px-3 focus:border-highlightColor"
          label={"X.509 Certificate"}
          placeholder={certificateText}
          value={formData?.certificate || ""}
          onChange={(e) => {
            setFormData((prevData) => ({ ...prevData, certificate: e.target.value }));
            setItemModifiedCount((prevData) => prevData + 1);
          }}
        />
      </div>
      <div className="col-span-4 sm:col-span-4 lg:col-span-4">
        <div className="flex rounded bg-gray-100 p-4">
          <input
            id="comments"
            name="comments"
            type="checkbox"
            className="text-color-06 mt-1 h-5 w-5 cursor-pointer rounded border-gray-300 focus:border-highlightColor focus:ring-0"
            defaultChecked={true}
            disabled={true}
          />
          <div className="ml-3">
            <div className="text-sm font-medium text-gray-800">Character Encoding</div>
            <div className="text-xs text-gray-400">Uses iconv encoding to convert X509 certificate into correct encoding.</div>
          </div>
        </div>
      </div>
    </>
  );
};

const Auth0Form = ({ formData = null, setFormData = () => {}, setItemModifiedCount = () => {} }) => {
  return (
    <>
      <div className="col-span-4 sm:col-span-4 lg:col-span-3">
        <Input
          inline={true}
          name="domain"
          label="Domain"
          value={formData?.domain || ""}
          onChange={(e) => {
            setFormData((prevData) => ({ ...prevData, domain: e.target.value }));
            setItemModifiedCount((prevData) => prevData + 1);
          }}
        />
        <div className="mt-1 text-xs text-gray-400">*You can find the Domain in Your application settings as entityID</div>
      </div>
      <div className="col-span-4 sm:col-span-4 lg:col-span-3">
        <Input
          inline={true}
          type={"password"}
          autoComplete="new-password"
          name="client-id"
          label="Client ID"
          value={formData?.client_id}
          placeholder={formData?.client_id_placeholder}
          onChange={(e) => {
            setFormData((prevData) => ({ ...prevData, client_id: e.target.value }));
            setItemModifiedCount((prevData) => prevData + 1);
          }}
        />
        <div className="mt-1 text-xs text-gray-400">*You can find the Client ID in Your application settings as Client ID</div>
      </div>
      <div className="col-span-4 sm:col-span-4 lg:col-span-3">
        <Input
          inline={true}
          type={"password"}
          autoComplete="new-password"
          name="client-secret"
          label="Client Secret"
          value={formData?.client_secret}
          placeholder={formData?.client_secret_placeholder}
          onChange={(e) => {
            setFormData((prevData) => ({ ...prevData, client_secret: e.target.value }));
            setItemModifiedCount((prevData) => prevData + 1);
          }}
        />
        <div className="mt-1 text-xs text-gray-400">*You can find the Client Secret in Your application settings as Client Secret</div>
      </div>
    </>
  );
};

const SSOManualForm = ({ formData = null, setFormData = () => {}, setItemModifiedCount = () => {} }) => {
  return (
    <div className="relative">
      <div className="w-ful max-w-4xl">
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-4 sm:col-span-4 lg:col-span-3">
            <Select
              inline={true}
              label="Identity Provider type"
              options={[
                { _id: "general", name: "General" },
                { _id: "auth0", name: "Auth0" },
              ]}
              selected={{
                _id: formData?.provider_type,
                name: formData?.provider_type === "auth0" ? "Auth0" : "General",
              }}
              setSelected={(val) => {
                setFormData((prevData) => ({
                  ...prevData,
                  provider_type: val?._id,
                  domain: "",
                  client_id: "",
                  client_secret: "",
                  identity_url: "",
                  login_url: "",
                  logout_url: "",
                  certificate: "",
                }));
                setItemModifiedCount((prevData) => prevData + 1);
              }}
            />
          </div>
          <div className="col-span-4 sm:col-span-4 lg:col-span-3">
            <Input
              name="provider-name"
              inline={true}
              label="Identity Provider Name"
              value={formData?.provider_name || ""}
              onChange={(e) => {
                setFormData((prevData) => ({ ...prevData, provider_name: e.target.value }));
                setItemModifiedCount((prevData) => prevData + 1);
              }}
            />
          </div>
          {formData?.provider_type === "auth0" ? (
            <Auth0Form
              formData={formData}
              setFormData={setFormData}
              setItemModifiedCount={setItemModifiedCount}
            />
          ) : (
            <GeneralForm
              formData={formData}
              setFormData={setFormData}
              setItemModifiedCount={setItemModifiedCount}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SSOManualForm;
