import { classNames } from "src/helpers/classNames";

const Section = ({ children, className = "", shadow = false }) => {
  return (
    <div className="grid w-full">
      <div className={classNames("bg-white px-2 sm:px-4 py-4 sm:pb-10 rounded-md flex flex-col w-full", shadow ? "shadow-lg border-[1px] border-gray-50" : "", className)}>{children}</div>
    </div>
  );
};

export default Section;
