import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import Button from "src/components/Shared/Buttons/Button";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import Modal from "src/components/Shared/Modal";

const ManageWorkspace2FAModal = ({ workspaces = [], isOpen, onCancel = () => {}, onSuccess = () => {}, manageSiteSettings, site, two_factor_enabled = false, two_factor_timeframe = "" }) => {
  const [twoFactorEnabledAdmin, setTwoFactorEnabledAdmin] = useState(false);
  const [twoFactorEnabledWorkspaces, setTwoFactorEnabledWorkspaces] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSelectedWorkspace = (workspacesArray) => {
    let updatedSelectedWorkspaces = workspacesArray.reduce((workspaces, workspace) => (workspace.selected ? [...workspaces, workspace.key] : workspaces), []);
    setTwoFactorEnabledWorkspaces(updatedSelectedWorkspaces);
  };

  const onSubmit = async () => {
    try {
      setIsDisabled(true);
      const message = await manageSiteSettings({
        two_factor_enabled_admin: twoFactorEnabledAdmin,
        two_factor_enabled_workspaces: twoFactorEnabledWorkspaces,
        two_factor_enabled,
        two_factor_timeframe,
      });
      onSuccess();
      toast.success(message);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    if (site?._id) {
      setTwoFactorEnabledAdmin(site.two_factor_enabled_admin || false);
      setTwoFactorEnabledWorkspaces(site.two_factor_enabled_workspaces || []);
    }
  }, [site]);

  return (
    <>
      <Modal
        title="Select Workspaces"
        isOpen={isOpen}
        onCancel={onCancel}
        onSuccess={onSubmit}
        isLoading={isDisabled}>
        <div className="relative mb-5 flex w-full flex-col gap-y-5">
          <div className="w-full items-center space-y-6">
            <div className="w-full">
              <Checkbox
                checkboxLabel={"Enforce 2FA for admin portal"}
                checkboxLableClass={"font-semibold"}
                checkboxName="set_default"
                onChange={(e) => setTwoFactorEnabledAdmin(e.target.checked)}
                isChecked={twoFactorEnabledAdmin}
              />
            </div>
            <div className="space-y-1">
              <div className="w-full">
                <Button
                  version="default"
                  onClick={() => {
                    setTwoFactorEnabledWorkspaces(workspaces?.map((workspace) => workspace?._id));
                  }}
                  className="py-2 text-xs font-semibold text-gray-400">
                  + Select all workspaces
                </Button>
              </div>
              <div className="w-full">
                <MultiSelectObject
                  defaultOptions={workspaces?.map((workspace) => {
                    return { key: workspace._id, value: workspace.name, selected: twoFactorEnabledWorkspaces.includes(workspace?._id), object: workspace };
                  })}
                  strictOnChangeMode={false}
                  searchableFields={["name"]}
                  title="Assign workspace"
                  onChange={handleSelectedWorkspace}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(ManageWorkspace2FAModal);
