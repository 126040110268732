import { Bars3Icon, ClipboardDocumentListIcon, SquaresPlusIcon, UserIcon, WindowIcon } from "@heroicons/react/24/outline";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Button from "src/components/Shared/Buttons/Button";
import Image from "src/components/Shared/Image";
import TRBody from "src/components/Shared/Table/TRBody";
import TableRow from "src/components/Shared/Table/TableRow";
import WorkspaceSubscriberInstance from "src/components/Workspaces/WorkspaceSubscriberInstance";
import { noFavicon } from "src/config/host";
import { classNames } from "src/helpers/classNames";

const WorkspaceEntry = ({ item, isDragging = { keyId: "", value: false }, provided }) => {
  let pagesCount = (
    <Link to={`/workspaces/${item?._id}?tab=Pages`}>
      <div className={classNames("flex w-12 cursor-pointer gap-x-[2px] font-medium transition-all duration-100 hover:underline md:gap-x-1", (item?.workspace_type === "JWT_FULL_EMBED" ? item?.domo_data?.page > 0 : item?.pages?.length > 0) ? "text-gray-900" : "text-gray-300")}>
        <WindowIcon className="h-5 w-5" />
        {item?.workspace_type !== "JWT_FULL_EMBED" ? item.pages?.length || 0 : item.workspace_type && item?.workspace_type === "JWT_FULL_EMBED" ? item?.domo_data?.page || 0 : 0}
      </div>
    </Link>
  );

  let formsCount =
    item?.workspace_type !== "JWT_FULL_EMBED" ? (
      <Link to={`/workspaces/${item?._id}?tab=Forms`}>
        <div className={classNames("flex w-12 cursor-pointer gap-x-[2px] font-medium transition-all duration-100 hover:underline md:gap-x-1", item?.forms?.length > 0 ? "text-gray-900" : "text-gray-300")}>
          <ClipboardDocumentListIcon className="h-5 w-5" />
          {item.forms?.length || 0}
        </div>
      </Link>
    ) : (
      <div className={classNames("flex w-12 gap-x-1 font-medium text-gray-300 transition-all duration-100 ")}>N/A</div>
    );

  let groupsCount = (
    <Link to={`/workspaces/${item?._id}?tab=Groups`}>
      <div className={classNames("flex w-12 cursor-pointer gap-x-[2px] font-medium transition-all duration-100 hover:underline md:gap-x-1", (item?.workspace_type === "JWT_FULL_EMBED" ? item?.domo_data?.group > 0 : item?.groups?.length > 0) ? "text-gray-900" : "text-gray-300")}>
        <SquaresPlusIcon className="h-5 w-5" />
        {item?.workspace_type !== "JWT_FULL_EMBED" ? item.groups?.length || 0 : item.workspace_type && item?.workspace_type === "JWT_FULL_EMBED" ? item?.domo_data?.group || 0 : 0}
      </div>
    </Link>
  );

  let usersCount = (
    <Link to={`/workspaces/${item?._id}?tab=Users`}>
      <div className={classNames("flex w-12 cursor-pointer gap-x-[2px] font-medium transition-all duration-100 hover:underline md:gap-x-1", item?.userIds?.length > 0 ? "text-gray-900" : "text-gray-300")}>
        <UserIcon className="h-5 w-5" />
        {item?.userIds?.length || 0}
      </div>
    </Link>
  );

  return (
    <TableRow
      keyId={item?._id}
      provided={provided}
      isDragging={isDragging}
      classes={isDragging.value && isDragging.keyId === item?._id ? "!inline-table bg-white/80 !border-none" : ""}>
      <TRBody className="w-[3%] whitespace-nowrap !px-0">
        <Button
          dragHandleProps={provided?.dragHandleProps}
          type="button"
          version="default"
          className="mx-auto ml-3 flex w-8 cursor-move items-center justify-center bg-transparent text-gray-500 outline-none ring-0 sm:min-w-[32px]">
          <Bars3Icon className="h-6 w-6" />
        </Button>
      </TRBody>
      <TRBody className="w-[97%] whitespace-nowrap py-2.5 pl-1 pr-1 text-sm sm:w-[52%] sm:py-1.5 sm:pl-3 sm:pr-3">
        <div className="relative flex w-full items-center gap-x-3">
          <Link
            to={`/workspaces/${item._id}`}
            className="group w-full cursor-pointer rounded-md border border-transparent pr-5 font-medium text-gray-900 transition-all duration-200">
            <div className="flex w-full items-center gap-5 sm:gap-4">
              <div
                style={!item?.square_logo && item.image_logo ? { background: item.top_bar_color } : {}}
                className="mx-6 h-12 w-12 overflow-hidden rounded bg-white/10 sm:mx-0 sm:h-14 sm:w-14 sm:px-0">
                <Image
                  image={item.square_logo || item.image_logo || item.image_favicon}
                  failImage={noFavicon}
                  alt={"Workspace"}
                />
              </div>
              <div className="grid gap-y-1 sm:block sm:gap-y-0">
                <div className="sm:max-w-auto flex h-full items-center gap-x-4 overflow-visible truncate text-gray-600 group-hover:text-gray-900 group-hover:underline sm:w-auto sm:gap-x-2">
                  <span className="text-xl text-gray-600 sm:text-base">{item?.name}</span>
                  {/* <ArrowTopRightOnSquareIcon className="h-4 w-4 opacity-20 transition-all duration-150 group-hover:opacity-70" /> */}
                  <div className="ml-3 sm:hidden">{item?.workspace_type === "JWT_FULL_EMBED" && <WorkspaceSubscriberInstance />}</div>
                </div>
                <div className="hidden sm:inline">{item?.workspace_type === "JWT_FULL_EMBED" && <WorkspaceSubscriberInstance />}</div>
                <div className="flex sm:hidden">
                  {pagesCount}
                  {formsCount}
                  {groupsCount}
                  {usersCount}
                </div>
              </div>
            </div>
          </Link>
        </div>
      </TRBody>
      <TRBody className="hidden w-[15px] whitespace-nowrap !px-3 text-sm sm:table-cell md:w-[10%]">{pagesCount}</TRBody>
      <TRBody className="hidden w-[15px] whitespace-nowrap !px-3 text-sm sm:table-cell md:w-[10%]">{formsCount}</TRBody>
      <TRBody className="hidden w-[15px] whitespace-nowrap !px-3 text-sm sm:table-cell md:w-[10%]">{groupsCount}</TRBody>
      <TRBody className="hidden w-[15px] whitespace-nowrap !px-3 text-sm sm:table-cell md:w-[10%]">{usersCount}</TRBody>
      {/* <td className="whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium">
        <div className="flex justify-end">
          <QuickMenu
            items={[
              {
                name: "Edit",
                onClick: () => {
                  navigate(`/workspaces/${workspace._id}`);
                },
              },
            ]}
          />
        </div>
      </td> */}
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(WorkspaceEntry);
