import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import Select from "src/components/Shared/Forms/Selects/Select";
import Modal from "src/components/Shared/Modal";

const PdpModalManage = ({
  isOpen = false,
  workspaceId = null,
  setIsOpen = () => {},
  title = "",
  secondaryTitle = "",
  users = [],
  groups = [],
  pdpId = null,
  datasetId = null,
  setdatasetId = () => {},
  pdpData = {},
  setPdpData = () => {},
  datasetList = [],
  filterJson = {},
  onSubmit = () => {},
  successLoaderStart = false,
  ...props
}) => {
  const [selectedDataset, setSelectedDataset] = useState({ id: -1, name: "None selected" });
  // const [datasetDetails, setDatasetDetails] = useState({});

  // const getDatasetDetails = async () => {
  //   try {
  //     const res = await apiRequest("post", `/workspaces/${props?.workspaceDetails?._id}/dataset/${datasetId}`, {
  //       body: {
  //         authToken: props?.workspaceDetails?.auth_token,
  //       },
  //     });

  //     if (res.data.status === 200) {
  //       setDatasetDetails(res.data.data);
  //     }
  //   } catch (error) {
  //     console.dir("ERROR:", error);
  //   }
  // };

  const handleSelectedUsers = (usersArray) => {
    let updatedSelectedUsers = usersArray.reduce((users, user) => (user.selected ? [...users, user.key] : users), []);
    setPdpData({
      ...pdpData,
      users: updatedSelectedUsers,
    });
  };

  const handleSelectedGroups = (groupsArray) => {
    let updatedSelectedGroups = groupsArray.reduce((groups, group) => (group.selected ? [...groups, group.key] : groups), []);
    setPdpData({
      ...pdpData,
      groups: updatedSelectedGroups,
    });
  };

  useEffect(() => {
    if (datasetList.length > 0) {
      setSelectedDataset(datasetList.find((dataset) => dataset.id === datasetId));
    }
    // if (datasetId) {
    //   getDatasetDetails();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetList, datasetId]);

  return (
    <>
      <Modal
        title={title}
        secondaryTitle={secondaryTitle}
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(!isOpen);
        }}
        onSuccess={onSubmit}
        defaultStyles={{
          overFlowYVisible: false,
        }}
        defaultOptions={{
          onSuccessButtonText: "Save",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successLoaderStart,
        }}>
        <div className="relative w-full space-y-4">
          {(props?.modalType === "add" || props?.modalType === "edit") && (
            <div className="relative mb-4 w-full">
              <Input
                name="name"
                label="Name"
                inline={true}
                value={pdpData?.name}
                onChange={(e) =>
                  setPdpData({
                    ...pdpData,
                    name: e.target.value,
                  })
                }
              />
            </div>
          )}
          {props?.modalType === "add" && (
            <div className="relative mb-4 w-full">
              <Select
                inline={true}
                disabled={pdpId}
                label={"Dataset"}
                options={datasetList}
                selected={selectedDataset}
                setSelected={(e) => setdatasetId(e.id)}
              />
            </div>
          )}
          {(props?.modalType === "add" || props?.modalType === "edit") && (
            <div className="relative mb-4">
              <div className="mb-1 flex justify-between text-sm font-medium text-gray-700">Filters</div>
              <div className="space-y-2">
              {pdpData?.filters?.map((filter, index) => {
                return (
                  <div
                    key={filter?.id}
                    className="relative flex items-center pr-12">
                    <div className="relative w-full flex-shrink flex-grow">
                      <Input
                        inline={true}
                        name="column"
                        label="Column"
                        value={filter?.column}
                        onChange={(e) =>
                          setPdpData({
                            ...pdpData,
                            filters: pdpData.filters.map((filter, i) => {
                              if (index === i) {
                                return { ...filter, column: e.target.value };
                              } else {
                                return filter;
                              }
                            }),
                          })
                        }
                      />
                    </div>
                    <div className="relative">
                      <div className="mx-3 mt-3 text-xl font-bold text-gray-800">=</div>
                    </div>
                    <div className="relative w-full flex-shrink flex-grow">
                      <Input
                        inline={true}
                        name="values"
                        label="Text Value"
                        value={filter?.values[0]}
                        onChange={(e) =>
                          setPdpData({
                            ...pdpData,
                            filters: pdpData.filters.map((filter, i) => {
                              if (index === i) {
                                return { ...filter, values: [e.target.value] };
                              } else {
                                return filter;
                              }
                            }),
                          })
                        }
                      />
                    </div>
                    {index > 0 && (
                      <div className="absolute bottom-0 right-0">
                        <Button
                          type="button"
                          version="default"
                          className="relative flex h-10 w-10 items-center justify-center rounded text-gray-500 transition-all duration-300"
                          onClick={() => {
                            setPdpData({
                              ...pdpData,
                              filters: pdpData.filters.filter((fil, i) => i !== index),
                            });
                          }}>
                          <TrashIcon className="h-5 w-5" />
                        </Button>
                      </div>
                    )}
                  </div>
                );
              })}
              </div>
              <div className="mt-4 flex items-center">
                <Button
                  type="button"
                  version="default"
                  className="inline-flex w-full justify-center gap-2 rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setPdpData({
                      ...pdpData,
                      filters: [...pdpData.filters, filterJson],
                    });
                  }}>
                  <PlusIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                  Add Condition
                </Button>
              </div>
            </div>
          )}
          {(props?.modalType === "add" || props?.modalType === "edit" || props?.modalType === "userAssign") && (
            <div className="relative mb-4 w-full">
              <MultiSelectObject
                inline={true}
                defaultOptions={users.map((user) => {
                  return { key: user.id, value: user.name, selected: pdpData?.users?.includes(user.id), object: user };
                })}
                searchableFields={["name", "email"]}
                title="Assign users"
                onChange={handleSelectedUsers}
              />
            </div>
          )}
          {(props?.modalType === "add" || props?.modalType === "edit" || props?.modalType === "groupAssign") && (
            <div className="relative mb-4 w-full">
              <MultiSelectObject
                inline={true}
                defaultOptions={groups.map((group) => {
                  return { key: group.id, value: group.name, selected: pdpData?.groups?.includes(group.id), object: group };
                })}
                searchableFields={["name"]}
                title="Assign groups"
                onChange={handleSelectedGroups}
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {})(PdpModalManage);
