import { useEffect, useState } from "react";
import { connect } from "react-redux";
import NavigationPreviewBox from "src/components/Preview/NavigationPreviewBox";

function UserLayoutWrapper({ children, me, site, workspaceDetails, setShowLoader }) {
  const [hasImpersonatedUserId, setHasImpersonatedUserId] = useState(false);
  const [rolePreviewModal, setRolePreviewModal] = useState(false);

  useEffect(() => {
    let impersonatedUserId = localStorage.getItem("impersonatedUserId");
    if (impersonatedUserId) {
      setHasImpersonatedUserId(true);
    } else {
      setHasImpersonatedUserId(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("impersonatedUserId")]);

  const removePreviewUser = () => {
    localStorage.removeItem("impersonatedUserId");
    // navigate("/users");
  };

  return (
    <>
      <div className="relative flex h-full w-full flex-col">{children}</div>
      <div className="fixed bottom-6 right-6 z-10">
        <NavigationPreviewBox
          workspaceDetails={workspaceDetails}
          rolePreviewModal={rolePreviewModal}
          removePreviewUser={removePreviewUser}
          user={me}
          hasImpersonatedUserId={hasImpersonatedUserId}
          setRolePreviewModal={setRolePreviewModal}
          setShowLoader={setShowLoader}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
    site: state.site,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(UserLayoutWrapper);
