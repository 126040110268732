import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";

const TechicalIssues = ({ site }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (site?._id) {
      navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?._id]);
  return (
    <main className="h-full w-full grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-indigo-600">501</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Technical Issues</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we it appears that there are issues connecting to our servers.</p>
        <p className="mt-6 text-sm leading-7 text-gray-400">Please contact your website administrator if you need immediate support.</p>
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(TechicalIssues);
