import { connect } from "react-redux";
import { getAuthorizedUser } from "src/actions/auth";
import DomoWorkspaceGroupList from "src/components/Workspaces/Workspace/PlatformEmbed/Domo/Groups/DomoWorkspaceGroupList";
import WorkspaceGroupList from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupList";

const WorkspaceGroups = ({ pageName, getAuthorizedUser, viewOnly = false, userId = "", workspaceId = "", workspaceDetails = {}, ...props }) => {
  return (
    <>
      {workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" ? (
        <WorkspaceGroupList
          pageName={pageName}
          getAuthorizedUser={getAuthorizedUser}
          viewOnly={viewOnly}
          userId={userId}
          workspaceId={workspaceId}
          workspaceDetails={workspaceDetails}
          props
        />
      ) : (
        <>
          <DomoWorkspaceGroupList
            pageName={pageName}
            getAuthorizedUser={getAuthorizedUser}
            viewOnly={viewOnly}
            userId={userId}
            workspaceId={workspaceId}
            workspaceDetails={workspaceDetails}
            props={props}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  getAuthorizedUser,
})(WorkspaceGroups);
