export const emailTemplateConfig = ({ content, config = {} }) => {
  const date = new Date();
  config.year = date.getFullYear();

  let emailContent = content;

  for (let emailMergeText in config) {
    if (emailContent.includes(`{%${emailMergeText}%}`)) {
      let regex = new RegExp(`{%${emailMergeText}%}`, "g");
      // Directly replace without checking for the presence of the emailMergeText as a standalone string in the emailContent
      emailContent = emailContent.replace(regex, config[emailMergeText]);
    }
  }

  return emailContent;
};
