import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import { manageSiteSettings } from "src/actions/site";
import { H2, H3, H5 } from "src/components/Shared/Text/Headers";
import UserImportDataset from "src/components/Site/UserImportDataset";
import BulkUpload from "src/components/Users/Add/ImportUsers/BulkUpload";
import CSVFormat from "src/components/Users/Add/ImportUsers/CSVFormat";
import { classNames } from "src/helpers/classNames";

const ImportUsers = ({ site, manageSiteSettings }) => {
  const [downloadingCSV, setDownloadingCSV] = useState(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const [uploadType, setUploadType] = useState("bulk");

  useEffect(() => {
    if (site?._id) {
      setSendWelcomeEmail(site.bulk_upload_send_welcome_email);
      if ("DOMO_DATASET" === site.api_state) setUploadType("dataset");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site?._id]);

  const downloadCSVUserData = async () => {
    setDownloadingCSV(true);

    try {
      const { data: res, status } = await apiRequest("GET", `/users/csv-user-download`, null, {
        responseType: "blob",
      });
      if (status === 200) {
        // Convert the response data into a Blob
        const blob = res;

        // Create a Blob URL for the CSV data
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element to trigger the download
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.setAttribute("download", "user_permission_output.csv"); // Set the desired file name

        // Trigger a click on the link to start the download
        downloadLink.click();

        // Clean up the Blob URL after download
        URL.revokeObjectURL(blobUrl);
      } else {
        console.error("Server responded with an error:", res.statusText);
        // Handle the error or display a message to the user
      }
    } catch (error) {
      console.error("Error downloading CSV:", error);
      // Handle the error or display a message to the user
    } finally {
      setDownloadingCSV(false);
    }
  };

  const handleSendWelcomeEmail = async (value) => {
    try {
      setSendWelcomeEmail(value);
      const message = await manageSiteSettings({
        bulk_upload_send_welcome_email: value,
      });
      toast.success(message);
    } catch (err) {}
  };

  return (
    <div className="grid w-full gap-y-6">
      <Section>
        <H2 caption="Manage all users from one DOMO dataset in the cloud.">Import User Data</H2>
        <div className="flex flex-wrap gap-6">
          <Button
            version="default"
            className={classNames("flex w-full !h-auto max-w-80 flex-col !items-start space-y-2 rounded-md border bg-white px-4 py-4 text-left", uploadType === "bulk" ? "border-transparent ring-2 ring-highlightColor ring-offset-2" : "border-gray-200")}
            onClick={() => setUploadType("bulk")}>
            <div className="text-base font-semibold text-gray-800">Bulk Upload</div>
            <div className="text-sm text-gray-400">Upload users's from a locally stored CSV file.</div>
          </Button>
          <Button
            version="default"
            className={classNames("flex w-full !h-auto max-w-80 flex-col !items-start space-y-2 rounded-md border bg-white px-4 py-4 text-left", uploadType === "dataset" ? "border-transparent ring-2 ring-highlightColor ring-offset-2" : "border-gray-200")}
            onClick={() => setUploadType("dataset")}>
            <div className="text-base font-semibold text-gray-800">DOMO Dataset Upload</div>
            <div className="text-sm text-gray-400">Manage all users from one DOMO dataset in the cloud.</div>
          </Button>
        </div>
        <div className="mt-4 w-full max-w-3xl">
          {uploadType === "bulk" ? (
            <div className="w-full">
              <H3 caption="Upload users's from a locally stored CSV file.">Bulk Upload</H3>
              <BulkUpload />
            </div>
          ) : (
            <div className="relative w-full">
              <H3 caption="Manage all users from one DOMO dataset in the cloud.">DOMO Dataset Upload</H3>
              <UserImportDataset setUploadType={setUploadType} />
            </div>
          )}
        </div>
        <div className="w-full max-w-3xl">
          <TextAccordion
            startOpen={true}
            headerTextPosition="left"
            headerText="Advanced details">
            {site?._id && (
              <div className="flex w-full flex-col gap-y-3 md:flex-row">
                <div className="ml-2">
                  <Toggle
                    checked={sendWelcomeEmail}
                    onChange={handleSendWelcomeEmail}
                  />
                </div>
                <H5 caption='When enabled, users will need to follow the "reset password process" before logging in.'>Send welcome email on setup</H5>
              </div>
            )}
          </TextAccordion>
        </div>
      </Section>
      <Section>
        <H2 caption="Manage all users from one DOMO dataset in the cloud.">Export Data</H2>
        <div className="relative mt-4 grid w-full gap-y-12">
          <div className="relative w-full">
            <H3 caption="Download the schema for CSV upload via manual or API connection.">Schema Download</H3>
            <CSVFormat className="pt-2" />
          </div>
          <div className="relative flex w-full justify-between">
            <H3 caption="Create a CSV export of all current permission assignment.">Download User Data</H3>
            <div className="flex min-w-[140px] items-center justify-end">
              <Button
                disabled={downloadingCSV}
                onClick={downloadCSVUserData}>
                Download CSV
              </Button>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(ImportUsers);
