import { useState } from "react";
import Modal from "src/components/Shared/Modal";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";
import SSOManageFormContent from "src/components/Workspaces/Workspace/SSO/SSOManageFormContent";

const WorkspaceSSOAddModal = ({ title = "", secondaryTitle = "", workspaceId = null, isOpen, onCancel = () => {}, onSuccess = () => {}, ...props }) => {
  const [formData, setFormData] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const onCreate = async () => {
    try {
      setDisabled(true);
      const { data } = await apiRequest("POST", workspaceId ? `/workspaces/:workspace_id/sso/add` : `/sso/add`, {
        body: formData,
        params: { workspace_id: workspaceId },
      });
      setDisabled(false);
      toast.success(data.message);
      onSuccess();
    } catch (error) {
      setDisabled(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Modal
        title={title}
        secondaryTitle={secondaryTitle}
        isOpen={isOpen}
        size={`xxl`}
        onCancel={onCancel}
        onSuccess={onCreate}
        defaultOptions={{
          onSuccessButtonText: "Save",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: disabled,
        }}
        defaultStyles={{
          overFlowYVisible: false,
        }}>
        <div className="relative min-h-[200px] overflow-visible py-4">
          <SSOManageFormContent
            workspaceId={workspaceId}
            formData={formData}
            setFormData={setFormData}
            formReset={isOpen}
          />
        </div>
      </Modal>
    </>
  );
};

export default WorkspaceSSOAddModal;
