import { CheckIcon, NoSymbolIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Section from "src/components/Shared/Containers/Section";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import ManageWorkspace2FAModal from "src/components/Users/Modal/ManageWorkspace2FAModal";

const SettingsContainer = ({ defaultTitle = {}, isEnabled = false, setIsEnabled = () => {}, timeFrame = 0, setTimeFrame = () => {}, isButtonDisabled = false, onSubmit = () => {}, onCancel = () => {}, isWorkspaceOptionEnabled = false, workspaceOptionOnClick = () => {} }) => {
  return (
    <EditContainer
      title={defaultTitle?.title}
      preview={{
        style: "success",
        isSuccess: isEnabled,
        text: !isEnabled ? (
          <>
            <NoSymbolIcon className="h-5 w-5" /> Disabled
          </>
        ) : (
          <>
            <CheckIcon className="h-5 w-5" /> Enabled
          </>
        ),
      }}
      onSuccess={onSubmit}
      defaultOptions={{
        onSuccessLoaderVisible: isButtonDisabled,
        onSuccessLoaderStart: isButtonDisabled,
      }}
      onCancel={onCancel}>
      <div className="flex w-full flex-wrap gap-x-4 gap-y-6">
        <div className="min-w-[260px] px-2">
          <div className="relative w-full">
            <ToggleHeader
              title={defaultTitle?.title}
              subtitle={defaultTitle?.subTitle}>
              <Toggle
                checked={isEnabled}
                onChange={(e) => {
                  setIsEnabled(e);
                }}
              />
            </ToggleHeader>
            <div className="flex w-full items-center gap-x-5 gap-y-3 font-light">
              <p>{defaultTitle?.inputFirstTitle}</p>
              <div className="w-[70px]">
                <Input
                  name="user-settings"
                  disabled={!isEnabled}
                  value={timeFrame}
                  onChange={(e) => {
                    setTimeFrame(e.target.value.replace(/[^0-9]/g, ""));
                  }}
                />
              </div>
              <p>{defaultTitle?.inputSecondTitle}</p>
            </div>
            {isWorkspaceOptionEnabled && (
              <div className="mt-8 flex w-full items-center gap-x-5 gap-y-3">
                <Button
                  disabled={!isEnabled}
                  version="default"
                  onClick={workspaceOptionOnClick}>
                  <div className="flex">
                    + Select workspaces <p className="pl-1 font-light">(optional)</p>
                  </div>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </EditContainer>
  );
};

const UserSettings = ({ site, manageSiteSettings }) => {
  const [workspaces, setWorkspaces] = useState([]);

  const [userInactivityLogoutEnabled, setUserInactivityLogoutEnabled] = useState(false);
  const [userInactivityLogoutTimeframe, setUserInactivityLogoutTimeframe] = useState("");
  const [userInactivityButtonDisabled, setUserInactivityButtonDisabled] = useState(false);

  const [userDeactivationEnabled, setUserDeactivationEnabled] = useState(false);
  const [userDeactivationTimeframe, setUserDeactivationTimeframe] = useState("");
  const [userDeactivationButtonDisabled, setUserDeactivationButtonDisabled] = useState(false);

  const [userDeletionEnabled, setUserDeletionEnabled] = useState(false);
  const [userDeletionTimeframe, setUserDeletionTimeframe] = useState("");
  const [userDeletionButtonDisabled, setUserDeletionButtonDisabled] = useState(false);

  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [twoFactorTimeframe, setTwoFactorTimeframe] = useState("");
  const [twoFactorTimeframeButtonDisabled, setTwoFactorTimeframeButtonDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onUserInactivitySubmit = async () => {
    try {
      setUserInactivityButtonDisabled(true);
      const message = await manageSiteSettings({
        user_inactivity_logout_enabled: userInactivityLogoutEnabled,
        user_inactivity_logout_timeframe: userInactivityLogoutTimeframe,
      });
      toast.success(message);
      setUserInactivityButtonDisabled(false);
    } catch (error) {
      setUserInactivityButtonDisabled(false);
    }
  };

  const onUserDeactivationSubmit = async () => {
    try {
      setUserDeactivationButtonDisabled(true);
      const message = await manageSiteSettings({
        user_deactivation_enabled: userDeactivationEnabled,
        user_deactivation_timeframe: userDeactivationTimeframe,
      });
      toast.success(message);
      setUserDeactivationButtonDisabled(false);
    } catch (error) {
      setUserDeactivationButtonDisabled(false);
    }
  };

  const onUserDeletionSubmit = async () => {
    try {
      setUserDeletionButtonDisabled(true);
      const message = await manageSiteSettings({
        user_deletion_enabled: userDeletionEnabled,
        user_deletion_timeframe: userDeletionTimeframe,
      });
      toast.success(message);
      setUserDeletionButtonDisabled(false);
    } catch (error) {
      setUserDeletionButtonDisabled(false);
    }
  };

  const onTwoFactorTimeframeSubmit = async () => {
    try {
      setTwoFactorTimeframeButtonDisabled(true);
      const message = await manageSiteSettings({
        two_factor_enabled: twoFactorEnabled,
        two_factor_timeframe: twoFactorTimeframe,
      });
      toast.success(message);
      setTwoFactorTimeframeButtonDisabled(false);
    } catch (error) {
      setTwoFactorTimeframeButtonDisabled(false);
    }
  };

  useEffect(() => {
    if (site?._id) {
      setUserInactivityLogoutEnabled(site.user_inactivity_logout_enabled || false);
      setUserInactivityLogoutTimeframe(site.user_inactivity_logout_timeframe || "");
      setUserDeactivationEnabled(site.user_deactivation_enabled || false);
      setUserDeactivationTimeframe(site.user_deactivation_timeframe || "");
      setUserDeletionEnabled(site.user_deletion_enabled || false);
      setUserDeletionTimeframe(site.user_deletion_timeframe || "");
      setTwoFactorEnabled(site.two_factor_enabled || false);
      setTwoFactorTimeframe(site.two_factor_timeframe || "");
    }
  }, [site]);

  useEffect(() => {
    const loadWorkspaces = async () => {
      const res = await apiRequest("post", "/workspaces/list", {});
      const data = res.data;
      if (data.status === 200) {
        setWorkspaces(data.data);
      }
    };
    loadWorkspaces();
  }, []);

  return (
    <>
      <Section>
        <div className="grid w-full gap-y-4">
          <SettingsContainer
            defaultTitle={{
              title: `Multi-factor email authentication (2FA)`,
              subTitle: `Require email 2FA choose to set how long devices can be remembered.`,
              inputFirstTitle: `Email 2FA is required after`,
              inputSecondTitle: `days without a login.`,
            }}
            isEnabled={twoFactorEnabled}
            setIsEnabled={setTwoFactorEnabled}
            timeFrame={twoFactorTimeframe}
            setTimeFrame={setTwoFactorTimeframe}
            isButtonDisabled={twoFactorTimeframeButtonDisabled}
            onSubmit={onTwoFactorTimeframeSubmit}
            onCancel={() => {
              setTwoFactorEnabled(site.two_factor_enabled || false);
              setTwoFactorTimeframe(site.two_factor_timeframe || "");
            }}
            isWorkspaceOptionEnabled={true}
            workspaceOptionOnClick={() => setIsOpen(true)}
          />
          <SettingsContainer
            defaultTitle={{
              title: `Inactivity auto-logout`,
              subTitle: `Define how long a user may be inactive.`,
              inputFirstTitle: `Log a user out after`,
              inputSecondTitle: `minutes spent inactive.`,
            }}
            isEnabled={userInactivityLogoutEnabled}
            setIsEnabled={setUserInactivityLogoutEnabled}
            timeFrame={userInactivityLogoutTimeframe}
            setTimeFrame={setUserInactivityLogoutTimeframe}
            isButtonDisabled={userInactivityButtonDisabled}
            onSubmit={onUserInactivitySubmit}
            onCancel={() => {
              setUserInactivityLogoutEnabled(site.user_inactivity_logout_enabled || false);
              setUserInactivityLogoutTimeframe(site.user_inactivity_logout_timeframe || "");
            }}
          />
          <SettingsContainer
            defaultTitle={{
              title: `Automatic User Deactivation`,
              subTitle: `(Excluding admins)`,
              inputFirstTitle: `Deactivate users after`,
              inputSecondTitle: `days without a login.`,
            }}
            isEnabled={userDeactivationEnabled}
            setIsEnabled={setUserDeactivationEnabled}
            timeFrame={userDeactivationTimeframe}
            setTimeFrame={setUserDeactivationTimeframe}
            isButtonDisabled={userDeactivationButtonDisabled}
            onSubmit={onUserDeactivationSubmit}
            onCancel={() => {
              setUserDeactivationEnabled(site.user_deactivation_enabled || false);
              setUserDeactivationTimeframe(site.user_deactivation_timeframe || "");
            }}
          />
          <SettingsContainer
            defaultTitle={{
              title: `Automatic User Deletion`,
              subTitle: `(Excluding admins)`,
              inputFirstTitle: `Permanently delete users after`,
              inputSecondTitle: `days without a login.`,
            }}
            isEnabled={userDeletionEnabled}
            setIsEnabled={setUserDeletionEnabled}
            timeFrame={userDeletionTimeframe}
            setTimeFrame={setUserDeletionTimeframe}
            isButtonDisabled={userDeletionButtonDisabled}
            onSubmit={onUserDeletionSubmit}
            onCancel={() => {
              setUserDeletionEnabled(site.user_deletion_enabled || false);
              setUserDeletionTimeframe(site.user_deletion_timeframe || "");
            }}
          />
        </div>
      </Section>
      <ManageWorkspace2FAModal
        workspaces={workspaces}
        isOpen={isOpen}
        two_factor_enabled={twoFactorEnabled}
        two_factor_timeframe={twoFactorTimeframe}
        onCancel={() => setIsOpen(false)}
        onSuccess={() => setIsOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(UserSettings);
