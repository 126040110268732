import { motion, useIsPresent } from "framer-motion";
import { useRef, useState, useEffect } from "react";
import { classNames } from "src/helpers/classNames";

const TableRow = ({ keyId, children, lastRow, classes = "", isHoverable = false, provided, isDragging = { keyId: '', value: false } }) => {
  const [customKey, setCustomKey] = useState('')

  const ref = useRef(null);
  let isPresent = useIsPresent();
  let generateStyle = () => {
    if (isPresent) {
      return {
        position: "relative",
      };
    } else {
      return {
        position: "absolute",
        display: "flex",
        alignItems: "center",
      };
    }
  };

  // Rerender the component on drag
  useEffect(() => { 
    if (isDragging) setCustomKey(`${new Date().getTime()}`) 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging?.value])

  return (
    <motion.tr
      layout={!isDragging ? true : !isDragging?.value}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={(isDragging && !isDragging?.value) ? {} : { opacity: { duration: 0.3 } }}
      style={generateStyle}
      key={keyId + customKey}
      ref={(element) => {
        ref.current = element;
        if (provided) {
          provided.innerRef(element);
        }
      }}
      {...provided?.draggableProps}
      //{...provided?.dragHandleProps}
      className={classNames("relative", isHoverable?"group/tableRow":"", classes, lastRow ? "rounded-xl" : "")}
    >
      {children}
    </motion.tr>
  );
};

export default TableRow;
