import React from 'react';
import { UserIcon, WindowIcon, ClipboardDocumentIcon, IdentificationIcon, BuildingOfficeIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import ActivityItem from "src/components/Shared/MultiColumnLayout/TabContent/ActivityItem";

const ActivityContent = () => {
  const data = [
    {_id:1, icon: UserIcon, label:"User Added", text: "Admin added a user to the workspace", color:"text-green-500", background: "bg-green-400/20", date:"6 Mar 2023"},
    {_id:2, icon: WindowIcon, label:"Page Created", text: "Admin create a page to the workspace", color:"text-orange-500", background: "bg-orange-400/20", date:"6 Mar 2023"},
    {_id:3, icon: ClipboardDocumentIcon, label:"Group Created", text: "Admin create a group to the workspace", color:"text-indigo-500", background: "bg-indigo-400/20", date:"6 Mar 2023"},
    {_id:4, icon: IdentificationIcon, label:"SSO Updated", text: "Admin updated a SSO credentials", color:"text-rose-500", background: "bg-rose-400/20", date:"6 Mar 2023"},
    {_id:5, icon: BuildingOfficeIcon, label:"Company Added", text: "Admin added a new Company", color:"text-amber-500", background: "bg-amber-400/20", date:"6 Mar 2023"},
    {_id:6, icon: Cog6ToothIcon, label:"Setting Updated", text: "Admin updated a setting to the workspace", color:"text-purple-500", background: "bg-purple-400/20", date:"6 Mar 2023"},
  ]
  return (
    <>
      <div className="relative px-8">
        <div className="divide-y divide-gray-200">
          {data.map((item) => (
            <ActivityItem key={item._id} data={item}/>
          ))}
        </div>
      </div>
    </>
  );
};

export default ActivityContent;