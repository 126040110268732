import { CheckIcon } from "@heroicons/react/24/outline";
import React from "react";
import Input from "src/components/Shared/Forms/Inputs/Input";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import { classNames } from "src/helpers/classNames";
import { linearScaleFrom, linearScaleTo } from "src/helpers/formHelper";

const FormLinearScale = ({ section = null, content = null, from = "form", answer = null, onHandleChange = () => {} }) => {
  return (
    <>
      {from === "response" ? (
        <div className="relative mt-4 flex items-end gap-10">
          {content?.linear_scale_from_label && <div className={classNames("block text-sm font-medium leading-6 text-gray-600")}>{content?.linear_scale_from_label}</div>}

          <div className="flex items-center gap-10">
            {Array.from({ length: content?.linear_scale_to - content?.linear_scale_from + 1 }, (_, index) => content?.linear_scale_from + index)?.map((value, index) => (
              <div
                key={`linear-${index}-${value}`}
                className="flex flex-col items-center space-y-2">
                <div>{value}</div>
                <div className={classNames("aspect-square w-5 flex-shrink-0 rounded-full border-2 border-gray-300", answer?.value?.toString() === value?.toString() && "!border-highlightColor bg-highlightColor")}>
                  {answer?.value?.toString() === value?.toString() && <CheckIcon className="h-4 w-4 text-white" />}
                </div>
              </div>
            ))}
          </div>

          {content?.linear_scale_to_label && <div className={classNames("block text-sm font-medium leading-6 text-gray-600")}>{content?.linear_scale_to_label}</div>}
        </div>
      ) : (
        <>
          <div className="col-span-1 grid grid-cols-2 gap-4">
            <div className="relative">
              <SelectMenu
                inline={true}
                label={"Scale from"}
                options={linearScaleFrom}
                dropdownClass={"w-full"}
                startIndex={linearScaleFrom?.findIndex((type) => type?.value === content?.linear_scale_from)}
                setOption={(option) => {
                  onHandleChange({ fields: [{ field: "linear_scale_from", value: option.value }] });
                }}
              />
            </div>
            <div className="relative">
              <SelectMenu
                inline={true}
                label={"Scale to"}
                options={linearScaleTo}
                dropdownClass={"w-full"}
                startIndex={linearScaleTo?.findIndex((type) => type?.value === content?.linear_scale_to)}
                setOption={(option) => {
                  onHandleChange({ fields: [{ field: "linear_scale_to", value: option.value }] });
                }}
              />
            </div>
            <div className="relative">
              <Input
                inline={true}
                label={"From label"}
                wrapperClass={"!bg-white"}
                value={content?.linear_scale_from_label}
                onChange={(e) => onHandleChange({ fields: [{ field: "linear_scale_from_label", value: e.target.value }] })}
              />
            </div>
            <div className="relative">
              <Input
                inline={true}
                label={"To label"}
                wrapperClass={"!bg-white"}
                value={content?.linear_scale_to_label}
                onChange={(e) => onHandleChange({ fields: [{ field: "linear_scale_to_label", value: e.target.value }] })}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FormLinearScale;
