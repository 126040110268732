import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login, loginTracking, otpVerify } from "src/actions/auth";
import { ssoLoginData } from "src/actions/sso";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import AuthContainer from "src/components/Auth/AuthContainer";
import AuthHeader from "src/components/Auth/AuthHeader";
import { useLocation } from "react-router-dom";
import useFetch from "src/hooks/useFetch";
import { ssoLoginActivity } from "src/actions/auth";
import { apiUrl } from "src/config/host";
import AuthDataLayout from "src/layouts/AuthDataLayout";

const Login = ({ seoTitle, ...props }) => {
  const { pathname } = useLocation();

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [attemp, setAttemp] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [timeLeft, setTimeLeft] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const navigate = useNavigate();

  const {
    response: { data: ssos, meta },
    status: { done: ssosLoaded },
  } = useFetch("/sso/default-list");

  useEffect(() => {
    if (attemp) {
      let currentTime = new Date();
      let lastTime = new Date(attemp?.updated_at);
      let seconds = (currentTime.getTime() - lastTime.getTime()) / 1000;
      if (seconds > 0 && seconds < 61) {
        let startDate = new Date(attemp?.updated_at);
        startDate.setSeconds(startDate.getSeconds() - (60 - seconds));
        calculateTimeLeft(startDate, lastTime);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attemp]);

  useEffect(() => {
    if (attemp && startDate) {
      let startDate2 = new Date(startDate);
      startDate2.setSeconds(startDate2.getSeconds() + 1);
      let endDate = new Date(attemp?.updated_at);
      const timer = setTimeout(() => {
        calculateTimeLeft(startDate2, endDate);
      }, 1000);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  const calculateTimeLeft = (startDate, endDate) => {
    if (attemp?.attemp_count < 10) return;

    let difference = +new Date(endDate) - +new Date(startDate);
    let timeLeft = {};
    if (difference > 0) {
      setIsDisabled(true);
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60) > 9 ? Math.floor((difference / 1000 / 60) % 60) : "0" + Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60) > 9 ? Math.floor((difference / 1000) % 60) : "0" + Math.floor((difference / 1000) % 60),
      };
      setTimeLeft(timeLeft);
      setStartDate(startDate);
    } else {
      setTimeLeft("");
      setStartDate(null);
      setIsDisabled(false);
    }
  };

  const loginUser = async (e) => {
    if (isDisabled) {
      return;
    }
    setError("");

    e.preventDefault();
    setIsDisabled(true);

    try {
      const { message } = await props.login({
        email,
        password,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        token: localStorage.getItem("access_token"),
      });
      setError("");
      toast.success(message);
      return message;
    } catch (error) {
      toast.error(error.message);
      // setError(error.message);
      try {
        const attemp = await props.loginTracking({});
        setAttemp(attemp);
      } catch (error) {
        // console.dir("ERROR:", error);
      } finally {
        setIsDisabled(false);
      }
    }
  };

  const otpVerify = async (e) => {
    if (isSubmitDisabled) {
      return;
    }
    setError("");

    e.preventDefault();
    setIsSubmitDisabled(true);

    try {
      const { message } = await props.otpVerify({
        otp,
        email: props?.twoFactorDetails?.email,
      });
      setError("");
      toast.success(message);
      return message;
    } catch (error) {
      setIsSubmitDisabled(false);
      toast.error(error.message);
      // setError(error.message);
      try {
        const attemp = await props.loginTracking({});
        setAttemp(attemp);
      } catch (error) {
        // console.dir("ERROR:", error);
      } finally {
        setIsDisabled(false);
      }
    }
  };

  let access_token = new URLSearchParams(window.location.search).get("access_token");
  let refresh_token = new URLSearchParams(window.location.search).get("refresh_token");
  let user_id = new URLSearchParams(window.location.search).get("user_id");

  useEffect(() => {
    if (access_token && refresh_token && user_id) {
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("userId", user_id);
      // Remove user inactive time because login makes user active
      localStorage.removeItem("userLastActiveTime");

      ssoLoginActivity();
      // navigate("/users"); NAVIGATE
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loginTrack = async () => {
      try {
        const attemp = await props.loginTracking({});
        setAttemp(attemp);
      } catch (error) {
        // console.dir("ERROR:", error);
      }
    };

    loginTrack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ssos?.length === 0) {
      navigate("/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssos]);

  const ssoLogin = async (id) => {
    try {
      const message = await props.ssoLoginData({ id });
      window.location.href = message;
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  return (
    <>
      {ssosLoaded ? (
        <AuthDataLayout
          header={
            <AuthHeader
              seoTitle={seoTitle}
              title="Administrator login portal"
              switchTo={{
                startText: "Or switch to",
                text: "User Portal",
                location: props.site?.site_domain || "/",
              }}
            />
          }>
          <AuthContainer site={props.site}>
            <>
              {pathname === "/sso-logout" && meta?.count > 0 && !user_id && (
                <>
                  <div className="-mt-5 mb-7 flex w-full justify-center">
                    <div className="rounded-md bg-red-100 px-3 py-2">You have been logged out.</div>
                  </div>
                  {ssos.map((item) => (
                    <div
                      key={item?._id}
                      className="mt-4">
                      <Button
                        version="gray"
                        className="border text-white"
                        width="w-full"
                        style={{
                          borderStyle: "solid",
                          border: props.site?.sso_button_text_color,
                          color: props.site?.sso_button_text_color || "#fff",
                          backgroundColor: props.site?.sso_button_color,
                        }}
                        onClick={() => ssoLogin(item?._id)}>
                        {props.site.sso_button_text ? props.site.sso_button_text : item.provider_name}
                      </Button>
                    </div>
                  ))}
                  <div className="my-5 flex items-center justify-between">
                    <div className="h-[1px] w-1/4 bg-gray-300"></div>
                    <span className="text-xs text-gray-500 sm:text-sm">Or continue with email</span>
                    <div className="h-[1px] w-1/4 bg-gray-300"></div>
                  </div>
                  <div className="">
                    <a
                      href="/login"
                      className="text-regular inline-flex h-full w-full cursor-pointer items-center justify-center rounded-md border bg-white px-4 py-2 font-medium text-gray-600 shadow-sm focus:outline-none">
                      <div className="flex items-center gap-x-2 text-sm">Go to sign in</div>
                    </a>
                  </div>
                </>
              )}
              {(pathname === "/" && ssosLoaded) || pathname === "/login" ? (
                <>
                  {props?.twoFactorDetails?.two_factor_enabled ? (
                    <>
                      <form
                        className="flex w-full flex-col gap-y-3"
                        autoComplete="on">
                        {/* OTP */}
                        <div className="w-full">
                          <Input
                            inline={true}
                            type="number"
                            autoComplete="on"
                            name="contact-otp"
                            label="OTP"
                            error={error === "OTP field is required"}
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                otpVerify(e);
                              }
                            }}
                          />
                        </div>
                      </form>
                      <div className="mt-4">
                        <Button
                          width="w-full"
                          disabled={isSubmitDisabled}
                          onClick={otpVerify}>
                          Submit
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <form
                        className="flex w-full flex-col gap-y-2"
                        autoComplete="on">
                        {/* Email */}
                        <div className="w-full">
                          <Input
                            inline={true}
                            type="email"
                            autoComplete="on"
                            name="contact-email"
                            label="Email"
                            error={error === "Email field is required"}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                loginUser(e);
                              }
                            }}
                          />
                        </div>

                        {/* Password */}
                        <div className="w-full min-w-[200px]">
                          <Input
                            inline={true}
                            autoComplete="on"
                            type="password"
                            name="password"
                            label="Password"
                            error={error === "Password field is required."}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                loginUser(e);
                              }
                            }}
                          />
                        </div>
                      </form>
                      <div className=" flex w-full justify-end">
                        <Link
                          to="/forgot-password/submit-email"
                          className="my-2 text-sm text-slate-800">
                          Forgot Password?
                        </Link>
                      </div>
                      {attemp && attemp.attemp_count >= 10 && timeLeft ? (
                        <div className="mt-4">
                          <Button
                            width="w-full"
                            disabled={isDisabled}>
                            Your login has been blocked for {timeLeft?.minutes}:{timeLeft?.seconds}
                          </Button>
                        </div>
                      ) : (
                        <div className="mt-2">
                          <Button
                            className="h-9 !py-0 !font-normal "
                            width="w-full"
                            disabled={isDisabled}
                            onClick={loginUser}>
                            Login
                          </Button>
                        </div>
                      )}
                      {meta?.count > 0 && (
                        <>
                          <div className="my-5 flex items-center justify-between">
                            <div className="h-[1px] w-1/4 bg-gray-300"></div>
                            <span className="text-xs text-gray-500 sm:text-sm">Or continue with SSO</span>
                            <div className="h-[1px] w-1/4 bg-gray-300"></div>
                          </div>
                          {ssos?.map((item) => (
                            <div
                              key={item?._id}
                              className="mt-4">
                              <Button
                                version="gray"
                                className="border-[1px] text-white"
                                width="w-full"
                                style={{
                                  borderColor: props.site?.sso_button_text_color,
                                  color: props.site?.sso_button_text_color || "#fff",
                                  backgroundColor: props.site?.sso_button_color,
                                }}
                                onClick={() => (window.location.href = `${apiUrl}/login-sso`)}>
                                {props.site.sso_button_text ? props.site.sso_button_text : item.provider_name}
                              </Button>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                !(pathname === "/" || meta?.count) && !ssosLoaded && <Preloader />
              )}

              {/* <div className="form-action mt-2">
              <a
                // href="https://symlinktechllp.my.salesforce.com/?ec=302&startURL=%2Fidp%2Flogin%3Fapp%3D0sp5i000000blKb"
                href="https://trial-1942520.okta.com/app/trial-1942520_clearsquareportal_1/exk12nxefwuJRYD6m697/sso/saml"
                className="btn-action-primary mr-3"
              >
                OKTA SSO
              </a>

              <a
                href="https://symlinktechllp.my.salesforce.com/?ec=302&startURL=%2Fidp%2Flogin%3Fapp%3D0sp5i000000blKb"
                // href="https://trial-1942520.okta.com/app/trial-1942520_clearsquareportal_1/exk12nxefwuJRYD6m697/sso/saml"
                className="btn-action-secondary mr-3"
              >
                SALESFORCE SSO
              </a>
            </div> */}
            </>
          </AuthContainer>
        </AuthDataLayout>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    sso: state.sso,
    twoFactorDetails: state.twoFactorDetails,
  };
};

export default connect(mapStateToProps, { login, loginTracking, ssoLoginData, otpVerify })(Login);
