import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Modal from "src/components/Shared/Modal";

const ManageCustomAttributeModal = ({ field = null, isOpen, onCancel = () => {}, onSuccess = () => {} }) => {
  const fieldJson = {
    _id: null,
    label: null,
    is_required: false,
    is_default: false,
    default_value: null,
  };
  const [isDisabled, setIsDisabled] = useState(false);
  const [item, setItem] = useState(fieldJson);

  const onSubmit = async () => {
    try {
      setIsDisabled(true);
      const params = { id: item?._id };
      const { data: resData } = await apiRequest(item?._id ? `PUT` : `POST`, item?._id ? `/users/custom-fields/:id` : `/users/custom-fields`, {
        body: item,
        params,
      });
      onSuccess();
      toast.success(resData.message);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    setItem(field ? { ...field, is_default: field?.default_value ? true : false } : fieldJson);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, field]);

  return (
    <>
      <Modal
        title="Custom Attribute"
        secondaryTitle={item?._id ? `Edit` : `Add`}
        isOpen={isOpen}
        onCancel={onCancel}
        onSuccess={onSubmit}
        defaultOptions={{
          onSuccessButtonText: "Save",
          onSuccessLoaderVisible: isDisabled,
          onSuccessLoaderStart: isDisabled,
        }}>
        <div className="relative mb-5 flex w-full flex-col gap-y-5">
          <div className="flex w-full items-center ">
            <div className="w-full max-w-md">
              <Input
                label="Label"
                value={item?.label || ""}
                inline={true}
                onChange={(e) => setItem((prevData) => ({ ...prevData, label: e.target.value }))}
              />
            </div>
          </div>
          <div className="grid gap-y-2">
            <div className="flex items-center gap-x-3">
              <Checkbox
                checkboxName="set_default"
                onChange={(e) => setItem((prevData) => ({ ...prevData, is_required: e.target.checked }))}
                isChecked={item?.is_required || false}
              />
              <p className="text-gray-500">*Is a required field</p>
            </div>
            <div className="w-full space-y-5">
              <div className="flex items-center gap-x-3">
                <Checkbox
                  checkboxName="set_default"
                  onChange={(e) => setItem((prevData) => ({ ...prevData, is_default: e.target.checked, default_value: null }))}
                  isChecked={item?.is_default || false}
                />
                <p className="text-gray-500">Add a default value</p>
              </div>
              {item?.is_default && (
                <div className="w-full max-w-md">
                  <Input
                    disabled={false}
                    inline={true}
                    label="Default"
                    value={item?.default_value || ""}
                    onChange={(e) => setItem((prevData) => ({ ...prevData, default_value: e.target.value }))}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ManageCustomAttributeModal;
