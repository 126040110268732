import React, { useEffect } from "react";
import { GlobeAltIcon, ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import FormList from "src/components/Forms/FormList";
import { useNavigate, useLocation } from "react-router-dom";
import { H1 } from "src/components/Shared/Text/Headers";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { connect } from "react-redux";
import { getAuthorizedUser } from "src/actions/auth";

const Forms = ({ ...props }) => {
  const router = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    getAuthorizedUser();
  }, []);

  const existsPath = props.menus.some((obj) => obj.path?.includes(router.pathname));

  if (Object.keys(props.menus).length && !existsPath) {
    navigate("/");
  }

  const tabs = [
    { name: "All forms", icon: ClipboardDocumentListIcon, component: <FormList /> },
    { name: "Global forms", icon: GlobeAltIcon, component: <FormList isGlobal={true} /> },
  ];

  if (!props?.user?._id) return <></>;
  if (props?.user?.type === "editor" && props?.user?.workspaces?.length === 1) {
    window.location.href = `/workspaces/${props.user.workspaces[0]._id}?tab=Forms`;
    return <></>;
  }

  return (
    <>
      {Object.keys(props.menus).length > 0 && existsPath && (
        <>
          <div className="flex gap-x-3 items-center">
            <H1>Forms </H1><p className="text-lg text-gray-400 md:mb-6">(Beta Feature)</p>
            </div>
          <Tabs tabs={tabs} />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    menus: Object.values(state.menus),
  };
};

export default connect(mapStateToProps, { getAuthorizedUser })(Forms);
