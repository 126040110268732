import { Bars4Icon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import ActivityList from "src/components/Activity/ActivityList";
import ActivitySettings from "src/components/Activity/ActivitySettings";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { H1 } from "src/components/Shared/Text/Headers";

function Activity() {
  const tabs = [
    { name: "Activity Log", icon: Bars4Icon, component: <ActivityList /> },
    { name: "Settings", icon: WrenchScrewdriverIcon, component: <ActivitySettings /> },
  ];

  return (
    <>
      <H1>Activity List</H1>
      <Tabs tabs={tabs} />
    </>
  );
}

export default Activity;
