import { PencilIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";

const QuickSightPage = ({ newPage = {}, setNewPage = () => {}, startIndex = -1, pageCredentialOptions = {}, user = null, platformEmbeds = [] }) => {
  const [credMenuDisabled, setCredMenuDisabled] = useState(true);
  const [embedTypes, setEmbedTypes] = useState([{ key: "Dashboard", value: "EMBED_PAGE" }]);

  useEffect(() => {
    setEmbedTypes((prevData) => {
      if (platformEmbeds?.length > 0) {
        const newEntry = { key: "Platform", value: "EMBED_PLATFORM" };
        const isDuplicate = prevData.find((entry) => entry.value === newEntry.value);
        if (!isDuplicate) {
          return [...prevData, newEntry];
        }
        return prevData;
      } else {
        return prevData.filter((data) => data?.value !== "EMBED_PLATFORM");
      }
    });
  }, [platformEmbeds]);

  useEffect(() => {
    if (!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)) {
      setNewPage((prevData) => ({
        ...prevData,
        credential_id: pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)?.value,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPage?._id, pageCredentialOptions]);

  useEffect(() => {
    if (!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)?.value === newPage?.credential_id) {
      setCredMenuDisabled(true);
    }
  }, [newPage?._id, pageCredentialOptions, newPage?.credential_id]);

  return (
    <div>
      <div className="grid gap-y-4">
        <div className="flex w-full flex-col gap-x-3 gap-y-3 sm:flex-row">
          <div className="w-1/3 flex-none">
            {/* Embed Type */}
            <SelectMenu
              label="Embed Type"
              inline={true}
              startIndex={embedTypes.findIndex((item) => item.value === newPage.embed_type)}
              options={embedTypes}
              setOption={(option) => {
                setNewPage({
                  ...newPage,
                  embed_type: option.value,
                });
              }}
            />
          </div>
          <div className="flex-grow">
            {/* Credentials */}
            {newPage?.embed_type !== "EMBED_PLATFORM" ? (
              <div className="relative">
                <SelectMenu
                  inline={true}
                  disabled={(!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default) && credMenuDisabled) || (newPage?._id && pageCredentialOptions.length === 0 && newPage?.credential_name)}
                  label="QuickSight credentials"
                  emptyListText={newPage?.credential_name || "No listed items"}
                  defaultText={newPage?.credential_name || "Select an option"}
                  startIndex={startIndex}
                  options={pageCredentialOptions || [{ key: "Select credentials", value: -1 }]}
                  setOption={(option) => {
                    setNewPage({ ...newPage, credential_id: option.value });
                  }}
                />
                {!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default) && credMenuDisabled && (
                  <div className="absolute bottom-0.5 right-0.5 z-30 flex aspect-square w-9 items-center justify-center rounded-md bg-white">
                    <Button
                      version="default"
                      className="!h-6"
                      onClick={() => setCredMenuDisabled(false)}>
                      <PencilIcon className="h-4 w-4 text-gray-400" />
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="relative">
                <SelectMenu
                  label="Platform Embeds"
                  startIndex={platformEmbeds.findIndex((item) => item._id === newPage.platform_embed)}
                  options={platformEmbeds?.map((item) => ({ key: item?.name, value: item?._id })) || [{ key: "Select platform", value: -1 }]}
                  setOption={(option) => {
                    setNewPage({ ...newPage, platform_embed: option.value });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {newPage?.embed_type !== "EMBED_PLATFORM" && (
          <div className="w-full">
            <Input
              inline={true}
              label="Dashboard ID"
              type="text"
              value={newPage.dashboard_id}
              onChange={(e) => setNewPage({ ...newPage, dashboard_id: e.target.value })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default QuickSightPage;
