import React, { useEffect, useState } from "react";
import SSOUploadMetaDataForm from "./SSOUploadMetaDataForm";
import SSOManualForm from "./SSOManualForm";
import Button from "src/components/Shared/Buttons/Button";
import { classNames } from "src/helpers/classNames";

const SSOManageFormContent = ({ workspaceId = null, formReset = false, formData = null, setFormData = () => {}, setItemModifiedCount = () => {} }) => {
  const formJson = {
    provider_type: "general",
    provider_name: "",
    domain: "",
    client_id: "",
    client_id_placeholder: "",
    client_secret: "",
    client_secret_placeholder: "",
    identity_url: "",
    login_url: "",
    logout_url: "",
    certificate: "",
  };
  const [uploadType, setUploadType] = useState("manual");

  const onCallback = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      login_url: data?.identityProviderUrl,
      logout_url: data?.logoutUrl,
      certificate: data?.cert,
    }));
    setUploadType("manual");
  };

  useEffect(() => {
    setFormData(formJson);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formReset]);

  useEffect(() => {

  }, [uploadType])

  return (
    <>
      <div className="w-ful mb-8 max-w-4xl border-b border-solid border-gray-200 pb-8">
        <h5 className="mb-4 text-base font-medium">How will you setup up the metadata?</h5>
        <div className="grid grid-cols-2 sm:flex flex-wrap gap-3 sm:gap-6">
          <Button
            version="default"
            className={classNames("w-full flex-col !h-auto !items-start max-w-80 space-y-2 rounded-md border px-4 py-4 text-left bg-white", uploadType === 'manual' ? "ring-2 ring-offset-2 ring-highlightColor border-transparent" :"border-gray-200")}
            onClick={() => setUploadType("manual")}>
            <div className="text-base font-semibold text-gray-800">Manually</div>
            <div className="text-sm text-gray-400">Fill out this form from your IDP and find the information in its SSO setup.</div>
          </Button>
          <Button
            version="default"
            className={classNames("w-full flex-col !h-auto !items-start max-w-80 space-y-2 rounded-md border  px-4 py-4 text-left bg-white", uploadType === 'upload' ? "ring-2 ring-offset-2 ring-highlightColor border-transparent" :"border-gray-200")}
            onClick={() => setUploadType("upload")}>
            <div className="text-base font-semibold text-gray-800">Upload File</div>
            <div className="text-sm text-gray-400">Your IDP may also have a metadata file with all this data that you can upload here.</div>
          </Button>
        </div>
      </div>
      {uploadType === "upload" ? (
        <SSOUploadMetaDataForm
          workspaceId={workspaceId}
          formData={formData}
          setFormData={setFormData}
          onCallback={onCallback}
        />
      ) : (
        <SSOManualForm
          formData={formData}
          setFormData={setFormData}
          setItemModifiedCount={setItemModifiedCount}
        />
      )}
    </>
  );
};

export default SSOManageFormContent;
