import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { classNames } from "src/helpers/classNames";

const PasswordValidationItem = ({ isWaiting = true, condition, title }) => (
  <div className="flex items-center mt-2 ml-3">
    {isWaiting ? <XMarkIcon className="text-gray-400 w-5 h-5 mr-1" /> : condition ? <CheckIcon className="text-green-500 w-5 h-5 mr-1" /> : <XMarkIcon className="text-red-500 w-5 h-5 mr-1" />}

    <span className={classNames("text-[14px], font-normal", isWaiting ? "text-gray-400" : condition ? "text-green-500" : "text-red-500")}>{title}</span>
  </div>
);

const ResetPasswordForm = ({ password = "", setPassword = () => {}, confirmPassword = "", setConfirmPassword = () => {} }) => {
  return (
    <>
      <div className="grid gap-y-4 mb-4">
        <div className="w-full">
          <Input
            value={password}
            label={"Password *"}
            type={"password"}
            name={"password"}
            onChange={setPassword}
          />
          <PasswordValidationItem
            isWaiting={password === ""}
            condition={password.length >= 8}
            title={"At least 8 characters"}
          />
          <PasswordValidationItem
            isWaiting={password === ""}
            condition={/[a-z]/.test(password)}
            title={"Contains one lowercase letter"}
          />
          <PasswordValidationItem
            isWaiting={password === ""}
            condition={/[A-Z]/.test(password)}
            title={"Contains one uppercase letter"}
          />
          <PasswordValidationItem
            isWaiting={password === ""}
            condition={/[0-9]/.test(password)}
            title={"Contains one number"}
          />
          <PasswordValidationItem
            isWaiting={password === ""}
            condition={/[^\w\s]/.test(password)}
            title={"Contains one special character (@, #, $, %, etc.)"}
          />
        </div>
        <div className="w-full">
          <Input
            value={confirmPassword}
            label={"Confirm Password *"}
            type={"password"}
            name={"confirm-password"}
            onChange={setConfirmPassword}
          />
          <PasswordValidationItem
            isWaiting={confirmPassword === ""}
            condition={password === confirmPassword}
            title={"Passwords match"}
          />
        </div>
      </div>
    </>
  );
};

export default ResetPasswordForm;
