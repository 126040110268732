import { HomeIcon, PhotoIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import SiteColorItem from "src/components/Site/SiteColorItem";
import { baseUrl, noFavicon, noLogo, publicImageStorage } from "src/config/host";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
// import { convertToBase64 } from "src/helpers/image";

const StylesPreview = ({ workspaceDetails, layoutType = "LEFT_NAVIGATION", menu, styles = {}, handleBrowserTabText = null, onChange = () => {}, setWorkspace = () => {}, ...props }) => {
  const allowedExts = ["jpg", "jpeg", "png", "bmp", "gif", "svg", "webp", "avif"];
  const { id } = useParams();

  const onImageChange = (e, type) => {
    let changeType = type === "logo" ? "image_logo" : type === "background" ? "background_logo" : "image_favicon";
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        onChange({ [changeType]: e.target.files[0] });

        setWorkspace((workspace) => ({
          ...workspace,
          [changeType]: e.target.files[0],
        }));
      }
    }
  };

  const ImageHandler = ({ image = "", type = "logo", label = "Edit", labelClass = "" }) => {
    const [currentImage, setCurrentImage] = useState(null);
    const [imageUrl, setImageUrl] = useState();

    const areFilesEqual = (file1, file2) => {
      // Check if both files have the same name, size, and type
      if (!(file1 instanceof File) || !(file2 instanceof File)) return false;

      return file1.name === file2.name && file1.size === file2.size && file1.type === file2.type && file1.lastModified === file2.lastModified;
    };

    const imageSource = useMemo(() => {
      if (image instanceof File && areFilesEqual(image, currentImage)) {
        return imageUrl;
      }

      let objUrl = "";

      if (image instanceof File) {
        objUrl = URL.createObjectURL(image);
        setImageUrl(objUrl);
        setCurrentImage(image);
      }

      return image instanceof File ? objUrl : image?.includes(publicImageStorage) ? image + "?" + Date.now() : image?.includes("data:image/") ? image : baseUrl + image;
    }, [image, currentImage, imageUrl]);

    const imageComponent = useCallback(() => {
      return (
        <>
          {styles._id && (
            <img
              src={imageSource}
              alt="Logo"
              className="h-full w-full rounded-lg object-contain"
              onError={(e) => (e.target.src = `${type === "logo" || type === "background" ? noLogo : noFavicon}`)}
            />
          )}
          {authorizeUserComponentAccess(props.user, styles._id, "layout", ["update"]) && (
            <>
              <label
                htmlFor={`change_logo_${type}`}
                className="absolute left-0 top-0 z-40 h-full w-full cursor-pointer">
                <div className={classNames("absolute bottom-0 left-0 flex h-10 w-full cursor-pointer items-center justify-center gap-x-2 rounded-b bg-gray-700/60 px-2 py-1 text-lg text-white transition-all duration-200 group-hover:bg-highlightColor", labelClass)}>
                  <PhotoIcon className="h-6 w-6 stroke-2" />
                  {label}
                </div>
              </label>
              <input
                type="file"
                id={`change_logo_${type}`}
                name={`change_logo_${type}`}
                className="hidden"
                onChange={(e) => onImageChange(e, type)}
              />
            </>
          )}
          {imageSource && authorizeUserComponentAccess(props.user, id, "layout", ["update"]) && (
            <div
              className="absolute -right-3 -top-3 z-40 hidden h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-200 text-center transition-all hover:bg-gray-300 group-hover:flex"
              onClick={() => {
                onChange({ [type === "background" ? "background_logo" : `image_${type}`]: "" });
                setWorkspace((workspace) => ({ ...workspace, image_logo: null }));
              }}>
              <XMarkIcon className="h-5 w-5 text-gray-600" />
            </div>
          )}
        </>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageSource, type, props.user, styles._id]);

    return imageComponent();
  };

  return (
    <div className="relative mt-9 flex w-full flex-wrap rounded-lg border border-gray-300">
      <div className="absolute -top-9 left-4 z-20 flex h-9 items-center justify-between rounded-t-lg border-[1px] border-gray-200 bg-gray-400/20 px-3">
        <div className="flex items-center gap-x-3">
          {useCallback(
            () =>
              !styles.image_favicon ? (
                <></>
              ) : (
                <img
                  className="h-7 w-7"
                  src={
                    styles.image_favicon instanceof File
                      ? URL.createObjectURL(styles.image_favicon)
                      : styles.image_favicon?.includes(publicImageStorage)
                        ? styles.image_favicon + "?" + Date.now()
                        : styles.image_favicon?.includes("data:image/")
                          ? styles.image_favicon
                          : baseUrl + styles.image_favicon
                  }
                  onError={(e) => (e.target.src = `${noFavicon}`)}
                  alt="logo"
                />
              ),
            [styles.image_favicon],
          )()}

          <div className="w-[120px] max-w-[120px]">{handleBrowserTabText ? handleBrowserTabText : <p className="mr-4 w-[120px] max-w-[100px] truncate text-center text-gray-600">{styles.name}</p>}</div>
        </div>
        <div className="group absolute -right-28 z-30 transition-all duration-150">
          <div className={`group relative h-20 w-20 cursor-pointer rounded-md border bg-white/10 hover:border-highlightColor`}>
            {useCallback(
              () => (
                <>
                  {props.user?._id ? (
                    <ImageHandler
                      image={styles.image_favicon}
                      type="favicon"
                    />
                  ) : (
                    <></>
                  )}
                </>
              ),
              [styles.image_favicon, props.user?._id],
            )()}
          </div>

          <div className="absolute -left-[190px] top-3 z-30 h-4 w-1 bg-gray-200 transition-all duration-200 group-hover:bg-highlightColor" />
          <div className="absolute -left-[190px] top-3 z-30 h-1 w-[190px] bg-gray-200 transition-all duration-200 group-hover:bg-highlightColor" />
          <div className="absolute -left-2 top-2 z-30 h-4 w-4 rounded-full border-[3px] border-gray-200 bg-white transition-all duration-200 group-hover:border-highlightColor" />
          <div className="absolute -left-[194px] top-5 z-30 h-4 w-4 rounded-full border-[3px] border-gray-200 bg-white transition-all duration-200 group-hover:border-highlightColor" />
        </div>
      </div>
      <div
        style={{ backgroundColor: styles.top_bar_color }}
        className="relative flex h-[76px] w-full items-center justify-between rounded-t-lg px-6">
        <div className={`group relative h-20 w-40 cursor-pointer rounded-md border bg-white/10 hover:border-highlightColor`}>
          {useCallback(
            () => (
              <>
                {props.user?._id ? (
                  <ImageHandler
                    image={styles.image_logo}
                    type="logo"
                    layout="rectangle"
                  />
                ) : (
                  <></>
                )}
              </>
            ),
            [styles.image_logo, props.user?._id],
          )()}
        </div>

        <div className="flex items-center gap-x-2">
          <div
            style={{ backgroundColor: styles.top_bar_text_color }}
            className="h-4 w-24 rounded"
          />
          <div className="h-6 w-6 rounded-full bg-gray-100" />
        </div>
        <div
          className={classNames(
            "absolute z-10 hidden w-[160px] flex-col rounded-xl border border-gray-100 bg-white px-2 py-2 drop-shadow-md before:absolute before:-top-2 before:left-4 before:-z-10 before:h-4 before:w-4 before:rotate-[45deg] before:border before:border-b-0 before:border-r-0 before:border-gray-100 before:bg-white before:content-[''] sm:flex ",
            layoutType === "TOP_NAVIGATION" && "right-56 top-10",
            layoutType === "LEFT_NAVIGATION" && "right-56 top-10",
            layoutType === "NO_NAVIGATION" && "right-56 top-10",
          )}>
          <SiteColorItem
            onChange={(value) => onChange({ top_bar_color: value })}
            color={styles.top_bar_color}
            name="Header Background"
          />
        </div>
        <div className="absolute -top-11 right-14 z-10 hidden w-[160px] flex-col rounded-xl border border-gray-100 bg-white px-2 py-2 drop-shadow-md before:absolute before:-bottom-2 before:right-4 before:-z-10 before:h-4 before:w-4 before:rotate-[45deg] before:border before:border-l-0 before:border-t-0 before:border-gray-100 before:bg-white before:content-[''] sm:flex">
          <SiteColorItem
            onChange={(value) => onChange({ top_bar_text_color: value })}
            color={styles.top_bar_text_color}
            name="Header text"
          />
        </div>
      </div>

      <div
        style={{ backgroundColor: styles.side_bar_color }}
        className={"relative p-4 " + (layoutType === "LEFT_NAVIGATION" ? "w-5/12 space-y-4 rounded-bl-lg sm:w-3/12" : "flex w-full space-x-4")}>
        {layoutType === "NO_NAVIGATION" ? (
          <div
            style={{ color: styles.side_bar_color }}
            className="flex h-5 items-center rounded px-3 py-4 font-medium">
            <HomeIcon
              className="h-6 w-6"
              style={{ color: styles.side_bar_text_color }}
            />
          </div>
        ) : (
          <>
            <div
              style={{ color: styles.left_nav_active_text_color, backgroundColor: styles.side_bar_shade_color }}
              className="flex h-5 items-center rounded px-3 py-4 font-medium">
              Pages
            </div>
            {menu.map((mnu) => {
              return (
                <div
                  key={mnu}
                  style={{ color: styles.side_bar_text_color }}
                  className="flex h-5 items-center rounded px-3 py-4 font-medium">
                  {mnu}
                </div>
              );
            })}
          </>
        )}

        {layoutType !== "NO_NAVIGATION" && (
          <div
            className={
              "absolute z-30 flex w-[160px] flex-col gap-y-4 rounded-xl border border-gray-100 bg-white px-2 py-2 drop-shadow-md before:absolute before:-top-2 before:left-4 before:-z-10 before:h-4 before:w-4 before:rotate-[45deg] before:border before:border-b-0 before:border-r-0 before:border-gray-100 before:bg-white before:content-[''] " +
              (layoutType === "TOP_NAVIGATION" ? "left-0 top-14" : "left-2 top-10 sm:left-14 sm:top-6")
            }>
            <SiteColorItem
              onChange={(value) => onChange({ side_bar_shade_color: value })}
              color={styles.side_bar_shade_color}
              name="Navigation Active"
            />
            <SiteColorItem
              onChange={(value) => onChange({ left_nav_active_text_color: value })}
              color={styles.left_nav_active_text_color}
              name="Navigation Active Text"
            />
          </div>
        )}
        <div
          className={classNames(
            "absolute z-10 flex w-[160px] flex-col rounded-xl border border-gray-100 bg-white px-2 py-2 drop-shadow-md before:absolute before:-top-2 before:left-4 before:-z-10 before:h-4 before:w-4 before:rotate-[45deg] before:border before:border-b-0 before:border-r-0 before:border-gray-100 before:bg-white before:content-[''] ",
            layoutType === "TOP_NAVIGATION" && "right-2 top-32 sm:right-2 sm:top-14",
            layoutType === "LEFT_NAVIGATION" && "left-2 top-48 sm:left-6 sm:top-[240px]",
            layoutType === "NO_NAVIGATION" && "left-0 top-14",
          )}>
          <SiteColorItem
            onChange={(value) => onChange({ side_bar_text_color: value })}
            color={styles.side_bar_text_color}
            name="Navigation text"
          />
          <SiteColorItem
            onChange={(value) => onChange({ side_bar_color: value })}
            color={styles.side_bar_color}
            name="Navigation background"
          />
        </div>
      </div>

      <div
        style={{ backgroundColor: styles.background_color }}
        className={"relative rounded-br-lg  bg-gray-50 " + (layoutType === "NO_NAVIGATION" || layoutType === "TOP_NAVIGATION" ? "w-full" : "w-7/12 sm:w-9/12")}>
        <div
          className={classNames(`relative mx-auto w-full space-y-4 p-4 transition-all duration-300`)}
          style={{
            maxWidth: styles?.max_width || "100%",
          }}>
          <div
            className={classNames(
              "absolute z-20 flex w-[160px] flex-col gap-y-2 rounded-xl border border-gray-100 bg-white px-2 py-2 drop-shadow-md before:absolute before:-top-2 before:right-10 before:-z-10 before:h-4 before:w-4 before:rotate-[45deg] before:border before:border-b-0 before:border-r-0 before:border-gray-100 before:bg-white before:content-[''] before:sm:left-4",
              layoutType === "TOP_NAVIGATION" && "left-2 top-60 sm:left-32",
              layoutType === "LEFT_NAVIGATION" && "bottom-40 right-2 top-auto sm:bottom-auto sm:left-32 sm:top-14",
              layoutType === "NO_NAVIGATION" && "left-32 top-60",
            )}>
            <SiteColorItem
              onChange={(value) => onChange({ highlight_color: value })}
              color={styles.highlight_color}
              name="Highlight"
            />
            <SiteColorItem
              onChange={(value) => onChange({ background_color: value })}
              color={styles.background_color}
              name="Page background"
            />
          </div>
          <div
            className={classNames(
              "absolute z-20 flex w-[160px] flex-col gap-y-4 rounded-xl border border-gray-100 bg-white px-2 py-2 drop-shadow-md before:absolute before:-top-2 before:-z-10 before:h-4 before:w-4 before:rotate-[45deg] before:border before:border-b-0 before:border-r-0 before:border-gray-100 before:bg-white before:content-['']",
              layoutType === "TOP_NAVIGATION" && "-right-4 left-auto top-[240px] before:left-auto before:right-4 2xl:-right-36 2xl:before:left-4 2xl:before:right-auto",
              layoutType === "LEFT_NAVIGATION" && "bottom-2 left-auto right-2 before:left-auto before:right-4 sm:-right-4 sm:bottom-auto sm:top-[240px] 2xl:-right-36 2xl:before:left-4 2xl:before:right-auto",
              layoutType === "NO_NAVIGATION" && "-right-4 left-auto top-[240px] before:left-auto before:right-4 2xl:-right-36 2xl:before:left-4 2xl:before:right-auto",
            )}>
            <SiteColorItem
              onChange={(value) => {
                onChange({ max_width: value });
              }}
              value={styles.max_width}
              type="input"
              name="Max Width"
              generalInputDisabled={workspaceDetails?.workspace_type === "JWT_FULL_EMBED"}
              dropdownDisabled={workspaceDetails?.workspace_type === "JWT_FULL_EMBED"}
            />
          </div>
          <div
            style={{ backgroundColor: styles.highlight_color }}
            className={"h-4 rounded " + (layoutType !== "TOP_NAVIGATION" ? "w-48" : "bg-gray-400")}></div>
          <div className="h-4 rounded bg-gray-400"></div>
          <div className="h-4 rounded bg-gray-400"></div>
          <div className="h-4 w-48 rounded bg-gray-400"></div>
          <div className="h-12"></div>
          <div
            style={{ backgroundColor: styles.highlight_color }}
            className={"h-4 rounded " + (layoutType === "TOP_NAVIGATION" ? "w-48" : "bg-gray-400")}></div>
          <div className="h-4 rounded bg-gray-400"></div>
          <div className="h-4 w-48 rounded bg-gray-400"></div>
          <div className="h-40 sm:h-20"></div>
          <div className="flex justify-end">
            <div className={`group relative h-32 w-44 cursor-pointer rounded-md border bg-white/10 hover:border-highlightColor`}>
              {useCallback(
                () => (
                  <>
                    {props.user?._id ? (
                      <ImageHandler
                        image={styles.background_logo}
                        type="background"
                        layout="rectangle"
                        label="Login page background"
                        labelClass={"!text-sm !leading-none"}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ),
                [styles.background_logo, props.user?._id],
              )()}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "absolute z-30 flex w-[160px] flex-col gap-y-4 rounded-xl border border-gray-100 bg-white px-2 py-2 drop-shadow-md before:absolute before:-top-2 before:right-10 before:-z-10 before:h-4 before:w-4 before:rotate-[45deg] before:border before:border-b-0 before:border-r-0 before:border-gray-100 before:bg-white before:content-[''] sm:hidden before:sm:left-4",
          layoutType === "TOP_NAVIGATION" && "right-4 top-16 sm:bottom-4 sm:right-4 sm:top-auto",
          layoutType === "LEFT_NAVIGATION" && "right-4 top-16 sm:bottom-4",
          layoutType === "NO_NAVIGATION" && "bottom-4 right-4",
        )}>
        <SiteColorItem
          onChange={(value) => onChange({ top_bar_text_color: value })}
          color={styles.top_bar_text_color}
          name="Header text"
        />
        <SiteColorItem
          onChange={(value) => onChange({ top_bar_color: value })}
          color={styles.top_bar_color}
          name="Header Background"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(StylesPreview);
