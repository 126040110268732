import Input from "src/components/Shared/Forms/Inputs/Input";
import { useEffect, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
// import { Menu } from "@headlessui/react";

const AddWhereContent = ({ data = null, onClick = () => {} }) => {
  const [dataset, setDataset] = useState("");
  const [datasetList, setDatasetList] = useState([]);

  const handleDataset = async (e) => {
    if ((e.code === "Enter" || e.code === "Comma" || e.submit === true) && dataset.trim()) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setDatasetList(data?.datasource_id?.trim() ? data?.datasource_id?.split(",") : []);
    setDataset("");
  }, [data]);

  return (
    <div className="w-full space-y-4">
      <div className="mb-4 space-y-4">
        <div className="w-full">
          <Input
            type="text"
            autoComplete="off"
            label="Add datasets"
            inline={true}
            value={dataset}
            onChange={(e) => setDataset(e.target.value)}
            onKeyDown={handleDataset}
          />
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          version="default"
          className="!h-8 rounded bg-highlightColor px-2 text-sm text-white"
          onClick={(e) => {
            if (dataset && datasetList.findIndex((tg) => tg.toLowerCase() === dataset.toLowerCase()) === -1) {
              const datasets = [...datasetList, dataset];
              setDatasetList(datasets);
              setDataset("");
              onClick({ datasource_id: datasets?.toString() });
            }
          }}>
          Add
        </Button>
      </div>
    </div>
  );
};

export default AddWhereContent;
