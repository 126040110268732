import React, { useEffect } from "react";
import MetabaseEmbedProgrammaticFiltering from "./MetabaseEmbedProgrammaticFiltering";
import MetabaseEmbedPlatform from "./MetabaseEmbedPlatform";

export default function MetabaseRouter({ content, config, setError }) {
  useEffect(() => {
    if (!content?.config?.provider) {
      setError("Provider type does not exist.");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.config?.provider]);

  return (
    <>
      {config?.type === "IFRAME_EMBED" && <MetabaseEmbedProgrammaticFiltering content={content} />}
      {config?.type === "JWT_FULL_EMBED" && <MetabaseEmbedPlatform content={content} />}
    </>
  );
}
