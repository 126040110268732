import { BeakerIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import FormContainer from "src/components/Shared/Containers/FormContainer";
import Input from "src/components/Shared/Forms/Inputs/Input";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import ProfileImage from "src/components/Users/Edit/ViewProfile/ProfileImage";
import WorkspaceSubscriberInstance from "src/components/Workspaces/WorkspaceSubscriberInstance";
import { classNames } from "src/helpers/classNames";

const AddUserForm = ({ index, state, setState, isAdmin, groups, workspaces = [], setWorkspaces = () => {}, disabled = false, customFields = [], setCustomFields = () => {}, ...props }) => {
  const [groupChangeKey, setGroupChangeKey] = useState(new Date().getTime());
  const [selectedGroups, setSelectedGroups] = useState([]);

  const removeForm = (e) => {
    e.preventDefault();

    delete state[index];
    setState(state);

    const availableForms = props.formCount.filter((count) => count !== index);
    props.setFormCount(availableForms);
  };

  const getValue = (key) => (state[index] ? state[index][key] : "");

  const updateState = (value, key) => {
    setState({
      ...state,
      [index]: {
        ...state[index],
        [key]: value,
      },
    });
  };

  const updateCustomFields = (index, value) => {
    setCustomFields((fields) =>
      fields.map((field, i) =>
        field?._id?.toString() !== index?.toString()
          ? field
          : {
              ...field,
              value,
            },
      ),
    );
  };

  useEffect(() => {
    setState({
      ...state,
      [index]: {
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        image: "",
        type: isAdmin ? "admin" : "user",
        notifications: false,
        update: true,
        disabled_sso_auto_updated: false,
        disabled_dataset_auto_updated: false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let updatedGroups = groups.filter((group) => selectedGroups.includes(group._id || group.id));
    if (state[index]) {
      setState({
        ...state,
        [index]: {
          ...state[index],
          groups: updatedGroups,
        },
      });
    } else {
      setState({
        ...state,
        [index]: {
          ...state[index],
          name: "",
          email: "",
          password: "",
          confirm_password: "",
          image: "",
          type: isAdmin ? "admin" : "user",
          notifications: false,
          update: true,
          disabled_sso_auto_updated: false,
          disabled_dataset_auto_updated: false,
          groups: updatedGroups,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroups]);

  const handleSelectedGroups = (groups, singleGroup) => {
    let filteredGroups = groups.filter((group) => group.selected);
    setSelectedGroups(filteredGroups.map((group) => group.key));
  };

  useEffect(() => {
    if (state[index]?.update) {
      setState({
        ...state,
        [index]: {
          ...state[index],
          password: "",
          confirm_password: "",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[index]?.update]);

  useEffect(() => {
    setGroupChangeKey(new Date().getTime());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces.filter((item) => item.selected).length]);

  return (
    <FormContainer>
      {index !== 0 && (
        <Button
          type="button"
          version="default"
          className="absolute right-4 top-4 rounded-md bg-white text-red-400 hover:text-red-500 focus:outline-none"
          onClick={removeForm}>
          <span className="sr-only">Close</span>
          <XMarkIcon
            className="h-6 w-6"
            aria-hidden="true"
          />
        </Button>
      )}

      <div className="flex w-full flex-wrap gap-y-4">
        {/* Photo */}
        <div className="w-full min-w-[200px] px-2">
          <ProfileImage
            user={state[index]}
            image={state[index]?.image}
            setImage={(value) => updateState(value, "image")}
          />
        </div>

        {/* Name */}
        <div className="w-full px-2 sm:w-1/2">
          <Input
            autocomplete="off"
            name="full-name"
            label="Full name"
            inline={true}
            type="text"
            value={getValue("name")}
            onChange={(e) => updateState(e.target.value, "name")}
          />
        </div>

        {/* Email */}
        <div className="w-full px-2 sm:w-1/2">
          <Input
            name="email"
            label="Email"
            inline={true}
            value={getValue("email")}
            onChange={(e) => updateState(e.target.value, "email")}
          />
        </div>

        

        {customFields.length > 0 &&
          customFields
            .filter((field) => field.is_required)
            .map((field, i) => (
              <div
                className="w-full px-2 sm:w-1/2"
                key={field._id}>
                <Input
                  inline={true}
                  name={field.label}
                  label={field.label}
                  value={field.value || ""}
                  onChange={(e) => updateCustomFields(field._id, e.target.value)}
                />
              </div>
            ))}

        
          {customFields?.filter((field) => !field.is_required)?.length > 0 && (
            <div className="w-full px-2 mb-4">
                <div className="text-base text-gray-400 mb-2">* Optional attributes</div>
                <div className="relative grid w-full gap-4 md:grid-cols-2">
                  {customFields
                    .filter((field) => !field.is_required)
                    .map((field) => (
                      <div
                        className="relative w-full"
                        key={field._id}>
                        <Input
                          inline={true}
                          name={field.label}
                          label={field.label}
                          value={field.value || ""}
                          onChange={(e) => updateCustomFields(field._id, e.target.value)}
                        />
                      </div>
                    ))}
                </div>
            </div>
          )}
        
        <div className="w-full px-2">
          <div className="text-base text-gray-400 -mb-2">Assign content</div>
        </div>
        <div className="w-full px-2 sm:w-1/2">
          {/* Workspaces */}
          {workspaces && (
            <MultiSelectObject
              inline={true}
              defaultOptions={workspaces.map((workspace) => ({
                key: workspace._id,
                value: workspace.name,
                selected: workspace.selected || false,
                object: workspace,
              }))}
              searchComponent={(object) =>
              {
                return (
                  <div className="relative flex items-center justify-center gap-x-2">
                    <div>{object.name}</div>
                    {object?.workspace_type === "JWT_FULL_EMBED" && <WorkspaceSubscriberInstance />}
                  </div>
                );
              }}
              bubbleComponent={(object) =>
              {
                return (
                  <div className="relative flex items-center gap-x-2 px-1">
                    <div className="leading-4">{object.name}</div>
                    {object?.workspace_type === "JWT_FULL_EMBED" && <BeakerIcon className="h-4 w-4" />}
                  </div>
                );
              }}
              searchableFields={["name"]}
              title="Assign Workspaces"
              onChange={setWorkspaces}
            />
          )}
        </div>

        <div className="w-full px-2 sm:w-1/2">
          {/* Groups */}
          {groups && (
            <MultiSelectObject
              inline={true}
              key={groupChangeKey}
              defaultOptions={groups
                .filter((group) =>
                  workspaces
                    .filter((item) => item.selected)
                    .map((item) => item.key)
                    .includes(group.workspace_id._id),
                )
                .map((group) =>
                {
                  return {
                    key: group._id || group.id,
                    value: group.name,
                    selected: selectedGroups.includes(group._id || group.id),
                    // group.selected || false,
                    object: group,
                  };
                })}
              groupedByField="workspace_id.name"
              // uniqueIdentifier="workspace_id._id"
              searchableFields={["name"]}
              title="Assign groups"
              onChange={(groups, group) => handleSelectedGroups(groups, group)}
            />
          )}
        </div>

        <div className="w-full">
          <EditContainer
            title="Welcome email settings"
            subtitle="Default settings will send out an email on user creation."
            defaultOptions={{
              onCancelButtonVisible: false,
              onSuccessButtonVisible: false,
            }}>
            {typeof getValue("update") === "boolean" && (
              <div className="grid max-h-[200px] gap-y-3 opacity-100 transition-all duration-150">
                <ToggleHeader
                  title="Send welcome email on setup"
                  subtitle="Disabling this will allow you to manually enter a password"
                  position="left">
                  <Toggle
                    checked={getValue("update")}
                    onChange={(e) => {
                      updateState(!getValue("update"), "update");
                    }}
                  />
                </ToggleHeader>
              </div>
            )}
            <div className={classNames("grid gap-y-3 transition-all duration-150", getValue("update") ? "mt-2 max-h-0 overflow-hidden opacity-0" : "mt-6 max-h-[200px] opacity-100")}>
              <p className="p-0 text-sm text-gray-300">
                Please enter a <span className="px-[2px] font-semibold text-gray-400">temporary</span> password that should be reset upon logging in.
              </p>
              <div className="flex w-full gap-x-4 gap-y-2">
                {/* Password */}
                <div className="w-full">
                  <Input
                    autoComplete="new-password"
                    type="password"
                    name="password"
                    label="Password"
                    value={getValue("password")}
                    onChange={(e) => updateState(e.target.value, "password")}
                    inline={true}
                  />
                </div>

                {/* Confirm Password */}
                <div className="w-full">
                  <Input
                    autoComplete="new-password"
                    type="password"
                    name="confirm-password"
                    label="Confirm password"
                    inline={true}
                    value={getValue("confirm_password")}
                    onChange={(e) => updateState(e.target.value, "confirm_password")}
                  />
                </div>
              </div>
            </div>
          </EditContainer>
          <EditContainer
            title="Data sync settings"
            subtitle="Control both SSO and dataset sync"
            defaultOptions={{
              onCancelButtonVisible: false,
              onSuccessButtonVisible: false,
            }}>
            <div className="grid max-h-[200px] gap-y-3 opacity-100 transition-all duration-150">
              <ToggleHeader
                title="SSO auto update"
                subtitle="Allow user's data auto update while login with SSO"
                position="left">
                <Toggle
                  checked={!getValue("disabled_sso_auto_updated")}
                  onChange={(e) => {
                    updateState(!getValue("disabled_sso_auto_updated"), "disabled_sso_auto_updated");
                  }}
                />
              </ToggleHeader>
            </div>
            <div className="mt-6 grid max-h-[200px] gap-y-3 opacity-100 transition-all duration-150">
              <ToggleHeader
                title="Dataset & bulk upload auto update"
                subtitle="Allow user's data auto update while import from Dataset and bulk upload"
                position="left">
                <Toggle
                  checked={!getValue("disabled_dataset_auto_updated")}
                  onChange={() => {
                    updateState(!getValue("disabled_dataset_auto_updated"), "disabled_dataset_auto_updated");
                  }}
                />
              </ToggleHeader>
            </div>
          </EditContainer>
        </div>
      </div>
    </FormContainer>
  );
};

export default AddUserForm;
