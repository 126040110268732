import { Fragment } from "react";
import { noFavicon } from "src/config/host";
import Image from "src/components/Shared/Image";

const FormListItem = ({ forms = [], form = {}, workspaceId = "", i = 0, colSpan = 3, ...props }) => {
  return (
    <Fragment>
      {!workspaceId && form?.workspace_id?._id !== forms[i - 1]?.workspace_id?._id && (
        <tr>
          <td
            colSpan={colSpan}
            className="whitespace-pre-wrap break-all bg-gray-100 px-4 py-4 text-sm font-semibold text-gray-500 sm:py-3 sm:pl-6">
            <div className="flex items-center gap-4">
              <div
                style={{ backgroundColor: form?.workspace_id?.top_bar_color }}
                className="h-10 w-10 overflow-hidden rounded">
                <Image
                  image={form?.workspace_id?.square_logo || form?.workspace_id?.image_favicon || form?.workspace_id?.image_logo}
                  failImage={noFavicon}
                  alt={"Form list item"}
                />
              </div>
              {form?.workspace_id?.name}
            </div>
          </td>
        </tr>
      )}
      {props.children}
    </Fragment>
  );
};

export default FormListItem;
