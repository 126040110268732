import { apiRequest } from "src/async/apiUtils";

export const getPortalWorkspaceList = async (body) => {
  try {
    let params = { user_id: body.user_id };
    const { data } = await apiRequest("GET", `/portal/user/:user_id/workspaces`, { params });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPortalWorkspacesFirstPage = async (body) => {
  try {
    let params = { user_id: body.user_id };
    const { data } = await apiRequest("GET", "/portal/user/:user_id/workspaces/page", { params });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPortalWorkspaceFirstPage = async (body) => {
  try {
    let params = { user_id: body.user_id, workspace_id: body.workspace_id };
    const { data } = await apiRequest("GET", "/portal/user/:user_id/workspaces/:workspace_id/page", { params });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPortalWorkspacesPageMenu = async (body) => {
  try {
    let params = { user_id: body.user_id, workspace_id: body.workspace_id };
    const { data } = await apiRequest("GET", "/portal/user/:user_id/workspaces/:workspace_id/menus", { params });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPageToRender = async (body) => {
  try {
    let params = { user_id: body.user_id, workspace_id: body.workspace_id };
    const { data } = await apiRequest("GET", "/portal/user/:user_id/workspaces/:workspace_id/embed", { params, queries: body?.queries });
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};
