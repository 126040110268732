import React, { useEffect, useState } from "react";
import Section from "src/components/Shared/Containers/Section";
import { H3 } from "src/components/Shared/Text/Headers";
import Button from "src/components/Shared/Buttons/Button";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import useFetch from "src/hooks/useFetch";
import SiteEmailManageModal from "./SiteEmailModal/SiteEmailManageModal";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import NoData from "src/components/Shared/NoData/NoData";

const EmailTemplateSettings = () => {
  const [templateData, setTemplateData] = useState({});
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  const {
    response: { data: emailTemplates = [], meta },
    status: { done: listLoaded, fail: listLoadedFail },
    refreshData: refreshTemplateSite,
  } = useFetch("/site-settings/email-template", { query: { limit, offset } });

  useEffect(() => {
    refreshTemplateSite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset]);

  return (
    <Section className="grid gap-y-8">
      <H3 caption="Customize your email templates so your clients recieve copy and branding that matches your company.">Email Templates</H3>
      <div>
        <div className="grid gap-y-2">
          {listLoaded || listLoadedFail ? (
            Array.isArray(emailTemplates) && emailTemplates.length > 0 ? (
              emailTemplates.map((item) => (
                <div
                  key={item._id}
                  className="flex items-center justify-between rounded border px-3 py-1.5 pl-5">
                  <div className="flex flex-col">
                    <div className="py-1">
                      <span className="text-gray-500">Subject:</span> {item.subject}
                    </div>
                    <div className="py-1 text-xs text-gray-300">{item.code}</div>
                  </div>

                  <Button
                    version="default"
                    className="rounded-md px-2 py-1 text-highlightColor"
                    onClick={() => {
                      setTemplateData(item);
                      setOpenTemplateModal(true);
                    }}>
                    Edit
                  </Button>
                </div>
              ))
            ) : (
              <NoData />
            )
          ) : (
            <Preloader />
          )}
        </div>
        <PaginationFooter
          itemName="Email"
          limit={limit}
          offset={offset}
          count={meta?.count}
          onChange={handlePaginationChange}
        />
      </div>

      <SiteEmailManageModal
        data={templateData}
        isOpen={openTemplateModal}
        onCancel={() => setOpenTemplateModal(false)}
        onSuccess={() => {
          setOpenTemplateModal(false);
          refreshTemplateSite();
        }}
      />
    </Section>
  );
};

export default EmailTemplateSettings;
