import { PhotoIcon } from "@heroicons/react/24/outline";
import React from "react";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import { fileSizes, fileTypes } from "src/helpers/formHelper";

const FormFileItem = ({ section = null, content = null, from = "form", answer = null, onHandleChange = () => {} }) => {
  const handleSelectedFileTypes = (typesArray) => {
    let updatedSelectedFileTypes = typesArray.reduce((types, type) => (type.selected ? [...types, type.key] : types), []);
    onHandleChange({ fields: [{ field: "file_types", value: updatedSelectedFileTypes }] });
  };

  return (
    <>
      {from === "response" ? (
        <div className="relative mt-2 space-y-4">
          {answer?.value && answer?.value?.length > 0 && (
            <div className="item-center flex flex-wrap gap-2">
              {answer?.value?.map((val, index) => (
                <Button
                  key={`${val?._id}`}
                  version="default"
                  onClick={() => window.open(val?.url, "_blank")}
                  className="relative flex items-center gap-2 rounded-md border border-gray-200 px-2 py-2 transition-all duration-200 hover:border-gray-300 hover:bg-gray-50 hover:underline">
                  <PhotoIcon className="h-5 w-5 stroke-[0.8]" />
                  <span className="text-sm font-medium text-gray-700">{val?.name}</span>
                </Button>
              ))}
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="col-span-1 grid grid-cols-2 gap-4">
            <div className="relative">
              <MultiSelectObject
                inline={true}
                defaultOptions={fileTypes?.map((fileType) => ({ ...fileType, selected: content?.file_types?.includes(fileType?.key) }))}
                title="Allow file types"
                onChange={handleSelectedFileTypes}
                searchable={false}
              />
            </div>
            <div className="relative">
              <Input
                inline={true}
                type={"number"}
                label={"Maximum number of files"}
                wrapperClass={"!bg-white"}
                value={content?.file_number}
                onChange={(e) => onHandleChange({ fields: [{ field: "file_number", value: e.target.value }] })}
              />
            </div>
            <div className="relative">
              <SelectMenu
                inline={true}
                label={"Maximum file size"}
                options={fileSizes}
                dropdownClass={"w-full"}
                startIndex={fileSizes?.findIndex((type) => type?.value === content?.file_size)}
                setOption={(option) => {
                  onHandleChange({ fields: [{ field: "file_size", value: option.value }] });
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FormFileItem;
