import { CheckIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "src/helpers/classNames";

const FormChoiceGrid = ({ content = null, answer = [] }) => {
  return (
    <div className="relative space-y-4">
      <div className="relative flex w-full">
        <div className="w-full max-w-[calc(100vw-32rem)] overflow-auto">
          <div className="relative flex">
            <div className="sticky left-0 z-[1] w-24 min-w-24 flex-grow bg-white px-2 py-4"></div>
            {content?.options?.map((option) => (
              <div
                key={option?._id}
                className="flex w-full min-w-24 flex-grow items-center justify-center px-2 py-4 text-sm font-semibold text-gray-700">
                {option?.name}
              </div>
            ))}
          </div>
          {content?.rows?.map((row) => (
            <div
              key={row?._id}
              className="relative flex">
              <div className="sticky left-0 z-[1] flex w-24 min-w-24 flex-grow items-center bg-white px-2 py-4 text-sm font-semibold text-gray-700">{row?.name}</div>
              {content?.options?.map((option) => (
                <div
                  key={option?._id}
                  className="flex w-full min-w-24 flex-grow items-center justify-center px-2 py-4">
                  <div
                    className={classNames(
                      "aspect-square w-5 flex-shrink-0 border-2 border-gray-300",
                      content?.question_type === "radio_grid" ? "rounded-full" : "rounded",
                      ((content?.question_type === "checkbox_grid" &&
                        answer?.value
                          ?.find((ans) => ans?.row_id?.toString() === row?._id?.toString())
                          ?.option_id?.map((option) => option?.toString())
                          ?.includes(option?._id?.toString())) ||
                        (content?.question_type === "radio_grid" && answer?.value?.find((ans) => ans?.row_id?.toString() === row?._id?.toString())?.option_id?.toString() === option?._id?.toString())) &&
                        "!border-highlightColor bg-highlightColor",
                      option.other ? "mt-0.5" : "",
                    )}>
                    {((content?.question_type === "checkbox_grid" &&
                      answer?.value
                        ?.find((ans) => ans?.row_id?.toString() === row?._id?.toString())
                        ?.option_id?.map((option) => option?.toString())
                        ?.includes(option?._id?.toString())) ||
                      (content?.question_type === "radio_grid" && answer?.value?.find((ans) => ans?.row_id?.toString() === row?._id?.toString())?.option_id?.toString() === option?._id?.toString())) && <CheckIcon className="h-4 w-4 text-white" />}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FormChoiceGrid;
