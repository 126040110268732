import { connect } from "react-redux";
import { classNames } from "src/helpers/classNames";
import useIframeResize from "src/hooks/useIframeResize";

const MetabaseEmbedProgrammaticFiltering = ({ content, workspaceDetails, site, user }) => {
  useIframeResize({ workspaceDetails, site, user });

  return (
    <>
      <div className="relative w-full">
        {content?.embedUrl && (
          <div
            className={classNames("mx-auto w-full overflow-auto", !localStorage.getItem("impersonatedUserId") ? "h-[calc(100vh-10rem)]" : workspaceDetails?.layout_type === "TOP_NAVIGATION" ? "h-[calc(100vh-4rem)] lg:h-[calc(100vh-120px)]" : "h-[calc(100vh-4rem)]")}
            style={{ maxWidth: workspaceDetails?.max_width || "100%" }}>
            <iframe
              id="iframe"
              title="Domo Embed"
              src={content?.embedUrl}
              width="100%"
              height="100%"
              marginheight="0"
              marginwidth="0"
              frameborder="0"></iframe>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(MetabaseEmbedProgrammaticFiltering);
