import { PlusIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Input from "./Inputs/Input";
import { classNames } from "src/helpers/classNames";

const InputWrapper = ({ label, value = "", onChange = () => {}, onRemove = () => {}, ...props }) => {
  const [toggleInput, setToggleInput] = useState(false);

  const handleRemoveClick = () => {
    onChange({ target: { name: props.name, value: "" } });
    setToggleInput(false);
    onRemove();
  };

  useEffect(() => {
    if (value || props.placeholder) setToggleInput(true);
  }, [value, props.placeholder]);

  return (
    <>
      {!toggleInput ? (
        <div className="relative flex">
          <Button
            onClick={() => setToggleInput(true)}
            type="button"
            version="default"
            className="flex h-8 items-center justify-center gap-2 rounded-full text-sm font-medium text-gray-400">
            <PlusIcon className="h-4 w-4 stroke-2" /> Add {label} <span className="text-gray-300">(optional)</span>
          </Button>
        </div>
      ) : (
        <div className="relative">
          {toggleInput && (
            <div className="flex justify-end">
              <Button
                type="button"
                version="default"
                className={classNames("flex items-center gap-1 py-1 text-gray-300 transition-all duration-150", props.inline ? "!h-6" : "")}
                onClick={handleRemoveClick}>
                <div className="block w-full text-sm leading-none">Remove</div>
              </Button>
            </div>
          )}
          <Input
            label={label}
            value={value}
            onChange={onChange}
            {...props}
          />
        </div>
      )}
    </>
  );
};

export default InputWrapper;
