import { CalendarDaysIcon, ClockIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "src/helpers/classNames";

const FormFieldItem = ({ type, placeholder, from = "form" }) => {
  return (
    <div className={classNames("flex items-center gap-1 border-b border-highlightColor", type === "input" ? "max-w-96" : type === "date" || type === "time" ? "max-w-60" : "")}>
      {["rich_text"]?.includes(type) && from === "response" ? <div className="rich_text w-full" dangerouslySetInnerHTML={{ __html: placeholder || "" }} /> : <div className="flex h-12 w-full items-center text-base text-gray-400">{placeholder}</div>}
      {(type === "date" || type === "time") && (
        <div className="flex flex-shrink-0 items-center">
          {type === "date" && <CalendarDaysIcon className="h-6 w-6 text-zinc-400" />}
          {type === "time" && <ClockIcon className="h-6 w-6 text-zinc-400" />}
        </div>
      )}
    </div>
  );
};

export default FormFieldItem;
