import { Bars3Icon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";

const FormModalSectionContent = ({ index = 0, section = null, totalSection = 0, innerRef, draggableProps, dragHandleProps, moveUp = () => {}, moveDown = () => {} }) => {
  const [name, setName] = useState("");

  useEffect(() => {
    let title = `Untitled Section ${index + 1}`;
    if (section?.contents?.length > 0) title = section?.contents[0]?.name || title;
    setName(title);
  }, [index, section]);
  return (
    <div
      ref={innerRef}
      {...draggableProps}
      // style={{ top: index * 76 }}
      className="group relative !left-0 overflow-hidden">
      <div className="flex cursor-pointer items-center gap-6 border-l-4 border-transparent py-4 pl-3 pr-4 transition-all duration-300 group-hover:border-highlightColor group-hover:bg-gray-50">
        <Button
          version="default"
          className="flex !h-10 !w-10 flex-shrink-0 !cursor-move items-center justify-center text-gray-400"
          dragHandleProps={dragHandleProps}>
          <Bars3Icon className="h-8 w-8" />
        </Button>
        <div className="flex-shrink flex-grow space-y-1">
          <div className="text-base font-semibold !leading-none text-highlightColor">{name}</div>
          <div className="text-sm !leading-none text-gray-300">
            Section {index + 1} of {totalSection}
          </div>
        </div>
        <div className="flex flex-shrink-0 items-center gap-2">
          <Button
            version="default"
            disabled={index === 0}
            onClick={() => moveUp(index)}
            className="flex !h-8 !w-8 items-center justify-center rounded !p-0 text-gray-300 transition-all duration-300 hover:bg-gray-100/50 hover:text-gray-400">
            <ChevronUpIcon className="h-6 w-6" />
          </Button>
          <Button
            version="default"
            disabled={index + 1 === totalSection}
            onClick={() => moveDown(index)}
            className="flex !h-8 !w-8 items-center justify-center rounded !p-0 text-gray-300 transition-all duration-300 hover:bg-gray-100/50 hover:text-gray-400">
            <ChevronDownIcon className="h-6 w-6" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormModalSectionContent;
