import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { apiRequest } from "src/async/apiUtils";
import HtmlAndRichTextEditor from "src/components/Emailer/HtmlAndRichTextEditor";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Modal from "src/components/Shared/Modal";
import { emailTemplateConfig } from "src/helpers/emailTemplateConfig";

const SiteEmailManageModal = ({ data, isOpen, onCancel = () => {}, onSuccess = () => {}, site, me }) => {
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [viewableContent, setViewableContent] = useState("");

  const [disabled, setDisabled] = useState(false);

  const updateEmailTemplate = async () => {
    try {
      setDisabled(true);

      const { data: respData } = await apiRequest(
        "PUT",
        `/site-settings/email-template/:id`,
        {
          body: {
            subject,
            content,
          },
          params: {
            id: data._id,
          },
        },
        {
          showToastMessage: true,
          onSuccess: () => {},
          onFailure: () => {},
        },
      );
      if (respData.status === 200) {
        onSuccess();
        setSubject("");
        setContent("");
      }
    } catch (error) {
    } finally {
      setTimeout(() => setDisabled(false), 300);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setSubject(data.subject);
      setContent(data.content);
    }
    return () => {
      setSubject("");
      setContent("");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.code, isOpen]);

  useEffect(() => {
    if (content) {
      const updatedContent = emailTemplateConfig({
        content,
        config: { name: me?.name, ...site, image_logo: `${site?.image_logo}?${Date.now()}` },
      });
      setViewableContent(updatedContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <Modal
      title="Email"
      size="xxxxxl"
      secondaryTitle={`Edit`}
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={updateEmailTemplate}
      defaultOptions={{
        onSuccessButtonText: "Save",
        onSuccessLoaderVisible: disabled,
        onSuccessLoaderStart: disabled,
      }}
      defaultStyles={{
        overFlowYVisible: false,
      }}>
      <div className="flex h-[400px] gap-x-4 sm:pb-6 lg:h-[600px]">
        <div className="relative h-full w-1/2 space-y-5">
          <div>
            <Input
              name="name"
              label="Subject"
              autoComplete="off"
              value={subject}
              inline={true}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          {content && (
            <HtmlAndRichTextEditor
              htmlContent={content}
              setHtmlContent={setContent}
            />
          )}
        </div>
        <div className="flex w-1/2">
          <div className="mr-5 h-full w-[1px] bg-gray-100" />
          <div className="h-full">
            <p className="text-gray-700 sm:text-sm">Preview:</p>
            <div className="h-full">
              <div
                className="h-full overflow-y-scroll border"
                dangerouslySetInnerHTML={{ __html: viewableContent }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(SiteEmailManageModal);
