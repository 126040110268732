import { Fragment } from "react";
import { noFavicon } from "src/config/host";
import Image from "src/components/Shared/Image";

const PageListItem = ({ pages = [], page = {}, workspaceId = "", i = 0, ...props }) => {
  return (
    <Fragment>
      {!workspaceId && page?.workspace_id?._id !== pages[i - 1]?.workspace_id?._id && (
        <tr>
          <td
            colSpan={3}
            className="break-all py-4 px-4 sm:pl-6 sm:py-3 text-sm font-semibold text-gray-500 whitespace-pre-wrap bg-gray-100">
            <div className="flex gap-4 items-center">
              <div
                style={{ backgroundColor: page?.workspace_id?.top_bar_color }}
                className="w-10 h-10 overflow-hidden rounded">
                <Image
                  image={page?.workspace_id?.square_logo || page?.workspace_id?.image_favicon || page?.workspace_id?.image_logo}
                  failImage={noFavicon}
                  alt={"Page list item"}
                />
              </div>
              {page?.workspace_id?.name}
            </div>
          </td>
        </tr>
      )}
      {props.children}
    </Fragment>
  );
};

export default PageListItem;
