import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "src/components/Shared/Buttons/Button";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Input from "src/components/Shared/Forms/Inputs/Input";
import RadioGroup from "src/components/Shared/Forms/Inputs/RadioGroup";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";
import { classNames } from "src/helpers/classNames";
import FileUpload from "src/components/Shared/Forms/Inputs/FileUpload";
import ChoiceGrid from "src/components/Shared/Forms/Inputs/ChoiceGrid";
import QuillEditor from "src/components/Shared/Forms/Inputs/QuillEditor";
import SignatureArea from "src/components/Shared/Forms/Inputs/SignatureArea";

const FormEmbed = ({ content, workspaceDetails }) => {
  const [sectionIndex, setSectionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);

  const onHandleChange = (content_id, key, value, question_type, option_id) => {
    setAnswers((prevData) => [
      ...prevData?.map((item) => {
        if (item?.content_id?.toString() === content_id?.toString()) {
          let newValue = value;
          // For checkbox
          if (question_type === "checkbox" && key === "value") {
            if (value === true) {
              newValue = [...(item?.value || []), option_id];
            } else {
              newValue = [...(item?.value || [])?.filter((val) => val !== option_id)];
            }
          }
          return {
            ...item,
            [key]: newValue,
          };
        } else {
          return item;
        }
      }),
    ]);
  };

  const onHandleSetAnswer = () => {
    let ansArr = [];
    if (content?.form?.sections && content?.form?.sections?.length > 0) {
      for (const section of content.form.sections) {
        if (section?.contents && section?.contents?.length > 0) {
          for (const content of section.contents) {
            if (content?.type === "question") {
              ansArr.push({
                content_id: content?._id,
                value: ["checkbox", "checkbox_grid", "radio_grid", "file"]?.includes(content?.question_type)
                  ? ["checkbox_grid", "radio_grid"]?.includes(content?.question_type)
                    ? content?.rows?.map((row) => ({ row_id: row?._id, option_id: content?.question_type === "radio_grid" ? "" : [] }))
                    : []
                  : "",
                other_value: "",
              });
            }
          }
        }
      }
      setAnswers(ansArr);
    }
  };

  const onHandleSubmit = async () => {
    if (content?.form?.sections?.length === sectionIndex + 1) {
      setSectionIndex(0);
      onHandleSetAnswer();
    } else {
      setSectionIndex((prevData) => prevData + 1);
    }
  };

  useEffect(() => {
    onHandleSetAnswer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.form?.sections]);

  return (
    <div className="relative w-full">
      <div
        className={classNames("mx-auto w-full overflow-auto", !localStorage.getItem("impersonatedUserId") ? "h-[calc(100vh-10rem)]" : workspaceDetails?.layout_type === "TOP_NAVIGATION" ? "h-[calc(100vh-4rem)] lg:h-[calc(100vh-120px)]" : "h-[calc(100vh-4rem)]")}
        style={{ maxWidth: workspaceDetails?.max_width || "100%" }}>
        {content?.form?.sections &&
          content?.form?.sections?.length > 0 &&
          content?.form?.sections?.map(
            (section, index) =>
              sectionIndex === index && (
                <div
                  className="w-full gap-4 p-6"
                  key={section?._id}>
                  <div className="mx-auto flex-shrink flex-grow">
                    <div className="space-y-6">
                      {section?.contents &&
                        section?.contents?.length > 0 &&
                        section?.contents?.map((content) => (
                          <div key={content?._id}>
                            {content?.type === "title" && (
                              <div className="relative space-y-2 rounded-md bg-white px-6 py-6 shadow">
                                <div className="text-2xl font-bold text-gray-600">{content?.name}</div>
                                <div className="font-medium text-gray-500">{content?.description}</div>
                              </div>
                            )}
                            {content?.type === "question" && (
                              <div className="mb-4 grid gap-y-4 rounded-md bg-white px-6 py-6 shadow">
                                <div className="w-full">
                                  {content?.question_type === "input" && (
                                    <Input
                                      error={content?.validation?.required}
                                      inline={true}
                                      label={content?.name}
                                      value={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                      onChange={(e) => onHandleChange(content?._id, "value", e.target.value, content?.question_type)}
                                    />
                                  )}
                                  {content?.question_type === "textarea" && (
                                    <TextArea
                                      error={content?.validation?.required}
                                      inline={true}
                                      label={content?.name}
                                      value={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                      onChange={(e) => onHandleChange(content?._id, "value", e.target.value, content?.question_type)}
                                    />
                                  )}
                                  {content?.question_type === "rich_text" && (
                                    <QuillEditor
                                      inline={true}
                                      error={content?.validation?.required}
                                      label={content?.name}
                                      value={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                      onChange={(e) => onHandleChange(content?._id, "value", e, content?.question_type)}
                                    />
                                  )}
                                  {content?.question_type === "signature" && (
                                    <SignatureArea
                                      inline={true}
                                      error={content?.validation?.required}
                                      label={content?.name}
                                      value={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                      onChange={(e) => onHandleChange(content?._id, "value", e, content?.question_type)}
                                    />
                                  )}
                                  {content?.question_type === "radio" && (
                                    <div className="space-y-4">
                                      <RadioGroup
                                        name={`option-${content?._id}`}
                                        required={content?.validation?.required}
                                        labelClass={"!font-semibold"}
                                        subtitle={""}
                                        title={content?.name}
                                        defaultChecked={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                        options={content?.options?.map((option) => ({ id: option?._id, title: !option?.other ? option?.name : "Other" }))}
                                        onChange={(id) => onHandleChange(content?._id, "value", id, content?.question_type)}
                                      />
                                      {answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value === content?.options?.find((option) => option?.other)?._id && (
                                        <Input
                                          inline={true}
                                          error={content?.validation?.required}
                                          label={"Specify other"}
                                          value={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.other_value}
                                          onChange={(e) => onHandleChange(content?._id, "other_value", e.target.value, content?.question_type)}
                                        />
                                      )}
                                    </div>
                                  )}
                                  {content?.question_type === "checkbox" && (
                                    <div className="space-y-4">
                                      <label className="text-base font-semibold text-gray-700">
                                        {content?.name} {content?.validation?.required && <span className="pl-1 text-base font-semibold !leading-3 text-red-500">*</span>}
                                      </label>
                                      <div className="space-y-4">
                                        <div className="space-y-3">
                                          {content?.options?.map((option) => (
                                            <Checkbox
                                              isChecked={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value?.includes(option?._id) ? true : false}
                                              key={option?._id}
                                              checkboxBoxClass={"h-5 w-5 flex-shrink-0"}
                                              checkboxLabel={!option?.other ? option?.name : "Other"}
                                              onChange={(e) => onHandleChange(content?._id, "value", e.target.checked, content?.question_type, option?._id)}
                                            />
                                          ))}
                                        </div>
                                        {answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value?.includes(content?.options?.find((option) => option?.other)?._id) && (
                                          <Input
                                            inline={true}
                                            error={content?.validation?.required}
                                            label={"Specify other"}
                                            value={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.other_value}
                                            onChange={(e) => onHandleChange(content?._id, "other_value", e.target.value, content?.question_type)}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {content?.question_type === "select" && (
                                    <SelectMenu
                                      startIndex={answers?.findIndex((ans) => ans?.content_id?.toString() === content?._id?.toString())}
                                      label={content?.name}
                                      required={content?.validation?.required}
                                      labelClass="mb-3 !font-semibold !text-base"
                                      options={content?.options?.map((option) => ({ key: option?.name, value: option?._id }))}
                                      setOption={(option) => onHandleChange(content?._id, "value", option.value, content?.question_type)}
                                    />
                                  )}
                                  {content?.question_type === "file" && (
                                    <FileUpload
                                      content={content}
                                      selectedFiles={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value || []}
                                      onHandleChange={onHandleChange}
                                    />
                                  )}
                                  {["radio_grid", "checkbox_grid"]?.includes(content?.question_type) && (
                                    <ChoiceGrid
                                      content={content}
                                      answer={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value || []}
                                      onHandleChange={onHandleChange}
                                    />
                                  )}
                                  {content?.question_type === "linear_scale" && (
                                    <div className="space-y-4">
                                      <RadioGroup
                                        required={content?.validation?.required}
                                        name={`option-${content?._id}`}
                                        labelClass={"!font-semibold"}
                                        subtitle={""}
                                        title={content?.name}
                                        layout={"scale"}
                                        scaleTitle={{ from: content?.linear_scale_from_label, to: content?.linear_scale_to_label }}
                                        defaultChecked={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                        options={Array.from({ length: content?.linear_scale_to - content?.linear_scale_from + 1 }, (_, index) => content?.linear_scale_from + index)?.map((value) => ({ id: value, title: value }))}
                                        onChange={(id) => onHandleChange(content?._id, "value", parseInt(id), content?.question_type)}
                                      />
                                    </div>
                                  )}
                                  {content?.question_type === "date" && (
                                    <div className="space-y-2">
                                      <label className="text-base font-semibold text-gray-700">
                                        {content?.name} {content?.validation?.required && <span className="pl-1 text-base font-semibold !leading-3 text-red-500">*</span>}
                                      </label>
                                      <div className="relative">
                                        <Flatpickr
                                          className="h-[47px] w-full rounded-md border border-gray-300 !ring-0 focus:border-highlightColor md:h-[40px] 2xl:h-[47px]"
                                          name="time"
                                          value={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                          onChange={([selectedTime]) => onHandleChange(content?._id, "value", selectedTime, content?.question_type)}
                                          options={{
                                            altInput: true,
                                            altFormat: "F j, Y",
                                            dateFormat: "d.m.y",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {content?.question_type === "time" && (
                                    <div className="space-y-2">
                                      <label className="text-base font-semibold text-gray-700">
                                        {content?.name} {content?.validation?.required && <span className="pl-1 text-base font-semibold !leading-3 text-red-500">*</span>}
                                      </label>
                                      <div className="relative">
                                        <Flatpickr
                                          className="h-[47px] w-full rounded-md border border-gray-300 !ring-0 focus:border-highlightColor md:h-[40px] 2xl:h-[47px]"
                                          name="time"
                                          value={answers?.find((ans) => ans?.content_id?.toString() === content?._id?.toString())?.value}
                                          onChange={([selectedTime]) => onHandleChange(content?._id, "value", selectedTime, content?.question_type)}
                                          options={{
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "h:i K",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                  {content?.form?.sections && content?.form?.sections?.length > 0 && (
                    <div className="mx-auto mt-5 flex w-full flex-shrink flex-grow justify-end gap-x-3  pt-3">
                      {sectionIndex > 0 && (
                        <Button
                          version="gray"
                          type="button"
                          onClick={() => setSectionIndex((prevData) => prevData - 1)}>
                          Back
                        </Button>
                      )}
                      <Button
                        type="button"
                        onClick={onHandleSubmit}>
                        {content?.form?.sections?.length === sectionIndex + 1 ? "Submit" : "Next"}
                      </Button>
                    </div>
                  )}
                </div>
              ),
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(FormEmbed);
