import React, { Fragment } from "react";
import NavItem from "./NavItem";
import Button from "src/components/Shared/Buttons/Button";
import { isSVGString } from "src/helpers";
import Image from "src/components/Shared/Image";
import { noFavicon } from "src/config/host";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { classNames } from "src/helpers/classNames";

const TopNavigationPanel = ({ pageList, currentNavigation, updateNavigation = () => {}, groupId }) => {
  return (
    <nav className="flex h-16 min-h-[64px] w-full items-center justify-between border-t-[2px] border-gray-100 bg-leftNavColor px-2 py-4 shadow-md">
      <div className="flex gap-x-3">
        {pageList.map((item) => (
          <Fragment key={item.key}>
            {item.permissions.length === 1 ? (
              <NavItem
                item={item.permissions[0]}
                activeItem={currentNavigation}
                group={item}
                onClick={updateNavigation}
              />
            ) : item.permissions.length > 0 ? (
              <div className="custom-drodown relative">
                <Button
                  type="button"
                  version="default"
                  className={classNames(
                    "flex w-full items-center gap-x-2 rounded-md px-2 py-2 transition-all duration-300 hover:bg-leftNavActiveColor hover:bg-opacity-80 hover:text-leftNavActiveTextColor",
                    item.key === groupId ? "bg-leftNavActiveColor text-leftNavActiveTextColor" : "text-leftNavTextColor",
                  )}>
                  {item.image && (
                    <div className="nav-icon mr-1 h-6 w-6 flex-shrink-0">
                      {isSVGString(item.image) ? (
                        <div
                          className=""
                          dangerouslySetInnerHTML={{ __html: item.image }}
                        />
                      ) : (
                        <Image
                          image={item.image}
                          failImage={noFavicon}
                          alt={"Groups"}
                        />
                      )}
                    </div>
                  )}
                  <div className="w-full text-left font-medium">{item.name}</div>
                  <div className="ml-1 flex h-8 w-4 min-w-[16px] items-center justify-center text-sm">
                    <ChevronRightIcon className="rotate-90 stroke-2" />
                  </div>
                </Button>
                <div className={"top-100 custom-dropdown-content absolute left-0 z-10 max-h-[400px] min-w-[200px] space-y-2 rounded-md border-none bg-leftNavColor shadow-lg transition-all duration-200 " + (!item.toggle ? "h-0 overflow-hidden" : "overflow-auto")}>
                  <div className="overflow-hidden rounded-md border border-gray-100 px-3 py-3">
                    {item.permissions.map((permission) => (
                      <Fragment key={permission.key}>
                        <NavItem
                          item={permission}
                          activeItem={currentNavigation}
                          onClick={updateNavigation}
                        />
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <NavItem
                item={item}
                activeItem={currentNavigation}
                onClick={updateNavigation}
              />
            )}
          </Fragment>
        ))}
      </div>
    </nav>
  );
};

export default TopNavigationPanel;
