import { connect } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DisplayPageForm from "src/components/PageForm/DisplayPageForm";
import NoData from "src/components/Shared/NoData/NoData";
import WorkspaceIntegrationFiltersItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFiltersItem";

const WorkspaceGroupAuthorizeUserComponent = ({
  group = {},
  permissions = [],
  workspaceId = "",
  setGroup = () => {},
  pageOptions = [],
  groupChanges = 0,
  setGroupChanges = () => {},
  operatorOptions = [],
  viewOnly = false,
  trustedAttributeArr = [],
  workspaceDetails = {},
  addPermission = () => {},
  updatePermission = () => {},
  removePermission = () => {},
  pagePreview = () => {},
  assignDisplayName = () => {},
  authorizeUserComponentAccessPermission = true,
  hideAliasManageButton = false,
  ...props
}) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result?.destination) {
      return;
    }

    const groupPermissions = reorder(permissions, result.source.index, result.destination.index);
    setGroup({ ...group, permissions: [...groupPermissions.map((groupPermission, index) => ({ ...groupPermission, ordering: index }))] });
    setGroupChanges(groupChanges + 1);
  };

  return (
    <>
      {group?.filters?.length > 0 && (
        <div className="mb-4 space-y-4 border-b border-dashed border-gray-200 pb-4">
          {group.filters.map((filter, index) => {
            return (
              <WorkspaceIntegrationFiltersItem
                index={index}
                filters={group.filters}
                viewOnly={!viewOnly && authorizeUserComponentAccessPermission ? false : true}
                from="group"
                key={filter?._id}
                state={filter}
                operators={operatorOptions}
                trustedAttributeArr={trustedAttributeArr}
                setState={(e) => {
                  setGroup({
                    ...group,
                    filters: group.filters.map((fl, i) => {
                      if (index === i) {
                        return e;
                      } else {
                        return fl;
                      }
                    }),
                  });
                  setGroupChanges(groupChanges + 1);
                }}
                removeFilter={() => {
                  setGroup({
                    ...group,
                    filters: group.filters.filter((data) => data?._id?.toString() !== filter._id?.toString()),
                  });
                  setGroupChanges(groupChanges + 1);
                }}
              />
            );
          })}
        </div>
      )}
      {permissions?.length > 0 ? (
        <>
          <div className="space-y-4">
            {permissions?.length > 0 &&
              (!viewOnly && authorizeUserComponentAccessPermission ? (
                <>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={`droppable-${group?._id}`}>
                      {(provided, snapshot) => (
                        <div
                          className="space-y-4"
                          {...provided.droppableProps}
                          ref={provided.innerRef}>
                          {permissions?.map((permission, index) => {
                            return (
                              <Draggable
                                key={permission?._id || permission?.id}
                                draggableId={permission?._id || permission?.id}
                                index={index}>
                                {(provided, snapshot) => (
                                  <>
                                    <DisplayPageForm
                                      ordering={index}
                                      permission={permission}
                                      workspaceId={workspaceId}
                                      groupId={group._id}
                                      setPermission={(e) => {
                                        addPermission(e, index);
                                      }}
                                      updatePermission={updatePermission}
                                      pageOptions={pageOptions}
                                      operatorOptions={operatorOptions}
                                      removePermission={(e) => {
                                        removePermission(e, index);
                                      }}
                                      pagePreview={pagePreview}
                                      trustedAttributeArr={trustedAttributeArr}
                                      innerRef={provided.innerRef}
                                      draggableProps={provided.draggableProps}
                                      dragHandleProps={provided.dragHandleProps}
                                      workspaceDetails={workspaceDetails}
                                      viewOnly={viewOnly}
                                      authorizeUserComponentAccessPermission={authorizeUserComponentAccessPermission}
                                      setDataChanges={setGroupChanges}
                                      dataChanges={groupChanges}
                                      assignDisplayName={assignDisplayName}
                                      hideAliasManageButton={hideAliasManageButton}
                                      className={props?.className}
                                    />
                                  </>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              ) : (
                permissions?.map((page, index) => {
                  return (
                    <DisplayPageForm
                      key={page?._id || page?.id}
                      permission={page}
                      pageOptions={pageOptions}
                      operatorOptions={operatorOptions}
                      viewOnly={true}
                      trustedAttributeArr={trustedAttributeArr}
                      workspaceId={workspaceId}
                      groupId={group._id}
                      workspaceDetails={workspaceDetails}
                      authorizeUserComponentAccessPermission={authorizeUserComponentAccessPermission}
                      pagePreview={pagePreview}
                    />
                  );
                })
              ))}
          </div>
        </>
      ) : (
        <div>
          <NoData />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(WorkspaceGroupAuthorizeUserComponent);
