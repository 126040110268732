import React from "react";
import NoData from "src/components/Shared/NoData/NoData";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";

export default function TableBodyLoader({ dataExists = false, loaded = true, colSpan = "5" }) {
  let isLoadedWithNoData = () => loaded && !dataExists;

  return (
    <>
      {isLoadedWithNoData() && (
        <tr>
          <td colSpan={colSpan}>
            <NoData />
          </td>
        </tr>
      )}
      {!loaded && !dataExists && (
        <tr>
          <td colSpan={colSpan}>
            <Preloader color="text-gray-400 fill-gray-700" />
          </td>
        </tr>
      )}
    </>
  );
}
