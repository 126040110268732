import React from "react";
import Button from "src/components/Shared/Buttons/Button";
import { classNames } from "src/helpers/classNames";

const FormAddItem = ({ type, isOthers, addOption = () => {}, addOther = () => {}, buttonTitle = "Add Option", isCircleShow = true }) => {
  return (
    <div className="relative flex w-full items-center gap-4">
      <div className="flex flex-shrink flex-grow items-center gap-4">
        {isCircleShow && (type === "radio" || type === "checkbox" || type === "radio_grid" || type === "checkbox_grid") && (
          <div className={classNames("aspect-square w-5 flex-shrink-0 border-2 border-gray-300", type === "radio" || type === "radio_grid" ? "rounded-full" : "rounded")}></div>
        )}
        {type === "dropdown" && <div className="flex-shrink-0 font-semibold text-gray-600">{"1"}</div>}
        <div className="relative flex flex-shrink-0 items-center">
          <Button
            version="default"
            onClick={addOption}
            className="text-sm font-semibold text-gray-500 transition-all duration-300 hover:underline">
            {buttonTitle}
          </Button>
          {isOthers && (
            <>
              <div className="mx-2 text-sm font-semibold text-gray-500">or</div>
              <Button
                onClick={addOther}
                version="default"
                className="text-sm font-semibold text-highlightColor">
                Add "Others"
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormAddItem;
