import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FormTextContent from "./FormContents/FormTextContent";
import FormQuestionContent from "./FormContents/FormQuestionContent";

const FormContent = ({ section = null, setFormData = () => {}, from = "form", answers = [], responseCreatedAt = null }) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result?.destination) {
      return;
    }

    const newContents = reorder(section?.contents, result.source.index, result.destination.index);
    setFormData((prevData) => ({
      ...prevData,
      sections: [
        ...prevData?.sections?.map((prevSection) => {
          if (prevSection?.id === section?.id) {
            return {
              ...prevSection,
              contents: [...newContents],
            };
          } else {
            return prevSection;
          }
        }),
      ],
    }));
  };

  return (
    <div className="space-y-6">
      {section?.contents && section?.contents?.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`content_${section?.id || section?._id}`}>
            {(provided, snapshot) => (
              <div
                className="space-y-4"
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {section?.contents?.map((content, contentIndex) => (
                  <Draggable
                    key={content?.id || content?._id}
                    draggableId={content?.id || content?._id}
                    isDragDisabled={from === "response"}
                    index={contentIndex}>
                    {(provided, snapshot) => (
                      <>
                        {content?.type === "title" && (
                          <FormTextContent
                            section={section}
                            content={content}
                            innerRef={provided.innerRef}
                            draggableProps={provided.draggableProps}
                            dragHandleProps={provided.dragHandleProps}
                            setFormData={setFormData}
                            from={from}
                          />
                        )}
                        {content?.type === "question" && (
                          <FormQuestionContent
                            section={section}
                            content={content}
                            innerRef={provided.innerRef}
                            draggableProps={provided.draggableProps}
                            dragHandleProps={provided.dragHandleProps}
                            setFormData={setFormData}
                            from={from}
                            answers={answers}
                            responseCreatedAt={responseCreatedAt}
                          />
                        )}
                      </>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default FormContent;
