import { Bars3Icon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "src/helpers/classNames";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Button from "src/components/Shared/Buttons/Button";
import FormFieldItem from "src/components/Forms/FormQuestionItems/FormFieldItem";

const FormOptionItem = ({ type, option, onChange = () => {}, onRemove = () => {}, index = 0, options = [], draggableProps = null, dragHandleProps = null, innerRef = null, from = "form", answer = null, placeholder = "Option", isCircleShow = true }) => {
  return (
    <div
      className="group/option-item relative -ml-5 flex w-full items-center gap-4 pl-5"
      ref={innerRef}
      {...draggableProps}>
      {!option.other && from !== "response" && (
        <Button
          version="default"
          className="invisible absolute left-0 top-1/2 flex-shrink-0 -translate-y-1/2 !cursor-move opacity-0 transition-all duration-300 group-hover/option-item:visible group-hover/option-item:opacity-100"
          dragHandleProps={dragHandleProps}>
          <Bars3Icon className="h-4 w-4 text-zinc-400" />
        </Button>
      )}
      <div className={classNames("flex flex-shrink flex-grow gap-4", option.other && from === "response" ? "items-start" : "items-center")}>
        {isCircleShow && (type === "radio" || type === "checkbox" || type === "radio_grid" || type === "checkbox_grid") && (
          <div
            className={classNames(
              "aspect-square w-5 flex-shrink-0 border-2 border-gray-300",
              type === "radio" || type === "radio_grid" ? "rounded-full" : "rounded",
              answer?.value?.includes(option?._id) && "!border-highlightColor bg-highlightColor",
              option.other ? "mt-0.5" : "",
            )}>
            {from === "response" && answer?.value?.includes(option?._id) && <CheckIcon className="h-4 w-4 text-white" />}
          </div>
        )}
        {type === "select" && <div className="flex-shrink-0 font-semibold text-gray-600">{index + 1}</div>}
        <div className="relative flex-shrink flex-grow">
          {from === "response" ? (
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                {option.other ? "Other" : option?.name}
                {type === "select" && answer?.value?.toString() === option?._id?.toString() && <CheckIcon className="h-5 w-5 text-highlightColor" />}
              </div>
              {answer?.value?.includes(option?._id) && option.other && <FormFieldItem placeholder={answer?.other_value} />}
            </div>
          ) : option.other ? (
            <FormFieldItem placeholder={"Other"} />
          ) : (
            <Input
              inline={false}
              label={""}
              wrapperClass={"!bg-white"}
              value={option?.name}
              placeholder={`${placeholder} ${index + 1}`}
              onChange={onChange}
            />
          )}
        </div>
        {from !== "response" && (options?.filter((option) => !option?.other)?.length > 1 || option.other) && (
          <div className="flex-shrink-0">
            <Button
              onClick={onRemove}
              version="default"
              className="text-gray-400 transition-all duration-300 hover:text-gray-600">
              <XMarkIcon className="h-6 w-6" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormOptionItem;
