import { connect } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { allowedScriptTypes, extractAndRemoveScripts, transpileES6ToES5 } from "src/helpers";
import { classNames } from "src/helpers/classNames";

const CustomHtmlEmbed = ({ content, workspaceDetails, user }) => {
  const [srcDoc, setSrcDoc] = useState("");
  const [srcScripts, setSrcScripts] = useState([]);
  const htmlDivRef = useRef(null);

  useEffect(() => {
    setSrcDoc(extractAndRemoveScripts(content.custom_html).modifiedHtml);
    setSrcScripts(extractAndRemoveScripts(content.custom_html).extractedScripts);
  }, [content]);

  useEffect(() => {
    let addedScriptCount = 0;
    let addedBelowScriptCount = 0;

    if (htmlDivRef.current?.innerHTML) {
      srcScripts.reverse().forEach((script) => {
        // document.body.insertAdjacentHTML('afterend', script)
        const matches = script.match(/<script[^>]*>([\s\S]*?)<\/script>/);
        const newScript = matches ? matches[1] : null;

        const scriptTypeMatch = /type="([^"]+)"/.exec(script || "");
        const scriptType = scriptTypeMatch ? scriptTypeMatch[1] || null : null;

        if (script.includes("src=") || (scriptType && !allowedScriptTypes.includes(scriptType))) {
          document.head.insertAdjacentHTML("beforeend", script);
          addedScriptCount += 1;
        } else {
          const scriptTag = document.createElement("script");
          scriptTag.innerHTML = transpileES6ToES5(newScript);
          document.body.appendChild(scriptTag);

          addedBelowScriptCount += 1;
        }
      });
    }
    // Cleanup function to remove the scripts when the component unmounts
    return () => {
      if (addedScriptCount || addedBelowScriptCount) {
        if (addedBelowScriptCount) {
          const head = document.head;
          const scriptTags = head.querySelectorAll("script");

          // Get the number of scripts and the index to start removal
          const scriptCount = scriptTags.length;
          const removeStartIndex = scriptCount - addedScriptCount;

          for (let i = removeStartIndex; i < scriptCount; i++) {
            head.removeChild(scriptTags[i]);
          }
        }

        if (addedBelowScriptCount) {
          const body = document.body;
          const bodyScriptTags = body.querySelectorAll("script");

          const bodyScriptCount = bodyScriptTags.length;
          const bodyRemoveStartIndex = bodyScriptCount - addedBelowScriptCount;

          for (let i = bodyRemoveStartIndex; i < bodyScriptCount; i++) {
            bodyScriptTags[i].remove();
          }
        }
      }
    };
  }, [srcScripts]);

  useEffect(() => {
    const linkId = "tailwind-cdn-link";
    let link = document.getElementById(linkId);

    if (!link) {
      link = document.createElement("link");
      link.id = linkId;
      link.rel = "stylesheet";
      link.href = "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
      document.head.appendChild(link);
    }
  }, []);

  useEffect(() => {
    const styleId = "font-awesome-cdn-style";
    let style = document.getElementById(styleId);

    if (!style) {
      style = document.createElement("link");
      style.rel = "stylesheet";
      style.href = "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css";
      style.id = styleId;
      document.body.appendChild(style);
    }
  }, []);

  return (
    <div className="relative w-full">
      <div
        ref={htmlDivRef}
        dangerouslySetInnerHTML={{ __html: srcDoc }}
        className={classNames("mx-auto w-full overflow-auto", !localStorage.getItem("impersonatedUserId") ? "h-[calc(100vh-10rem)]" : workspaceDetails?.layout_type === "TOP_NAVIGATION" ? "h-[calc(100vh-4rem)] lg:h-[calc(100vh-120px)]" : "h-[calc(100vh-4rem)]")}
        style={{ maxWidth: workspaceDetails?.max_width || "100%" }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(CustomHtmlEmbed);
