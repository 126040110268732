import { useEffect } from "react";
import { connect } from "react-redux";
import { logoutDispatch } from "src/actions/auth";
import { apiRequest } from "src/async/apiUtils";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { asyncLocalStorage } from "src/helpers/asyncLocalStorage";

const LogoutCallback = ({ ...props }) => {
  const destroySession = async (url = `/login`) => {
    await asyncLocalStorage.removeItem("userId");
    await asyncLocalStorage.removeItem("impersonatedUserId");
    await asyncLocalStorage.removeItem("access_token");
    await asyncLocalStorage.removeItem("refresh_token");
    props.logoutDispatch();
    window.location.href = url;
  };

  const getDefaultSSO = async () => {
    try {
      const res = await apiRequest("get", `sso/default-list`);
      if (res?.data?.status === 200) {
        if (res?.data?.data.length > 0 && props?.site?.dual_signin_for_admin === false) {
          destroySession(`/logout`);
        } else {
          destroySession();
        }
      } else {
        destroySession();
      }
    } catch (error) {
      destroySession();
    }
  };

  useEffect(() => {
    if (props?.site?._id) {
      getDefaultSSO();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.site?._id]);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <Preloader text="Redirecting..." />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { logoutDispatch })(LogoutCallback);
