import React, { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import PageImportModal from "src/components/Workspaces/WorkspacesModals/PageImportModal";

// Create DOMO Context
const DomoContext = createContext({
  embedIds: [],
  getEmbedIdCodesByCredential: () => {},
  columnNames: [],
  insertColumnNamesByPage: () => {},
  getColumnNamesByPage: () => {},
  onPageChange: () => {},
  openPageImportModal: () => {},
  isImporting: false,
});

// Create DOMO Provider
const DomoProvider = ({ children }) => {
  // For Embed Ids
  const [embedIds, setEmbedIds] = useState([]);

  const checkAndSetEmbedIds = ({ credentialId = null, codes = [] }) => {
    const index = embedIds.findIndex((id) => id?.credentialId?.toString() === credentialId?.toString());
    if (index === -1) {
      setEmbedIds((prevData) => [
        ...prevData,
        {
          credentialId,
          codes,
        },
      ]);
    }
  };

  const getDomoEmbedCodeList = async ({ workspaceId = null, credentialId = null }) => {
    try {
      let url = `/credentials/:credential_id/domo-embed-code/list`;
      if (workspaceId) url = `/workspaces/:workspace_id${url}`;
      const params = { workspace_id: workspaceId, credential_id: credentialId };

      const { data } = await apiRequest("GET", url, { params });

      checkAndSetEmbedIds({ credentialId, codes: data.data });
      return data.data;
    } catch (error) {
      return [];
    }
  };

  const getEmbedIdCodesByCredential = async ({ workspaceId = null, credentialId = null }) => {
    try {
      if (!credentialId) return [];
      const getIdsFromPrevData = embedIds.find((id) => id?.credentialId?.toString() === credentialId?.toString());
      if (getIdsFromPrevData) return getIdsFromPrevData?.codes || [];

      const codes = await getDomoEmbedCodeList({ workspaceId, credentialId });
      return codes || [];
    } catch (error) {
      return [];
    }
  };

  // For Dataset Columns
  const [columnNames, setColumnNames] = useState([]);

  const checkAndSetColumns = ({ type = "pageId", pageId = null, embedId = null, credentialId = null, columns = [], loading = false }) => {
    setColumnNames((prevData) => {
      let index = -1;
      if (type === "pageId") {
        index = prevData.findIndex((id) => id?.pageId?.toString() === pageId?.toString());
      } else if (type === "embedId") {
        index = prevData.findIndex((id) => id?.credentialId?.toString() === credentialId?.toString() && id?.embedId?.toString() === embedId?.toString());
      }
      if (index === -1) {
        return [
          ...prevData,
          {
            pageId,
            embedId,
            credentialId,
            columns,
            loading,
          },
        ];
      } else {
        return [
          ...prevData.map((item, i) => {
            if (index === i) {
              return { ...item, columns, loading };
            } else {
              return item;
            }
          }),
        ];
      }
    });
  };

  const fetchColumnListFromDomo = async ({ type = "pageId", workspaceId = null, pageId = null, embedId = null, credentialId = null, showToastMessage = false }) => {
    try {
      checkAndSetColumns({ type, pageId, embedId, credentialId, columns: [], loading: true });
      let url;
      if (type === "pageId") {
        url = `/pages/:page_id/domo-dataset-column/list`;
        if (workspaceId) url = `/workspaces/:workspace_id${url}`;
      } else if (type === "embedId") {
        url = `/credential/:credential_id/embed/:embed_id/domo-dataset-column/list`;
        if (workspaceId) url = `/workspaces/:workspace_id${url}`;
      }
      const params = { workspace_id: workspaceId, page_id: pageId, embed_id: embedId, credential_id: credentialId };

      const { data } = await apiRequest("GET", url, { params });

      checkAndSetColumns({ type, pageId, embedId, credentialId, columns: data.data, loading: false });
      if (showToastMessage) toast.success(data.message);
      return data.data;
    } catch (error) {
      checkAndSetColumns({ type, pageId, embedId, credentialId, columns: [], loading: false });
      return [];
    }
  };

  const fetchColumnListFromDb = async ({ workspaceId = null, pageId = null }) => {
    try {
      checkAndSetColumns({ pageId, columns: [], loading: true });
      let url = `/pages/:page_id/page-column/list`;
      if (workspaceId) url = `/workspaces/:workspace_id${url}`;
      const params = { workspace_id: workspaceId, page_id: pageId };

      const { data } = await apiRequest("GET", url, { params });

      checkAndSetColumns({ pageId, columns: data.data, loading: false });
      return data.data;
    } catch (error) {
      checkAndSetColumns({ pageId, columns: [], loading: false });
      return [];
    }
  };

  const manageColumnNamesByPage = async ({ workspaceId = null, pageId = null, showToastMessage = false }) => {
    try {
      if (!pageId) return [];
      const codes = await fetchColumnListFromDomo({ workspaceId, pageId, showToastMessage });
      return codes || [];
    } catch (error) {
      return [];
    }
  };

  const onPageChange = async ({ workspaceId = null, pageId = null }) => {
    try {
      if (!pageId) return [];
      const getPrevData = columnNames.find((id) => id?.pageId?.toString() === pageId?.toString());
      if (getPrevData) return getPrevData?.columns || [];
      const codes = await fetchColumnListFromDb({ workspaceId, pageId });
      return codes || [];
    } catch (error) {
      return [];
    }
  };

  const getColumnNamesByPage = ({ pageId = null }) => {
    try {
      if (!pageId) return { loading: false, columns: [] };
      const getPrevData = columnNames.find((id) => id?.pageId?.toString() === pageId?.toString());
      if (getPrevData) return { ...getPrevData };
      if (!getPrevData) return { loading: false, columns: [] };
    } catch (error) {
      return { loading: false, columns: [] };
    }
  };

  const getColumnsNameByEmbedIdAndCredentialId = async ({ workspaceId = null, embedId = null, credentialId = null, showToastMessage = false }) => {
    try {
      if (!embedId && !credentialId) return [];
      const getColumnsFromPrevData = columnNames.find((id) => id?.credentialId?.toString() === credentialId?.toString() && id?.embedId?.toString() === embedId?.toString());
      if (getColumnsFromPrevData) return getColumnsFromPrevData?.columns || [];

      const columns = await fetchColumnListFromDomo({ type: "embedId", workspaceId, embedId, credentialId, showToastMessage });
      return columns || [];
    } catch (error) {
      return [];
    }
  };

  // For Page Import
  const initialImportData = {
    workspaceId: null,
    integrationId: null,
    credentialId: null,
  };
  const [isImporting, setIsImporting] = useState(false);
  const [isPageImportModalOpen, setIsPageImportModalOpen] = useState(false);
  const [importData, setImportData] = useState(initialImportData);

  const openPageImportModal = ({ workspaceId = null, integrationId = null, credentialId = null, workspaces = [] }) => {
    setImportData({ workspaceId, integrationId, credentialId, workspaces });
    setIsPageImportModalOpen(true);
  };

  const closePageImportModal = () => {
    setIsPageImportModalOpen(false);
    setImportData(initialImportData);
    setIsImporting(false);
  };

  return (
    <DomoContext.Provider value={{ embedIds, getEmbedIdCodesByCredential, columnNames, manageColumnNamesByPage, getColumnNamesByPage, getColumnsNameByEmbedIdAndCredentialId, onPageChange, openPageImportModal, isImporting }}>
      {children}
      <PageImportModal
        isOpen={isPageImportModalOpen}
        onCancel={closePageImportModal}
        importData={importData}
        manageColumnNamesByPage={manageColumnNamesByPage}
        isImporting={isImporting}
        setIsImporting={setIsImporting}
      />
    </DomoContext.Provider>
  );
};

export default DomoProvider;

// Subscribe DOMO Context
const useDomo = () => {
  const context = useContext(DomoContext);
  if (!context) throw new Error(`useDomo must be used within an DomoProvider`);
  return context;
};

export { useDomo };
