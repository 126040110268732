import { ChevronUpDownIcon } from "@heroicons/react/24/outline";

const SortOrder = ({ sortDirection = "ASC", setSortDirection = () => {} }) => {
  return (
    <div
      onClick={() => (sortDirection === "ASC" ? setSortDirection("DESC") : setSortDirection("ASC"))}
      className="cursor-pointer bg-gray-200/0 text-gray-400 rounded hover:bg-gray-200/30 transition-all duration-100">
      <ChevronUpDownIcon className="p-[1px] h-6 w-6" />
    </div>
  );
};

export default SortOrder;
