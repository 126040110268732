import { connect } from "react-redux";
import { CheckIcon } from "@heroicons/react/24/solid";
import { classNames } from "src/helpers/classNames";

const Checkbox = ({ checkboxName, checkboxInputClass, isChecked, checkboxLabel, checkboxBoxClass, checkboxIconClass, checkboxLableClass, disabled = false, forceCheck = false, onChange = () => {}, siteSettings, divClass }) => {
  return (
    <>
      <div className={classNames("relative flex items-center gap-3 overflow-hidden", divClass)}>
        <input
          type="checkbox"
          disabled={disabled}
          name={checkboxName}
          className={classNames("peer absolute left-0 top-0 z-10 h-4 min-h-[16px] w-4 min-w-[16px] cursor-pointer opacity-0 " + checkboxInputClass, disabled ? "cursor-not-allowed" : "")}
          checked={forceCheck ? isChecked : isChecked && !disabled}
          onChange={onChange}
        />
        <div
          className={classNames(
            "flex h-4 min-h-[16px] w-4 min-w-[16px] items-center justify-center rounded border  text-sm text-black/0 peer-checked:text-white",
            checkboxBoxClass,
            disabled ? "cursor-not-allowed border-gray-300 bg-gray-300" : "border-gray-300 bg-white" + siteSettings?.highlight_color ? "peer-checked:border-highlightColor peer-checked:bg-highlightColor" : "peer-checked:border-black peer-checked:bg-black",
          )}>
          <CheckIcon className={classNames("h-4 w-4", checkboxIconClass)} />
        </div>
        {checkboxLabel && <div className={"w-full text-sm text-gray-500 " + checkboxLableClass}>{checkboxLabel}</div>}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteSettings: state.site,
  };
};

export default connect(mapStateToProps, {})(Checkbox);
