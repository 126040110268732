import React from "react";
import { baseUrl, noFavicon } from "src/config/host";
import { classNames } from "src/helpers/classNames";

export default function Image({ image = "", backupImage = "", failImage = noFavicon, alt = "Default", onError = () => {}, className = "" }) {
  return (
    <>
      {image ? (
        <img
          src={image.includes(".s3.") ? image + "?" + Date.now() : image.includes("data:image/") ? image : baseUrl + image}
          alt={alt}
          className={classNames("w-full h-full object-contain", className)}
          onError={(e) => {
            e.target.src = `${failImage}`;
            onError(e);
          }}
        />
      ) : backupImage ? (
        <img
          src={backupImage.includes(".s3.") ? backupImage + "?" + Date.now() : backupImage.includes("data:image/") ? backupImage : baseUrl + backupImage}
          alt={alt}
          className="w-full h-full object-contain"
          onError={(e) => {
            e.target.src = `${failImage}`;
            onError(e);
          }}
        />
      ) : (
        <img
          src={noFavicon}
          alt={alt}
          className="w-full h-full object-contain"
          onError={onError}
        />
      )}
    </>
  );
}
