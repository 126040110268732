import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import Button from "src/components/Shared/Buttons/Button";
import { classNames } from "src/helpers/classNames";

export default function PillEntry({ index = -1, tag = "", onDelete = () => {}, wrapperClass = "", labelClass = "", removeClass = "", deleteEnabled = true }) {
  return (
    <div
      key={index}
      className={classNames("relative inline-flex max-w-xs items-center justify-between rounded-full border border-highlightColor bg-white px-2 py-1", deleteEnabled ? "pr-7" : "pr-2", wrapperClass)}>
      <div className={classNames("max-w-full truncate text-xs font-semibold text-gray-700", labelClass)}>{tag}</div>
      {deleteEnabled && (
        <Button
          version="default"
          className={classNames("absolute bottom-auto right-1 top-auto flex !h-5 !w-5 items-center justify-center rounded-full bg-transparent transition-colors duration-200 hover:bg-gray-300", removeClass)}
          onClick={() => onDelete(index)}>
          <XMarkIcon className="h-4 w-4 text-gray-400" />
        </Button>
      )}
    </div>
  );
}
